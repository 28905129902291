.partbers-banner{
  padding-top: 155px;
  padding-bottom: 80px;
  min-height: 795px;
  height: auto;
  @include break(tablet){
    min-height: 940px;
    padding-top: 100px;
    padding-bottom: 80px;
  }
  @include break(ss-small){
    min-height: 605px;
    padding-top: 65px;
    padding-bottom: 35px;
  }
  &__container{
   
  }
  &__logos-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__logo{
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 60px;
    object-fit: contain;
    display: block;
    @include break(ss-small){
      max-width: 100px;
      max-height: 30px;
    }
  }
  &__logos-sep{
    width: 1px;
    height: 40px;
    background-color: $white;
    margin: 0 30px;
    @include break(ss-small){
      margin: 0 22px;
    }
  }
  &__content{
    margin-top: 30px;
    text-align: center;
    @include break(medium){
      margin-top: 70px;
    }
    @include break(ss-small){
      margin-top: 30px;
    }
  }
  &__title{
    max-width: 1070px;
    font-weight: 400;
    font-size: 120px;
    line-height: 114px;
    color: $white;
    margin-left: auto;
    margin-right: auto;
    @include break(medium){
      font-size: 110px;
      line-height: 122px;
      max-width: 767px;
    }
    @include break(ss-small){
      font-size: 55px;
      line-height: 61px;
      max-width: 358px;
    }
  }
  &__text{
    max-width: 715px;
    margin-left: auto;
    margin-right: auto;
    font-size: 23px;
    line-height: 35px;
    margin-top: 40px;
    color: $white;
    font-family: $main_font;
    margin-bottom: 0;
    @include break(medium){
      margin-top: 15px;
      max-width: 495px;
      font-size: 23px;
      line-height: 35px;
    }
    @include break(ss-small){
      margin-top: 20px;
      max-width: 295px;
      font-size: 18px;
      line-height: 26px;
    }
  }
  &__button-wrapper{
    margin-top: 40px;
    text-align: center;
    @include break(medium){
      margin-top: 35px;
    }
    @include break(ss-small){
      margin-top: 20px;
    }
  }
  &__button{
    max-width: 540px;
    width: 100%;
    padding: 30px 30px;
    color: white;
    border-radius: 46px;
    background-color: white;
    cursor: pointer;
    display: inline-block;
    font-size: 30px;
    line-height: 26px;
    -webkit-transition: .3s linear;
    transition: .3s linear;
    background-color: #b748ff;
    @include break(ss-small){
      padding: 15px;
      font-size: 20px;
      line-height: 20px;
      border-radius: 30px;
      max-width: 360px;
    }
    &:hover{
      background-color: #a034e7;
      color: $white;
    }  
  }
  &__bottom{
    margin-top: 45px;
    text-align: center;
    @include break(ss-small){
      margin-top: 40px;
    }
    &-text{
      font-size: 14px;
      line-height: 27px;
      margin-bottom: 0;
      text-align: center;
      font-family: $main_font;
      span{
        mix-blend-mode: color-dodge;
        color: rgba(255,240,240,0.2);
        text-shadow: 0 0 #f5aa14;
      }
      @include break(medium){
        line-height: 24px;
      }
      @include break(ss-small){
        font-size: 10px;
        line-height: 17px;
      }
      &:not(:last-child){
        margin-bottom: 4px;
        @include break(ss-small){
          margin-bottom: 2px;
        }
      }
    }
    &-link{
      color: $white;
      margin-left: 7px;
      padding-right: 14px;
      position: relative;
      text-decoration: none;
      display: inline-block;
      @include break(medium){
        padding-right: 10px;
        margin-left: 5px;
      }
      &::after{
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='12' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 6L1 11' stroke='white' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        width: 9px;
        height: 100%;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto;
        @include break(medium){
          background-image: url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.5L6 4L1 7.5' stroke='white' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          width: 7px;
        }
      }
    }
  }
}