.platform-menu {
	padding-top : 38px;
	padding-bottom : 45px;

	@media( max-width: 768px) {
		padding-top : 30px;
		padding-bottom : 35px;
	}

	@media( max-width: 480px) {
		padding-bottom : 20px;
	}
	&-v2{
		padding: 0;
		margin-top: 60px;
		margin-bottom: 60px;
		@include break(tablet){
			margin-top: 45px;
			margin-bottom: 60px;
		}
		@include break(x-small){
			margin-top: 40px;
		}
	}
	&__lobby{
		@media( max-width: 992px) {
			display : none;
		}
		ul, .menu{
			display: flex;
			justify-content: center;
		}
		li, .menu-item{
			margin: 0 9px;
			&.current-menu-item{
				a, span{
					background-color: $dark_blue;
					color: $white;
				}
			}
		}
		a, span{
			background-color: $white;
			color: $dark_blue;
			font-family: $second_font;
			font-weight: 300;
			font-size: 16px;
			text-align: center;
			line-height: 22px;
			min-width: 170px;
			padding: 7px 25px;
			box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
			border-radius: 81px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			transition: .3s linear;
			&:hover{
				background-color: $dark_blue;
				color: $white;
			}
		}
	}
	&__desktop  {
		max-width: 1240px;
		margin-left: auto;
		margin-right: auto;
		@media( max-width: 992px) {
			display : none;
		}

		ul {
			width : 100%;
			display : flex;
			position : relative;
			padding-bottom : 14px;
			justify-content: space-around;
			&:before {
				content: "";
				position : absolute;
				bottom : 0;
				width : 100%;
				display : block;
				background: #F2F2F2;
				border-radius: 4px;
				height : 6px;
			}


			li {
				flex : 1;
				text-align : center;
				display : flex;
				align-items : center;
				justify-content : center;
				padding : 0 10px;
				max-width: 180px;
				&.current-menu-item {
					position : relative;

					&:before {
						content: "";
						position : absolute;
						width : 100%;
						height : 6px;
						background: #15BDFF;
						box-shadow: 1px 3px 11px rgba(49, 197, 255, 0.7);
						border-radius: 16px;
						bottom: -14px;
						left : 0;
					}

					span, a {
						font-weight: 700;
					}
				}

				a, span {
					font-family: $second_font;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 1.43;
					text-align: center;
					display : inline-block;
					color: $dark_blue;

					@media( max-width: 1200px) {
						font-size: 14px;
					}

					@media( max-width: 1024px) {
						font-size: 13px;
					}
				}

			}
		}
	}

	&__mobile {
		display : none;

		&>div {
			position : relative;
			height : 61px;

			@media( max-width: 480px) {
				height : 40px;
			}
		}

		&>div>ul {
			position : absolute;
			display : flex;
			flex-direction : column;
			width : 100%;
			min-height : 61px;
			overflow: hidden;
			max-height: 100%;
			border-radius: 12.1977px;
			box-shadow: 0px 5.95122px 5.95122px rgba(49, 197, 255, 0.25);
			background: #F2F2F2;
			border: 1.4878px solid #15BDFF;
			padding :19px 22px;
			z-index : 5;
			transition: 0.5s;

			@media( max-width: 480px) {
				min-height : 40px;
				padding: 12px 15px;
				border-radius: 8px;
			}

			&:before {
				content: "";
				position : absolute;
				right: 29px;
				top: 26px;
				width : 14px;
				height : 8px;
				background-repeat : no-repeat;
				background-position : center center;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAwLjc4MTI1TDguNDM5MDIgOC4yMjAyN0wxNS44NzggMC43ODEyNSIgc3Ryb2tlPSIjMDIxRDU5IiBzdHJva2Utd2lkdGg9IjEuNDg3OCIvPjwvc3ZnPg==);

				@media( max-width: 480px) {
					top : 17px;
					right: 20px;
				}

			}

			&.open {
				max-height: 100vh;

				&:before {
					background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUuODc4OSA5LjIxODc1TDguNDM5ODggMS43Nzk3M0wxLjAwMDg2IDkuMjE4NzUiIHN0cm9rZT0iIzAyMUQ1OSIgc3Ryb2tlLXdpZHRoPSIxLjQ4NzgiLz48L3N2Zz4=);

				}
			}

			li {
				display : block;
				order:1;
				&:not(:last-child){
					margin-bottom : 26px;
				}
				&.current-menu-item {
					padding-bottom : 19px;
					margin-bottom : 29px;
					border-bottom: 1px solid #DADADA;
					order : 0;
				}

				a, span {
					font-family: $second_font;
					font-style: normal;
					font-weight: 400;
					font-size: 19px;
					line-height: 1.43;
					color: $dark_blue;
					display : block;

					@media( max-width: 480px) {
						font-size : 13px;
					}
				}

				span {
					font-weight: 700;
				}


			}
		}

		@media( max-width: 992px) {
			display : block;
		}
	}

}