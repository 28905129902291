.accept-cookie-box {
  z-index: 2000;
  position: fixed;
  bottom: 0;
  transform: translateY(100%);
  left: 0;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
  padding: 98px 0 46px;
  transition: 0.5s;
}

@media( max-width: 768px) {
  .accept-cookie-box {
      padding: 125px 0 97px;
  }
}

@media( max-width: 480px) {
  .accept-cookie-box {
      padding: 53px 0 49px;
  }
}


.accept-cookie-box.opened {
  transform: translateY(0);
}

.accept-cookie-box__inner {
  max-width: 988px;
  margin-left: auto;
  margin-right: auto;
}

@media( max-width: 768px) {
  .accept-cookie-box__inner {
      max-width: 560px;
  }
  
}

@media( max-width: 480px) {
  .accept-cookie-box__inner {
      max-width: 300px;
  }
 
}


.accept-cookie-box__text p {
  font-family: 'Helvetica', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 40px;
}

@media( max-width: 768px) {
  .accept-cookie-box__text p {
      font-size: 20px;
  }
}

@media( max-width: 600px) {
  .accept-cookie-box__text p {
      font-size: 16px;
  }
}

@media( max-width: 480px) {
  .accept-cookie-box__text p {
      font-size: 13px;
  }
}

.accept-cookie-box__butons {
  display: flex;
  justify-content: flex-end;
}

@media( max-width: 768px) {
  .accept-cookie-box__butons {
      justify-content: center;
  }
}

@media( max-width: 480px) {
  .accept-cookie-box__butons {
      justify-content: start;
  }
}

.accept-cookie-box__accept-btn {
  margin-right: 13px;
}

.accept-cookie-box__close-btn {

}

body.accept-cookie-box-is-opened .chat-icon {
  bottom: 85px;
}