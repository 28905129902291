.archive-resources-submenu{
  margin-top: 70px;
  margin-bottom: 70px;
  position: relative;
  @include break(tablet){
    margin-top: 40px;
    margin-bottom: 30px;
  }
  @include break(medium){
    margin-bottom: 40px;
  }
  @include break(ss-small){
    margin-top: 25px;
    margin-bottom: 30px;
  }
  &__container{

  }
  &__wrapper{

  }
  &__menu{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include break(medium){
      display: none;
    }
    &-item{
      width: 145px;
      margin: 0 9px 10px;
    }
    &-link{
      display: block;
      width: 100%;
      text-align: center;
      font-size: 16px;
      line-height: 22px;
      padding: 7px 15px;
      background-color: $white;
      color: $dark_blue;
      font-family: $second_font;
      font-weight: 300;
      box-shadow: 0px 7px 15px rgba(28, 20, 58, 0.15);
      border-radius: 81px;
      transition: .3s linear;
      &:hover,
      &.active{
        background-color: $dark_blue;
        color: $white;
      }
    }
  }
  &__mobile{
    display: none;
    @include break(medium){
      display: block;
    }
  }
}