.text-with-cta{
  margin-top: 115px;
  margin-bottom: 115px;
  @include break(tablet){
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @include break(ss-small){
    margin-top: 70px;
    margin-bottom: 65px;
  }
  &__container{

  }
  &__wrapper{
    max-width: 940px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
    border-radius: 35px;
    background-color: $dark_blue;
    background-image: url("data:image/svg+xml,%3Csvg width='931' height='137' viewBox='0 0 931 137' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M75.8327 78.4325L8.23678 212.886C3.68773 221.934 7.29998 232.971 16.305 237.538C25.31 242.104 36.2977 238.471 40.8468 229.422L108.443 94.9689C112.992 85.9205 109.38 74.8835 100.375 70.3171C91.3695 65.7507 80.3818 69.3841 75.8327 78.4325Z' fill='%23009AD6'/%3E%3Cpath d='M255.23 240.147L239.728 224.578C224.444 212.214 222.037 189.757 234.348 174.408L294.935 69.1951C307.246 53.8455 329.606 51.4277 344.889 63.7917L360.392 79.3611C375.675 91.725 378.083 114.182 365.772 129.531L305.185 234.744C292.874 250.093 270.514 252.511 255.23 240.147Z' fill='%231FB0C5'/%3E%3Cpath d='M494.133 242.727L463.128 211.588C441.57 191.421 440.367 157.535 460.429 135.884L513.976 59.924C534.056 38.2734 567.797 37.0645 589.355 57.2131L620.359 88.352C641.917 108.519 643.121 142.405 623.059 164.056L569.511 240.016C549.431 261.667 515.69 262.876 494.133 242.727Z' fill='%233EC7B4'/%3E%3Cpath d='M733.043 50.6342L686.526 97.3522C658.691 125.307 658.691 170.631 686.526 198.585L733.043 245.303C760.878 273.258 806.006 273.258 833.841 245.303L880.358 198.585C908.193 170.631 908.193 125.307 880.358 97.3522L833.841 50.6341C806.006 22.6794 760.878 22.6794 733.043 50.6342Z' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
    background-position: left bottom;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    padding: 70px 105px 50px 75px;
    @include break(medium850){
      background-image: url("data:image/svg+xml,%3Csvg width='655' height='104' viewBox='0 0 655 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M53.3011 55.1208L5.78944 149.625C2.59202 155.985 5.13098 163.743 11.4604 166.952C17.7898 170.162 25.5128 167.608 28.7103 161.248L76.2219 66.7439C79.4193 60.3839 76.8803 52.6263 70.5509 49.4167C64.2215 46.207 56.4985 48.7608 53.3011 55.1208Z' fill='%23009AD6'/%3E%3Cpath d='M179.328 169.395L168.477 158.375C157.779 149.624 156.094 133.73 164.711 122.866L207.12 48.3983C215.737 37.5341 231.388 35.8229 242.086 44.5738L252.937 55.5935C263.635 64.3445 265.32 80.2388 256.703 91.103L214.294 165.57C205.677 176.435 190.026 178.146 179.328 169.395Z' fill='%231FB0C5'/%3E%3Cpath d='M347.093 169.934L325.203 148.13C309.983 134.009 309.133 110.281 323.297 95.1214L361.103 41.9332C375.28 26.7732 399.101 25.9267 414.321 40.035L436.211 61.8387C451.431 75.9598 452.281 99.6873 438.117 114.847L400.311 168.036C386.134 183.196 362.313 184.042 347.093 169.934Z' fill='%233EC7B4'/%3E%3Cpath d='M515.239 35.5819L482.543 68.4189C462.979 88.0677 462.979 119.925 482.543 139.573L515.239 172.41C534.803 192.059 566.523 192.059 586.088 172.41L618.783 139.573C638.348 119.925 638.348 88.0676 618.783 68.4189L586.088 35.5819C566.523 15.9331 534.803 15.9331 515.239 35.5819Z' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
      padding: 50px 65px;
      background-position: center bottom;
    }
    @include break(ss-small){
      padding: 60px 27px 55px 27px;
     
    }
    @include break(x-small){
      background-image: url("data:image/svg+xml,%3Csvg width='257' height='42' viewBox='0 0 257 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M192.115 21.3135L206.065 7.21688C214.413 -1.21812 227.947 -1.21813 236.295 7.21687L250.245 21.3134C255.793 26.9193 257.654 34.8398 255.827 41.9985H186.532C184.706 34.8398 186.567 26.9193 192.115 21.3135Z' fill='%235DDDA3'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M181.069 41.999H126.123C126.814 40.5481 127.738 39.1766 128.896 37.9384L145.111 15.1442C151.191 8.64726 161.408 8.2845 167.936 14.3307L177.324 23.6749C182.513 28.4855 183.808 35.8977 181.069 41.999Z' fill='%233EC7B4'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M63.6006 41.999H94.6039L98.0626 35.7995C101.678 31.1471 100.971 24.3406 96.4827 20.5931L91.9305 15.8741C87.4426 12.1266 80.8768 12.8594 77.2618 17.5118L63.6006 41.999Z' fill='%231FB0C5'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 41.9996L10.7687 20.5799C12.1329 17.8663 15.4281 16.7766 18.1288 18.1461C20.8294 19.5156 21.9127 22.8256 20.5484 25.5392L12.273 41.9996H0Z' fill='%23009AD6'/%3E%3C/svg%3E%0A");
    }
    
  }
  &__title{
    font-size: 35px;
    line-height: 50px;
    font-weight: 400;
    margin-bottom: 20px;
    color: $white;
    @include break(medium850){
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    @include break(ss-small){
      font-size: 20px;
      line-height: 29px;
      margin-bottom: 15px;
      text-align: center;
    }
  }
  &__text{
    font-weight: 300;
    font-size: 22px;
    margin-bottom: 0;
    line-height: 31px;
    color: $white;
    @include break(medium850){
      font-size: 18px;
      line-height: 26px;
    }
    @include break(ss-small){
      font-size: 13px;
      line-height: 21px;
      text-align: center;
    }
  }
  &__button-wrapper{
    margin-top: 40px;
    @include break(ss-small){
      margin-top: 30px;
      text-align: center;
    }
  }
  &__button{
    @include break(ss-small){
      padding-left: 27px;
      padding-right: 27px;
    }
    &:hover{
      background-color: $azure;
    }
  }
}