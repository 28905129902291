@mixin break($point) {
	@if $point == min1920 {
		@media only screen and (min-width : 1920px) {
			@content;
		}

	}@else if $point == min1200 {
		@media only screen and (min-width : 1200px) {
			@content;
		}
	}@else if $point == large1920 {
		@media only screen and (max-width : 1920px) {
			@content;
		}
	} @else if $point == large1750 {
		@media only screen and (max-width : 1750px) {
			@content;
		}
	} @else if $point == large1650 {
		@media only screen and (max-width : 1680px) {
			@content;
		}
	} @else if $point == large1470 { // * 15"
		@media only screen and (max-width : 1470px) {
			@content;
		}
	} @else if $point == large1370 {
		@media only screen and (max-width : 1370px) {
			@content;
		}
	} @else if $point == desktop { // * 12"
		@media only screen and (max-width : 1200px) {
			@content;
		}
	} @else if $point == tablet { // * tablet portrait
		@media only screen and (max-width : 1030px) {
			@content;
		}
	} @else if $point == medium { // * tablet portrait
		@media only screen and (max-width : 991px) {
			@content;
		}
	} @else if $point == medium-second {
		@media only screen and (max-width : 950px) {
			@content;
		}
	} @else if $point == medium850 { // * big mobile
		@media only screen and (max-width : 850px) {
			@content;
		}
	} @else if $point == small { // * mobile landscape
		@media only screen and (max-width : 768px) {
			@content;
		}
	} @else if $point == ss-small {
		@media only screen and (max-width : 670px) {
			@content;
		}
	} @else if $point == s-small {
		@media only screen and (max-width : 570px) {
			@content;
		}
	} @else if $point == x-small { // * mobile portrait
		@media only screen and (max-width : 480px) {
			@content;
		}
	} @else if $point == super-small {
		@media only screen and (max-width : 415px) {
			@content;
		}
	} @else if $point == small-330 {  /* Iphone 5 */
		@media only screen and (max-width : 330px) {
			@content;
		}
	} @else if $point == small-360 {  /* Iphone 5 */
		@media only screen and (max-width : 360px) {
			@content;
		}
	} @else if $point == small-375 {  /* Iphone 5 */
		@media only screen and (max-width : 375px) {
			@content;
		}
	}

	@if $point == height-less-960 {
		@media only screen and (max-height : 960px) {
			@content;
		}
	}
	@else if $point == height-less-860 {
		@media only screen and (max-height : 860px) {
			@content;
		}
	}
}


/*
 * A mixin targeting a specific device width and larger.
 * These are the available targets: 'xs', 'sm', 'md', 'lg', 'xl'
 *
 * @param {string} $media The target media
 */
@mixin respond-from($media) {
	@if $media == xs {
		@media (min-width : 0px) {
			@content;
		}
	} @else if $media == sm {
		@media (min-width : 768px) {
			@content;
		}
	} @else if $media == md {
		@media (min-width : 1200px) {
			@content;
		}
	} @else if $media == lg {
		@media (min-width : 1450px) {
			@content;
		}
	} @else if $media == xl {
		@media (min-width : 1800px) {
			@content;
		}
	}
}

/*
 * Generates classes for a grid of columns, prefixed by size.
 * The result will be .col-[size]-[col]-[total-columns] E.g. .col-xs-6-12
 *
 * @param {string}	$size			The name of the size target (e.g. xs, sm, md, lg, xl, etc.)
 * @param {int}		$grid-columns	Amount of columns
 */
@mixin grid-generator($size, $grid-columns) {
	@for $i from 1 through $grid-columns {
		.col-#{$size}-#{$i}-#{$grid-columns} {
			width         : percentage($i / $grid-columns);
			//float: left;
			padding-left  : 15px;
			padding-right : 15px;
		}
	}
}


// Generating grid
@include grid-generator(xs, 12);

@include respond-from(sm) {
	@include grid-generator(sm, 12);
}

@include respond-from(md) {
	@include grid-generator(md, 12);
}

@include respond-from(lg) {
	@include grid-generator(lg, 12);
}

@include respond-from(xl) {
	@include grid-generator(xl, 12);
}

// =============================================================================
// Font Face
// =============================================================================
@mixin font-face($font-name, $file_name, $font_weight) {
	@font-face {
		font-family  : $font-name;
		src          : url('../fonts/' + $file_name + '.eot'),
		url('../fonts/' + $file_name + '.eot?#iefix') format('embedded-opentype'),
		url('../fonts/' + $file_name + '.woff') format('woff'),
		url('../fonts/' + $file_name + '.woff2') format('woff2'),
		url('../fonts/' + $file_name + '.svg') format('svg'),
		url('../fonts/' + $file_name + '.ttf') format('truetype');
		font-weight  : $font_weight;
		font-style   : normal;
		font-display : swap;
	}
}