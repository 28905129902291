.together-better {
	padding-top : 75px;
	padding-bottom : 186px;
	position : relative;

	@media( max-width: 768px) {
		padding-bottom : 96px;
	}

	@media( max-width: 480px) {
		padding-bottom : 95px;
	}

	&:before {
		content: "";
		position : absolute;
		top : 0;
		left : 0;
		width : 100%;
		height : 100%;
		background: linear-gradient(179.06deg, #E8F5FA 8.95%, rgba(241, 250, 253, 0) 81.62%);
		transform: matrix(1, 0, 0, -1, 0, 0);
	}

	&__floating-element {
		position: absolute;
		background-position: center;
		background-repeat: no-repeat;
		z-index : 1;

		&.floating-element-1 {
			width : 176px;
			height : 256px;
			top: 250px;
			left : 0;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcxIiBoZWlnaHQ9IjI0NiIgdmlld0JveD0iMCAwIDE3MSAyNDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iNDEuNjIzIiB5PSItMy45MTU5IiB3aWR0aD0iMTg4LjUiIGhlaWdodD0iMTcwLjUiIHJ4PSIxMy4yNSIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNDEuNjIzIC0zLjkxNTkpIiBzdHJva2U9IiMxQzE0M0EiIHN0cm9rZS1vcGFjaXR5PSIwLjMiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtZGFzaGFycmF5PSIxNiAxNiIvPjwvc3ZnPg==);

			@media( max-width: 768px) {
				top : 334px;
				width : 77px;
				height : 117px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzIiIGhlaWdodD0iMTA2IiB2aWV3Qm94PSIwIDAgNzIgMTA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxyZWN0IHg9Ii0wLjEyMDMyMiIgeT0iLTMuOTcxNTkiIHdpZHRoPSI4OC4xOTY1IiBoZWlnaHQ9Ijc5LjY5OSIgcng9IjEzLjI1IiB0cmFuc2Zvcm09InJvdGF0ZSgzMC44MzQ5IC0wLjEyMDMyMiAtMy45NzE1OSkiIHN0cm9rZT0iIzFDMTQzQSIgc3Ryb2tlLW9wYWNpdHk9IjAuMyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1kYXNoYXJyYXk9IjE2IDE2Ii8+PC9zdmc+);

			}

			@media( max-width: 480px) {
				top : 98px;
				width : 54px;
				height : 94px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDkiIGhlaWdodD0iODQiIHZpZXdCb3g9IjAgMCA0OSA4NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB4PSI0Ljc4NzkiIHk9Ii0zLjkzOTM0IiB3aWR0aD0iNjguNDY4MiIgaGVpZ2h0PSI2MS44Mzk3IiByeD0iMTMuMjUiIHRyYW5zZm9ybT0icm90YXRlKDQ1IDQuNzg3OSAtMy45MzkzNCkiIHN0cm9rZT0iIzFDMTQzQSIgc3Ryb2tlLW9wYWNpdHk9IjAuMyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1kYXNoYXJyYXk9IjE2IDE2Ii8+PC9zdmc+);

			}

		}

		&.floating-element-2 {
			width : 82px;
			height : 82px;
			top : 510px;
			left: 113px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzQiIGhlaWdodD0iNzQiIHZpZXdCb3g9IjAgMCA3NCA3NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCBvcGFjaXR5PSIwLjMiIHg9Ii00IiB5PSIzNi4yMTA5IiB3aWR0aD0iNTgiIGhlaWdodD0iNTgiIHJ4PSIxMCIgdHJhbnNmb3JtPSJyb3RhdGUoLTQzLjg1NjQgLTQgMzYuMjEwOSkiIGZpbGw9IiM1REREQTMiLz48L3N2Zz4=);

			@media( max-width: 768px) {
				top : 216px;
				left : 0;
				width : 38px;
				height : 61px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCAzNCA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCBvcGFjaXR5PSIwLjMiIHg9Ii0yNS42ODc1IiB5PSIyNi45OTIyIiB3aWR0aD0iNDQuNzI2OCIgaGVpZ2h0PSI0NC43MjY4IiByeD0iMTAiIHRyYW5zZm9ybT0icm90YXRlKC00My44NTY0IC0yNS42ODc1IDI2Ljk5MjIpIiBmaWxsPSIjNUREREEzIi8+PC9zdmc+);

			}

			@media( max-width: 480px) {
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzEiIHZpZXdCb3g9IjAgMCAzMSAzMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCBvcGFjaXR5PSIwLjMiIHg9Ii0zLjkyNzI1IiB5PSIxNC44NTU1IiB3aWR0aD0iMjcuMTMxNSIgaGVpZ2h0PSIyNy4xMzE1IiByeD0iMTAiIHRyYW5zZm9ybT0icm90YXRlKC00My44NTY0IC0zLjkyNzI1IDE0Ljg1NTUpIiBmaWxsPSIjNUREREEzIi8+PC9zdmc+);
				width : 39px;
				height : 39px;
				top : 296px;
				left : 30px;
			}

		}

		&.floating-element-3 {
			width : 55px;
			height : 114px;
			right: 0;
			top: 595px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTUiIGhlaWdodD0iMTE1IiB2aWV3Qm94PSIwIDAgNTUgMTE1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01NSAxMTQuMDIzQzg1LjM3NTcgMTE0LjAyMyAxMTAgODguNTAzNyAxMTAgNTcuMDIzNEMxMTAgMjUuNTQzMiA4NS4zNzU3IDAuMDIzNDM3NSA1NSAwLjAyMzQzNzVDMjQuNjI0MyAwLjAyMzQzNzUgMCAyNS41NDMyIDAgNTcuMDIzNEMwIDg4LjUwMzcgMjQuNjI0MyAxMTQuMDIzIDU1IDExNC4wMjNaIiBmaWxsPSIjREJGM0ZEIi8+PC9zdmc+);

			@media( max-width: 768px) {
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDEiIGhlaWdodD0iNTMiIHZpZXdCb3g9IjAgMCA0MSA1MyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAuNzIzNSAwSDM4LjQzMzJDNDIuMzQ3NSAwIDQ1Ljk2MzkgMi4wODU1MSA0Ny45MjEgNS40NjU4NUw1Ni43NzU5IDIwLjc3MDJDNTguNzMzIDI0LjE1NTkgNTguNzMzIDI4LjMyMTYgNTYuNzc1OSAzMS43MDE5TDQ3LjkyMSA0Ny4wMDYzQzQ1Ljk2MzkgNTAuMzkxOSA0Mi4zNDc1IDUyLjQ3MjEgMzguNDMzMiA1Mi40NzIxSDIwLjcyMzVDMTYuODA5MyA1Mi40NzIxIDEzLjE5MjkgNTAuMzg2NiAxMS4yMzU4IDQ3LjAwNjNMMi4zODA5MiAzMS43MDE5QzAuNDIzODA4IDI4LjMxNjMgMC40MjM4MDggMjQuMTUwNiAyLjM4MDkyIDIwLjc3MDJMMTEuMjM1OCA1LjQ2NTg1QzEzLjE5MjkgMi4wODU1MSAxNi44MDkzIDAgMjAuNzIzNSAwWiIgZmlsbD0iI0Y0RTNGRiIvPjwvc3ZnPg==);
				width : 40px;
				height : 51vw;
				top : 564px;

			}

			@media( max-width: 480px) {
				top : 570px;
				right : 0;
				width : 51px;
				height : 47px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEiIGhlaWdodD0iNDciIHZpZXdCb3g9IjAgMCA1MSA0NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNDkuMTUwNyAyOC4wMjA5TDQ5LjE1MDcgMjguMDIwOEM1MC43OTg3IDI1LjE2MSA1MC43OTg5IDIxLjYzNjQgNDkuMTUwNyAxOC43NzE1VjI4LjAyMDlaTTQ5LjE1MDcgMjguMDIwOUw0MS4yOTEgNDEuNjY4N0w0MS4yOTA5IDQxLjY2ODlDMzkuNjQzIDQ0LjUzMzEgMzYuNTk4MiA0Ni4yOTI1IDMzLjMwMyA0Ni4yOTI1SDE3LjU4MzhDMTQuMjg4OSA0Ni4yOTI1IDExLjI0MzkgNDQuNTI4NyA5LjU5NTY4IDQxLjY2ODZMMS43MzYyNCAyOC4wMjExQzEuNzM2MiAyOC4wMjEgMS43MzYxNyAyOC4wMjEgMS43MzYxNCAyOC4wMjA5QzAuMDg3ODUxNCAyNS4xNTYgMC4wODgwNzk3IDIxLjYzMTQgMS43MzYwNyAxOC43NzE3TDEuNzM2MTQgMTguNzcxNUw5LjU5NTY4IDUuMTIzODZDMTEuMjQzOSAyLjI2Mzc5IDE0LjI4ODkgMC41IDE3LjU4MzggMC41SDMzLjMwM0MzNi41OTc4IDAuNSAzOS42NDI4IDIuMjYzNzQgNDEuMjkxIDUuMTIzNzRDNDEuMjkxMSA1LjEyMzc4IDQxLjI5MTEgNS4xMjM4MiA0MS4yOTExIDUuMTIzODZMNDkuMTUwNiAxOC43NzE0TDQ5LjE1MDcgMjguMDIwOVoiIGZpbGw9IiNFQkQ4RkEiIGZpbGwtb3BhY2l0eT0iMC4yIiBzdHJva2U9IiNCNzQ4RkYiLz48L3N2Zz4=);

			}

		}

		&.floating-element-4 {
			display : none;
			width : 85px;
			height : 78px;
			right: 0;
			top : 590px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODUiIGhlaWdodD0iNzgiIHZpZXdCb3g9IjAgMCA4NSA3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNODEuOTQwOCA0Ni43MTYyTDgxLjk0MDcgNDYuNzE2M0w2OC44ODM1IDY5LjM4OTRMNjguODgzNCA2OS4zODk2QzY2LjA4NjcgNzQuMjUwNCA2MC45MTkxIDc3LjIzNjUgNTUuMzI2MyA3Ny4yMzY1SDI5LjIxMkMyMy42MTk2IDc3LjIzNjUgMTguNDUxOSA3NC4yNDI5IDE1LjY1NDkgNjkuMzg5NEMxNS42NTQ5IDY5LjM4OTQgMTUuNjU0OSA2OS4zODkzIDE1LjY1NDkgNjkuMzg5M0wyLjU5NzgyIDQ2LjcxNjRDLTAuMTk5MzQzIDQxLjg1NDkgLTAuMTk5MTQ3IDM1Ljg3MzYgMi41OTc2NSAzMS4wMjAzTDIuNTk3NzIgMzEuMDIwMkwxNS42NTQ5IDguMzQ3MkMxNS42NTQ5IDguMzQ3MTYgMTUuNjU0OSA4LjM0NzEyIDE1LjY1NDkgOC4zNDcwOEMxOC40NTE5IDMuNDkzNjEgMjMuNjE5NiAwLjUgMjkuMjEyIDAuNUg1NS4zMjYzQzYwLjkxODcgMC41IDY2LjA4NjUgMy40OTM2MiA2OC44ODM1IDguMzQ3MDhDNjguODgzNSA4LjM0NzEyIDY4Ljg4MzYgOC4zNDcxNiA2OC44ODM2IDguMzQ3Mkw4MS45NDA2IDMxLjAyMDFDODQuNzM3OCAzNS44ODE2IDg0LjczNzYgNDEuODYyOSA4MS45NDA4IDQ2LjcxNjJaIiBmaWxsPSIjRUJEOEZBIiBmaWxsLW9wYWNpdHk9IjAuMiIgc3Ryb2tlPSIjQjc0OEZGIi8+PC9zdmc+);

			@media( max-width: 768px) {
				display : block;
			}

			@media( max-width: 480px) {
				top : 554px;
				width : 23px;
				height : 32px;
				right: 0;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAyNCAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIuNjU5IDBIMjMuMzE5MUMyNS42NzUzIDAgMjcuODUyMSAxLjI1NTM1IDI5LjAzMDIgMy4yOTAwOUwzNC4zNjAzIDEyLjUwMjRDMzUuNTM4MyAxNC41NDAzIDM1LjUzODMgMTcuMDQ3OCAzNC4zNjAzIDE5LjA4MjVMMjkuMDMwMiAyOC4yOTQ4QzI3Ljg1MjEgMzAuMzMyOCAyNS42NzUzIDMxLjU4NDkgMjMuMzE5MSAzMS41ODQ5SDEyLjY1OUMxMC4zMDI5IDMxLjU4NDkgOC4xMjYwNiAzMC4zMjk2IDYuOTQ4IDI4LjI5NDhMMS42MTc5MiAxOS4wODI1QzAuNDM5ODYgMTcuMDQ0NiAwLjQzOTg2IDE0LjUzNzEgMS42MTc5MiAxMi41MDI0TDYuOTQ4IDMuMjkwMDlDOC4xMjYwNiAxLjI1NTM1IDEwLjMwMjkgMCAxMi42NTkgMFoiIGZpbGw9IiNGNEUzRkYiLz48L3N2Zz4=);

			}
		}
	}

	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 60px;
		line-height: 1.1;
		text-align: center;
		color: $dark_blue3;
		margin-bottom : 40px;
		position : relative;
		z-index : 1;

		@media( max-width: 1200px) {
			font-size: 55px;
			margin-bottom : 25px;
		}

		@media( max-width: 1024px) {
			font-size: 45px;
			margin-bottom : 20px;
		}

		@media( max-width: 768px) {
			font-size: 35px;
			margin-bottom : 10px;

		}

		@media( max-width: 480px) {
			font-size: 30px;
			max-width : 209px;
			margin : 0 auto 10px;
		}
	}

	&__subtitle {
		font-family: $second_font;
		font-style: normal;
		font-weight: 300;
		font-size: 22px;
		line-height: 1.43;
		text-align: center;
		color: $dark_blue;
		max-width: 757px;
		margin : 0 auto 116px;
		position : relative;
		z-index : 1;

		@media( max-width: 992px) {
			margin-bottom : 110px;
		}


		@media( max-width: 768px) {
			font-size: 23px;
			margin-bottom : 90px;
		}

		@media( max-width: 480px) {
			font-size: 15px;
			margin-bottom : 72px;
		}


	}

	&__content-item {
		font-family: $main_font;
		font-style: normal;
		font-weight: 700;
		font-size: 30px;
		line-height: 1.43;
		text-align: center;
		color: $dark_blue;
		position : relative;
		max-width: 596px;
		margin : 0 auto 132px;
		z-index : 1;

		@media( max-width: 768px) {
			font-size: 25px;
			margin-bottom : 142px;
			max-width : 498px;
		}

		@media( max-width: 480px) {
			font-size: 18px;
			margin-bottom : 90px;
			max-width : 270px;
		}

		&:last-of-type {
			margin-bottom : 0;

			&:before {
				display : none;
			}
		}

		&:before {
			content: "";
			position : absolute;
			display : block;
			background: #021D59;
			border-radius: 53px;
			height : 2px;
			width : 90px;
			bottom: -65px;
			left : 50%;
			transform: translateX(-50%);
			z-index : 1;

			@media( max-width: 768px) {
				bottom: -88px;
			}

			@media( max-width: 480px) {
				bottom: -44px;
			}
		}

	}
}