.archive-resources-banner{
  margin-top: 30px;
  margin-bottom: 70px;
  position: relative;
  @include break(tablet){
    margin-top: 25px;
    margin-bottom: 40px;
  }
  @include break(ss-small){
    margin-bottom: 25px;
  }
  &__container{

  }
  &__wrapper{
    height: 500px;
    border-radius: 35px;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark_blue3;
    @include break(small){
      height: 310px;
      box-shadow: 0px 10px 20px rgba(28, 20, 58, 0.2);
      border-radius: 20px;
    }
    @include break(x-small){
      height: 430px;
    }
  }
  &__img-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
  &__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__content{
    position: relative;
    z-index: 100;
    width: 100%;
    padding: 50px 15px;
  }
  &__title{
    font-size: 103px;
    line-height: 1;
    font-weight: 400;
    text-align: center;
    color: $white;
    @include break(medium850){
      font-size: 65px;
    }
    @include break(ss-small){
      font-size: 45px;
      line-height: 50px;
    }
  }
}
.single-resources-banner-v2{ 
  &__wrapper{
    height: 280px;
    @include break(small){
      height: 310px;
      box-shadow: 0px 10px 20px rgba(28, 20, 58, 0.2);
    }
    @include break(x-small){
      height: 150px;
    }
  }
  &__title{
    font-size: 60px;
    line-height: 66px;
    margin-bottom: 0;
    @include break(medium850){
      font-size: 65px;
      line-height: 1;
    }
    @include break(x-small){
      font-size: 30px;
      line-height: 34px;
    }
  }
}