$white: #fff;
$black: #000000;
$dark_blue: #021D59;
$dark_blue2: #1B1E62;
$dark_blue3: #1C143A;
$azure: #15BDFF;

$gray1:#D7D9DD; 
$gray2:#333333;
$gray3:#828282;


//fonts
$main_font_regular   : 'Agrandir-regular', sans-serif;
$main_font   : 'Agrandir', sans-serif;
$second_font : 'Helvetica', sans-serif;
$kanit : 'Kanit', sans-serif;
