.contact-us {

	&__banner-background {
		position : absolute;
		top : 0;
		left: 0;
		width : 100%;
		height : 100%;
	}

    &__banner-background-desktop, &__banner-background-tablet, &__banner-background-mobile {
	    width : 100%;
	    height : 100%;
	    object-fit: cover;
    }

	&__banner-background-desktop {
		@media( max-width: 768px) {
			display : none;
		}

	}

	&__banner-background-tablet {
		display : none;

		@media( max-width: 768px) {
			display : block;
		}
		@media( max-width: 480px) {
			display : none;
		}
	}
	&__banner-background-mobile {
		display : none;

		@media( max-width: 480px) {
			display : block;
		}
	}



	&__banner {
		padding : 256px 0 270px;
		position : relative;

		@media( max-width: 992px) {
			
		}

		@media( max-width: 991px) {
			padding: 0;
			height: 540px;
			width : calc(100% - 80px);
			margin : 25px auto 0;
			border-radius: 30px;
			overflow: hidden;
			box-shadow: 0px 10px 20px rgba(28, 20, 58, 0.2);
		}
		@media( max-width: 480px){
			width : calc(100% - 40px);
			border-radius: 20px;
			height: 555px;
		}
		/*&:before {
			content: '';
			position : absolute;
			height : 100%;
			width : 795px;
			background: linear-gradient(90deg, #1C143A 0%, rgba(2, 29, 89, 0) 69.36%);
			top : 0;
			left : 0;
			z-index : 1;

			@media( max-width: 480px) {
				width : 100%;
				height : 100%;
				background: linear-gradient(15.33deg, rgba(2, 29, 89, 0.9) 9%, rgba(2, 29, 89, 0) 91.05%);
			}
		}*/
	}
	&_content{
		@media( max-width: 991px){
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
	}
	&__banner-title {
		position : relative;
		z-index : 2;
		padding-left : 58px;
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 75px;
		line-height: 113%;
		color: #FFFFFF;

		@media( max-width: 1370px) {
			font-size: 70px;
		}

		@media( max-width: 1200px) {
			font-size: 65px;
			
		}
		@media( max-width: 991px) {
			padding-left : 0;
		}
		@media( max-width: 600px) {
			font-size: 55px;
			text-align : center;
		}

		@media( max-width: 480px) {
			font-size: 45px;
		}
	}

	&__container {
		display : flex;
		flex-wrap: wrap;
		justify-content : space-between;
		min-height : 604px;
		padding-bottom : 55px;
	}

	&__content-left {
		width : 40%;
		padding-top : 110px;
		padding-left : 58px;
		padding-right: 20px;

		@media( max-width: 1200px) {
			padding-left : 0px;
		}

		@media( max-width: 992px) {
			width : 100%;
			padding-right: 0px;
			padding-top : 48px;
		}

		p {
			font-family: $second_font;
			font-style: normal;
			font-weight: 300;
			font-size: 22px;
			line-height: 143%;
			color: #000000;
			margin-bottom : 35px;

			@media( max-width: 768px) {
				font-size: 21px;
				margin-bottom : 32px;
			}

			@media( max-width: 480px) {
				font-size: 20px;
				margin-bottom : 28px;
			}

			@media( max-width: 390px) {
				font-size: 18px;
				margin-bottom : 25px;
			}

		}
	}

	&__content-form {
		width : 60%;
		max-width: 722px;
		position : relative; 
		appearance: none;

		@media( max-width: 1370px) {
			width : 58%;
		}

		@media( max-width: 992px) {
			width : 100%;
			max-width: 100%;
		}

		@media( max-width: 768px) {
			margin-top : 70px;
		}

		@media( max-width: 480px) {
			margin-top : 60px;
		}
	}

	&__content-form-wrap {
		position : relative;
		z-index : 4;
		width : 100%;
		left : 0;
		margin-top : -405px;
		background: #FFFFFF;
		box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
		border-radius: 20px;
		padding : 65px 42px 41px;

		@media( max-width: 992px) {
			position : relative;
			margin-top : 0; 
			padding : 60px 42px 41px;
		}

		@media( max-width: 480px) {
			padding : 55px 36px 39px;
		}
 
		.wpcf7 {
			.communications{
				line-height: 18px;
				*{
					line-height: 18px;
				}
				.wpcf7-list-item-label{
					display: none;
				}
			}
			.wpcf7-spinner {
				/*position : absolute;
				left : 50%;
				transform: translateX(-50%);
				bottom : 0;*/
			}
			.wpcf7-form-control-wrap{
				.wpcf7-list-item{
					.wpcf7-list-item-label{
						font-family: $main_font;
						font-style: normal;
						font-weight: 400;
						font-size: 13px;
						line-height: 143%;
						color: #000000;
						margin-left : 6px;
					}
				}
				
			}
			p {
				font-family: $main_font;
				font-style: normal;
				font-weight: 400;
				font-size: 13px;
				line-height: 143%;
				color: #000000;
				margin-bottom : 20px;

				@media( max-width: 390px) {
					font-size: 13px;
				}

				a {
					color: $azure;
					transition: .3s linear;
					&:hover{
						color: $dark_blue;
					}
				}
			}
		}
	}

	&__form-title {
		font-family: $second_font;
		font-style: normal;
		font-weight: 300;
		font-size: 27px;
		line-height: 135%;
		text-align: center;
		color: #000000;
		margin-bottom : 40px;

		@media( max-width: 768px) {
			font-size: 25px;
			margin-bottom : 30px;
		}

		@media( max-width: 480px) {
			font-size: 20px;
			margin-bottom : 25px;
		}

		@media( max-width: 390px) {
			font-size: 18px;
			margin-bottom : 19px;
		}
	}

	.content-form {
		&__wrap {
			display : flex;
			justify-content : space-between;

			@media( max-width: 600px) {
				display : block;
			}
		}

		&__row {
			width : calc(50% - 23px);
			margin-bottom : 14px;

			@media( max-width: 600px) {
				width : 100%;
			}

			&.textarea-wrap {
				width : 100%;
				margin-bottom : 24px;
			}

			&.checkbox-wrap {
				margin-bottom : 20px;
				width : 100%;
				display : flex;
				align-items : start;
			}

			input[type="text"],input[type="email"], input[type="tel"] {
				width : 100%;
				background: #FFFFFF;
				border: 0.910859px solid rgba(28, 20, 58, 0.17);
				border-radius: 14.5737px;
				height : 40px;
				padding : 0 10px;

				@media( max-width: 480px) {
					height : 30px;
					border-radius: 10px;
				}

				&::placeholder {
					font-family: 'Rubik', sans-serif;
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 14px;
					display: flex;
					align-items: center;
					color: rgba(28, 20, 58, 0.6);
					opacity: 0;

					@media( max-width: 480px) {
						opacity: 1;
					}
				}
			}

			textarea {
				background: #FFFFFF;
				border: 0.910859px solid rgba(28, 20, 58, 0.17);
				border-radius: 14.5737px;
				width : 100%;
				height : 213px;
				padding : 6px 10px;
				resize: none;
				@media( max-width: 480px) {
					height : 96px;
					border-radius: 10px;
				}

				&::placeholder {
					font-family: 'Rubik', sans-serif;
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 14px;
					display: flex;
					align-items: center;
					color: rgba(28, 20, 58, 0.6);
					opacity: 0;

					@media( max-width: 480px) {
						opacity: 1;
					}
				}

			}
		}

		&__label {
			font-family: 'Rubik', sans-serif;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 1.21;
			display: block;
			color: rgba(28, 20, 58, 0.6);
			margin-bottom: 5px;
			@media( max-width: 480px) {
				display : none;
			}
		}

		&__label-checkbox {
			font-family: $main_font;
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 18px;
			color: #000000;
			margin-left : 6px;
		}
		&__submit-wrapper{
			text-align: center;
			margin-top : 40px;
		}
		&__submit {
			border: 1px solid $dark_blue;
			border-radius: 14.5737px;
			height : 40px;
			padding : 0 24px;
			cursor: pointer;
			transition: 0.3s;
			font-family: $main_font;
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 1.35;
			display: flex;
			align-items: center;
			background-color : $white;
			position : relative;
			margin-left: auto;
			margin-right: auto;

			color: #1C143A;

			&:hover {
				background-color : $dark_blue;
				color: $white;
			}
		}
	}

}