.main-events{
  margin-top: 115px;
  margin-bottom: 140px;
  @include break(tablet){
    margin-top: 70px;
    margin-bottom: 100px;
  }
  @include break(ss-small){
    margin-top: 35px;
    margin-bottom: 55px;
  }
  &__wrapper{
    max-width: 976px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    @include break(x-small){
      flex-direction: column;
    }
  }
  &__item{
    width: calc(50% - 30px);
    border: 1px solid rgba(2, 29, 89, 0.24);
    border-radius: 15px;
    padding: 22px 25px 25px 25px;
    position: relative;
    @include break(medium){
      padding: 16px 16px 25px 16px;
    }
    @include break(tablet){
      width: calc(50% - 10px);
    }
    @include break(ss-small){
      padding: 12px 12px 25px 12px;
    }
    @include break(x-small){
      width: 100%;
    }
    &:last-child{
      margin-left: 60px;
      @include break(tablet){
        margin-left: 20px;
      }
      @include break(x-small){
        margin-left: 0;
        margin-top: 20px;
      }
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
    &-image-wrapper{
      background-color: #D9D9D9;
      border-radius: 8px;
      display: block;
      overflow: hidden;
      position: relative;
      height: 200px;
      margin-bottom: 22px;
      @include break(small){
        height: 185px;
        margin-bottom: 32px;
      }
      @include break(ss-small){
        height: 150px;
        margin-bottom: 15px;
      }

    }
    img,
    &-image{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      transition: .3s linear;
    }
    &-date{
      min-width: 56px;
      position: absolute;
      left: 18px;
      top: 0;
      background-color: $azure;
      border-radius: 0px 0px 7px 7px;
      padding: 12px 10px;
      text-align: center;
      color: $white;
      font-size: 15px;
      line-height: 17px;
      &-number{
        font-size: 23px;
        line-height: 1;
        color: $white;
        display: block;
      }
    }
    &-title{
      font-size: 23px;
      line-height: 30px;
      color: $black;
      margin-bottom: 5px;
      a{
        color: $black;
        transition: .3s linear;
        &:hover{
          color: $azure;
        }
      }
      @include break(medium){
        font-size: 19px;
        line-height: 27px;
      }
      @include break(ss-small){
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 26px;
      }
    }
    &-main-date{
      font-weight: 300;
      font-size: 22px;
      line-height: 31px;
      text-transform: uppercase;
      margin-bottom: 0;
      @include break(medium){
        font-size: 18px;
        line-height: 26px;
      }
      @include break(ss-small){
        font-size: 15px;
        line-height: 21px;
      }
    }
    &-more{
      position: absolute;
      bottom: 20px;
      left: 25px;
      font-size: 18px;
      line-height: 26px;
      color: $azure;
      font-weight: 600;
      text-decoration: underline;
      transition: .3s linear;
      @include break(medium){
        font-size: 18px;
        line-height: 26px;
        bottom: 32px;
        left: 16px;
      }
      @include break(medium){
        font-size: 18px;
        line-height: 26px;
        bottom: 32px;
        left: 16px;
      }
      @include break(ss-small){
        font-size: 16px;
        line-height: 23px;
        bottom: 25px;
        left: 12px;
      }
      &:hover{
        text-decoration: none;
        color: $dark_blue;
      }
    }
  }
}