.platform-lobby{
  margin-top: 60px;
  margin-bottom: 175px;
  @include break(tablet){
    margin-top: 60px;
    margin-bottom: 150px;
  }
  @include break(x-small){
    margin-bottom: 115px;
  }
  &__wrapper{
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
  }
  &__title{
    font-size: 35px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 30px;
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
    @include break(medium){
      line-height: 41px;
      margin-bottom: 25px;
      max-width: 580px;
    }
    @include break(x-small){
      font-size: 30px;
      line-height: 34px;
      margin-bottom: 20px;
    }
  }
  &__text{
    max-width: 790px;
    margin-left: auto;
    margin-right: auto;
    font-family: $main_font;
    font-size: 23px;
    line-height: 30px;
    text-align: center;
    @include break(medium){
      max-width: 580px;
      line-height: 35px;
    }
    @include break(x-small){
      font-size: 18px;
      line-height: 26px;
      max-width: 305px;
    }
  }
  &__pages{
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 40px;
    row-gap: 40px;
    @include break(medium850){
      grid-template-columns: repeat(2, 1fr);
    }
    @include break(x-small){
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      row-gap: 20px;
    }
  }
  &__page{
    background: $white;
    border: 1px solid #B9D8E7;
    box-shadow: 0px 4.37647px 70.0235px rgba(2, 29, 89, 0.21);
    border-radius: 24px;
    padding: 17px 17px 23px 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &-wrapper{
      margin-bottom: 20px;
      @include break(medium850){
        margin-bottom: 40px;
      }
      @include break(x-small){
        margin-bottom: 35px;
      }
    }
    &-image-wrapper{
      display: block;
      border-radius: 13px;
      margin-bottom: 20px;
      overflow: hidden;
    }
    &-image{
      display: block;
      width: 100%;
      object-fit: cover;
      height: auto;
    }
    &-title{
      text-align: center;
      font-size: 20px;
      line-height: 22px;
      @include break(x-small){
        font-size: 15px;
        line-height: 17px;
        color: $dark_blue;
      }
      a{
        color: $azure;
        transition: .3s linear;
        &:hover{
          color: $dark_blue;
        }
      }
    }
    &-parent{
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      font-family: $main_font;
      padding-bottom: 13px;
      position: relative;
      margin-bottom: 12px;
      @include break(medium){
        font-size: 12px;
        line-height: 17px;
      }
      @include break(x-small){
        font-size: 12px;
        line-height: 17px;
      }
      &::before{
        content: '';
        display: block;
        position: absolute;
        width: 54px;
        height: 1px;
        background-color: #C6CCCE;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
    }
    &-excerpt{
      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 300;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 0;
      @include break(x-small){
        font-size: 12px;
        line-height: 15px;
        -webkit-line-clamp: 3;
      }
    }
    &-more{
      @extend .primary-btn;
      font-size: 15px;
      line-height: 18px;
      padding: 10px 23px;
      border-radius: 14px;
      @include break(x-small){
        padding: 7px 16px;
        border-radius: 9px;
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
}