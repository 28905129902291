.privacy-policy-banner {
	padding-top : 28px;
	margin-bottom : 70px;
	@include break(tablet){
		margin-bottom: 50px;
	}
	@include break(ss-small){
		margin-bottom: 30px;
	}
	&__wrap {
		width : 100%;
		padding : 108px 0;
		overflow: hidden;
		background: $dark_blue;
		border-radius: 35px;
		text-align : center;
		position : relative;

		@media( max-width: 768px) {
			padding : 128px 106px;
		}

		@media( max-width: 480px) {
			padding : 58px 60px;
		}
	}

	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 60px;
		line-height: 1.10;
		color: $white;
		position : relative;
		z-index : 1;
		max-width: 700px;
		margin-left : auto;
		margin-right : auto;

		@media( max-width: 768px) {
			font-size: 50px;
			max-width: 635px;
		}

		@media( max-width: 600px) {
			font-size: 40px;
		}

		@media( max-width: 480px) {
			font-size: 30px;
			max-width: 300px;
		}
	}

	&__background {
		position : absolute;
		top : 0;
		left : 0;
		width : 100%;
		height : 100%;

		img {
			object-fit: cover;
			object-position: center;
			width: 100%;
			height: 100%;
		}
	}

	&__background-desktop {
		@media( max-width: 768px) {
			display : none;
		}
	}

	&__background-tablet {
		display : none;

		@media( max-width: 768px) {
			display : block;
		}

		@media( max-width: 480px) {
			display : none;
		}
	}

	&__background-mobile {
		display : none;

		@media( max-width: 480px) {
			display : block;
		}
	}

}