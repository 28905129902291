html {
	overflow-x  : hidden;
	&.overflow {
		overflow : hidden;
	}
}

body {
	font-size   : 16px;
	line-height : 23px;
	font-weight : 400;
	position    : relative;
	
	background  : $white;
	padding-top : 80px;

	@include break(large1470) {

	}
	@include break(desktop) {

	}
	@include break(tablet) {

	}
	@include break(medium) {
		overflow-x: hidden;
	}
	@include break(medium850) {

	}
	@include break(ss-small) {
		padding-top : 50px;
	}

	* {
		outline     : none !important;
		box-sizing  : border-box !important;
		color       : $dark_blue;
		font-family : $main_font;
		font-weight : 400;
	}
}

p {
	font-family   : $second_font;
	font-style    : normal;
	font-weight   : 400;
	font-size     : 20px;
	line-height   : 1.43;
	color         : #021D59;
	margin-bottom : 30px;
}

.azure-btn {
	padding          : 11px 26px;
	font-size        : 20px;
	line-height      : 26px;
	background-color : $azure;
	color            : $white;
	border-radius    : 16px;
	border           : 1px solid $azure;
	transition       : .3s ease-in-out;
	text-decoration  : none;
	appearance       : none;
	display: inline-block;
	@include break(ss-small) {
		font-size     : 15px;
		line-height   : 19px;
		border-radius : 9px;
		padding       : 7px 12px;
	}

	&:hover {
		background-color : $dark_blue;
	}

	&.disabled,
	&:disabled {
		box-shadow       : 0px 4px 16px rgba(0, 0, 0, 0.15);
		color            : $gray3;
		background-color : $gray1;
	}
}

.white-btn {
	font-size        : 20px;
	line-height      : 26px;
	color            : $dark_blue;
	background-color : $white;
	border-radius    : 16px;
	padding          : 12px 27px;
	display          : inline-block;
	transition       : .3s ease-in-out;
	appearance       : none;

	@include break(ss-small) {
		font-size     : 15px;
		line-height   : 19px;
		padding       : 7px 12px;
		border-radius : 9px;
	}

	&:hover {
		background-color : $dark_blue;
		color            : $white;
	}

	@include break(ss-small) {
		font-size   : 15px;
		line-height : 19px;
		padding     : 7px 15px;
	}
}

.not-found{
	font-size: 35px;
	line-height: 50px;
	text-align: center;
	@include break(ss-small){
		font-size: 30px;
		line-height: 34px;
	}
}

.border-btn {
	border: 1px solid #021D59;
}

button, input[type="submit"] {
	cursor     : pointer;
	appearance : none;
}

a {
	text-decoration : none;
}

#primary-wrapper{
	position: relative;
}

#wrapper {
	display : flex;
	flex-direction : column;
	min-height : 100vh;
	justify-content : space-between;
}

#site-footer {
	margin-top : auto;
}


.hidden {
	display : none !important;
}

.row {
	display      : flex;
	flex-wrap    : wrap;
	margin-left  : -15px;
	margin-right : -15px;
}

.text-center {
	text-align      : center;
	justify-content : center;
}

h1, h2, h3, h4, h5, h5, p {
	&:empty {
		display : none;
	}
}

ul, ol, li, a, span {
	list-style : none;
}

ul {
	li {

	}
}

h1 {

}

h2 {
	font-family : $main_font;
	font-style  : normal;
	font-weight : 400;
	font-size   : 60px;
	line-height : 1.1;
	color       : #1C143A;
	margin      : 0 0 30px 0;
	padding     : 0;
}

h3 {

}

h4 {

}

h5 {

}

h6 {

}


.spacer_big {
	width    : 100%;
	height   : 48px;
	overflow : hidden;
}

.spacer_super_small {
	width    : 100%;
	height   : 18px;
	overflow : hidden;
}

.spacer_small {
	width    : 100%;
	height   : 24px;
	overflow : hidden;
}

p {


}

strong, b {
	font-weight : 700;
}

.container {
	width         : 100%;
	max-width     : 1440px;
	padding-left  : 60px;
	padding-right : 60px;
	margin        : 0 auto;

	@include break(large1370) {
		max-width     : 1200px;
		padding-left  : 40px;
		padding-right : 40px;
	}

	@include break(desktop) {
		max-width : 1080px;
	}
	@include break(tablet) {
		max-width : 900px;
	}

	@include break(medium) {
		/*max-width : 760px;*/
		max-width : 100%;
	}

	@include break(small) {
		max-width : 100%;
	}
	@include break(ss-small) {
		max-width     : 100%;
		padding-left  : 20px;
		padding-right : 20px;
	}
}


.primary-btn {
	font-family   : $main_font;
	font-style    : normal;
	font-weight   : 400;
	font-size     : 20px;
	line-height   : 26px;
	color         : #021D59;
	padding       : 11px 27px;
	border        : 1px solid #021D59;
	border-radius : 16px;
	display       : inline-block;
	transition       : 0.3s;
	background-color: transparent;
	@media(max-width : 768px) {
		font-size : 18px;
	}

	@media(max-width : 480px) {
		font-size : 17px;
		padding   : 10px 28px;
		border-radius : 9px;
	}

	@media(max-width : 390px) {
		font-size : 15px;
		line-height: 19px;
		padding   : 6px 30px;
	}

	&:hover {
		background-color : #021D59;
		color            : #fff;
	}
}
.select-mobile-menu{
	&__wrapper {
		position : relative;
		height : 61px;

		@media( max-width: 480px) {
			height : 40px;
		}
	}

	&__menu {
		position : absolute;
		display : flex;
		flex-direction : column;
		width : 100%;
		min-height : 61px;
		overflow: hidden;
		max-height: 100%;
		border-radius: 12.1977px;
		box-shadow: 0px 5.95122px 5.95122px rgba(49, 197, 255, 0.25);
		background: #F2F2F2;
		border: 1.4878px solid #15BDFF;
		padding :19px 22px;
		z-index : 5;
		transition: 0.5s;

		@media( max-width: 480px) {
			min-height : 40px;
			padding: 12px 15px;
			border-radius: 8px;
		}

		&:before {
			content: "";
			position : absolute;
			right: 29px;
			top: 26px;
			width : 14px;
			height : 8px;
			background-repeat : no-repeat;
			background-position : center center;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMSAwLjc4MTI1TDguNDM5MDIgOC4yMjAyN0wxNS44NzggMC43ODEyNSIgc3Ryb2tlPSIjMDIxRDU5IiBzdHJva2Utd2lkdGg9IjEuNDg3OCIvPjwvc3ZnPg==);

			@media( max-width: 480px) {
				top : 17px;
				right: 20px;
			}

		}

		&.open {
			max-height: 100vh;

			&:before {
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUuODc4OSA5LjIxODc1TDguNDM5ODggMS43Nzk3M0wxLjAwMDg2IDkuMjE4NzUiIHN0cm9rZT0iIzAyMUQ1OSIgc3Ryb2tlLXdpZHRoPSIxLjQ4NzgiLz48L3N2Zz4=);

			}
		}

		&-item {
			display : block;
			order:1;
			&:not(:last-child){
				margin-bottom : 25px;
				@media( max-width: 480px) {
					margin-bottom : 20px;
				}
			}
			&.active {
				padding-bottom : 19px;
				margin-bottom : 29px;
				border-bottom: 1px solid #DADADA;
				order : 0;
				@media( max-width: 480px) {
					padding-bottom : 12px;
					margin-bottom : 25px;
				}
			}

			a, span {
				font-family: $second_font;
				font-style: normal;
				font-weight: 400;
				font-size: 19px;
				line-height: 1.43;
				color: $dark_blue;
				display : block;

				@media( max-width: 480px) {
					font-size : 13px;
				}
			}

			span {
				font-weight: 700;
			}


		}
	}
}