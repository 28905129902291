/**
	Connect fonst by the mixin
	@include font-face('Font name', 'File name', Font weight)
 */
@font-face {
	font-family: 'Agrandir-regular';
	src: url('../fonts/PPAgrandir-Regular.woff2') format('woff2'),
	url('../fonts/PPAgrandir-Regular.woff') format('woff'),
	url('../fonts/PPAgrandir-Regular.ttf') format('truetype');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

 @font-face {
	font-family: 'Agrandir';
	src: url('../fonts/PPAgrandir-NarrowRegular.woff2') format('woff2'),
	url('../fonts/PPAgrandir-NarrowRegular.woff') format('woff'),
	url('../fonts/PPAgrandir-NarrowRegular.ttf') format('truetype');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}
@font-face {
	font-family: 'Agrandir';
	src: url('../fonts/PPAgrandir-Bold.woff2') format('woff2'),
	url('../fonts/PPAgrandir-Bold.woff') format('woff'),
	url('../fonts/PPAgrandir-Bold.ttf') format('truetype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica';
	src: url('../fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
	url('../fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
	url('../fonts/HelveticaNeueLTPro-Lt.ttf') format('truetype');
	font-weight: 300;
	font-display: swap;
	font-style: normal;
}
 
@font-face {
	font-family: 'Helvetica';
	src: url('../fonts/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
	url('../fonts/HelveticaNeueLTPro-Roman.woff') format('woff'),
	url('../fonts/HelveticaNeueLTPro-Roman.ttf') format('truetype');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica';
	src: url('../fonts/HelveticaNeueLTPro-Bd.woff2') format('woff2'),
	url('../fonts/HelveticaNeueLTPro-Bd.woff') format('woff'),
	url('../fonts/HelveticaNeueLTPro-Bd.ttf') format('truetype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Kanit';
	src: url('../fonts/Kanit-Bold.woff2') format('woff2'),
	url('../fonts/Kanit-Bold.woff') format('woff'),
	url('../fonts/Kanit-Bold.ttf') format('truetype');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}