.video-wrapper-v2{
  margin-top: 135px;
  margin-bottom: 120px;
  @include break(tablet){
    margin-top: 85px;
    margin-bottom: 60px;
  }
  @include break(ss-small){
    margin-top: 95px;
    margin-bottom: 75px;
  }
  &__container{
    max-width: 1365px;
  }
  &__title{
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    margin-bottom: 75px;
    text-align: center;
    @include break(medium){
      max-width: 470px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 35px;
      font-size: 35px;
      line-height: 41px;
    }
    @include break(ss-small){
      max-width: 305px;
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 34px;
    }
  }
  .video-wrapper__iframe{
    width: 100%;
    height: 600px;
    @include break(large1750){
      height: 500px;
    }
    @include break(tablet){
      height: 400px;
    }
    @include break(ss-small){
      height: 300px;
    }
  }
  &__iframe-creator{
    position: relative;
    border-radius: 18px;
    overflow: hidden;
    padding: 0;
    box-shadow: none;
    display: block;
    &:hover{
      &::before{
        opacity: 0;
      }
      .video-wrapper-v2__play-btn{
        transform: scale(1.1) translate(-50%,-50%);
      }
    }
    &::before{
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      height: 100%;
      background-color: rgba($color: #243863, $alpha: .4);
      transition: .3s linear;
    }
    img{
      display: block;
    }
  }
  &__play-btn{
    opacity: 1;
    transition: .3s linear;
    @include break(medium){
      width: 36px;
    }
    @include break(x-small){
      width: 16px;
    }
  }
  &__content{
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 65px;
    @include break(medium){
      padding-left: 30px;
      padding-right: 30px;
      margin-top: 45px;
    }
    @include break(ss-small){
      margin-top: 20px;
      padding-left: 0;
      padding-right: 0;
    }
    p{
      font-weight: 300;
      font-size: 42px;
      color: $azure;
      font-family: $second_font;
      @include break(desktop){
        font-size: 19px;
        line-height: 27px;
      }
      @include break(ss-small){
        font-size: 20px;
        line-height: 29px;
      }
    }
  }
}