.platform-banner  {
	padding-top : 26px;
	padding-bottom : 38px;

	@media( max-width: 768px) {
		padding-bottom : 34px;
		padding-top : 30px;
	}

	@media( max-width: 480px) {
		padding-top : 25px;
		padding-bottom : 16px;
	}

	&__wrap {
		position : relative;
		display : flex;
		align-items : flex-start;
		justify-content: center;
		flex-direction: column;
		min-height : 585px;
		@media( max-width: 768px) {
			align-items: center;
		}
	}
	&__background {
		position : absolute;
		top : 0;
		left : 0;
		width : 100%;
		height : 100%;
		border-radius: 35px;
		overflow: hidden;
		box-shadow: 0px 10px 20px rgba(28, 20, 58, 0.2);
		&::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(90deg, #021D59 3.88%, rgba(2, 29, 89, 0.44) 82.64%);
      z-index: 10;
    }
		@media( max-width: 768px) {
			border-radius: 25px;
		}

		@media( max-width: 480px) {
			border-radius: 21px;
		}

		/*&:before {
			content: "";
			position : absolute;
			top : 0;
			left : 0;
			z-index : 1;
			width : 100%;
			height : 100%;
		    background: linear-gradient(90deg, #021D59 3.88%, rgba(2, 29, 89, 0.44) 82.64%);

			@media( max-width: 480px) {
				background: linear-gradient(15.92deg, rgba(2, 29, 89, 0.9) 44.53%, rgba(2, 29, 89, 0) 100.01%);
			}

		}*/

		img {
			width : 100%;
			height : 100%;
			object-fit: cover;
			position: relative;
		}
	}

	&__background-desktop {
		@media( max-width: 768px) {
			display : none;
		}
	}

	&__background-tablet {
		display : none;

		@media( max-width: 768px) {
			display : block;
		}

		@media( max-width: 480px) {
			display : none;
		}
	}

	&__background-mobile {
		display : none;

		@media( max-width: 480px) {
			display : block;
		}
	}

	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 75px;
		line-height: 1.13;
		color: $white;
		max-width : 970px;
		padding-left : 76px;
		position : relative;
		z-index : 20;

		@media( max-width: 768px) {
			max-width : 530px;
			margin-left: auto;
			margin-right: auto;
			font-size: 65px;
			text-align : center;
			padding-left : 0;
		}

		@media( max-width: 480px) {
			max-width : 100%;
			font-size: 45px;
			width: 100%;
		}
	}

	&__link {
		z-index: 22;
		color: #021D59;
		margin: 45px 0 0 75px;
		font-size: 24px;
		padding: 15px 50px 15px 20px;
		background-color: #5ddda3;
		border-radius: 17px;
		&::after{
			content: '';
			width: 35px;
			height: 20px;
			position: absolute;
			background-position: center;
         	background-repeat: no-repeat;
			background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAmElEQVR4nO3Z0QkCMRBF0WGKMIskLFvUQgbsQMsV3a1GWf2whZfxng4eNx8JMQMAQITXfrV5nWxkXuPmLV5e+3PsMeVy8hr3z5gWu7V+tmEVxogqlBFFGVWUUUUZVZRRlarMvE7H1f/7BIjHMc6GlGLI0svvaPVtzKPFCBWUUEEJFZRQQQkVlFCxZLgAWqZvBUvy0QMA+EtvE6Gmhxv+kxgAAAAASUVORK5CYII=");
			z-index: 100;
			margin-top: 1px;
		  }
		@media( max-width: 768px) {
			margin: 40px 0 0 0;
		}
	}

}
