.culture-and-values{
  margin-top: 10px; 
  margin-bottom: 140px;
  position: relative;
  &::before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(179.06deg, #E8F5FA 8.95%, rgba(241, 250, 253, 0) 81.62%);
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: -1;
  }
  @include break(tablet){
    margin-top: 45px;
    margin-bottom: 85px;
  }
  @include break(ss-small){
    margin-top: 30px;
    margin-bottom: 100px;
  }
  &.style-2{
    margin-top: 100px;
    margin-bottom: 160px;
    @include break(tablet){
      margin-top: 110px;
      margin-bottom: 140px;
    }
    @include break(ss-small){
      margin-top: 55px;
      margin-bottom: 70px;
    }
  }
  &__container{
    position: relative;
    isolation: isolate;
    .floating-element{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
      &-1{
        background-image: url("data:image/svg+xml,%3Csvg width='246' height='246' viewBox='0 0 246 246' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='116.625' y='-3.93934' width='188.5' height='170.5' rx='13.25' transform='rotate(45 116.625 -3.93934)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
        width: 246px;
        height: 246px;
        top: 0;
        left: -97px;
        @include break(tablet){
          background-image: url("data:image/svg+xml,%3Csvg width='131' height='131' viewBox='0 0 131 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='65.2109' y='-5.52161' width='100.031' height='100.031' rx='14.884' transform='rotate(45 65.2109 -5.52161)' stroke='%231C143A' stroke-opacity='0.5' stroke-width='0.676543' stroke-dasharray='4.85 4.85'/%3E%3C/svg%3E%0A");
          width: 131px;
          top: 123px;
          left: -26px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='57' height='82' viewBox='0 0 57 82' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='16.3594' y='-4.56255' width='64.9449' height='64.9449' rx='13.6101' transform='rotate(45 16.3594 -4.56255)' stroke='%231C143A' stroke-opacity='0.4' stroke-width='0.618642' stroke-dasharray='4.44 4.44'/%3E%3C/svg%3E%0A");
          width: 57px;
          height: 82px;
          left: 0;
          top: 30px;
        }
      }
      &-2{
        background-image: url("data:image/svg+xml,%3Csvg width='78' height='72' viewBox='0 0 78 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M26.9554 0H51.0446C56.3786 0 61.2969 2.85838 63.9465 7.49455L75.9998 28.4967C78.6667 33.1329 78.6667 38.8497 75.9998 43.5033L63.9465 64.5055C61.2795 69.1416 56.3612 72 51.0446 72H26.9554C21.6214 72 16.7032 69.1416 14.0535 64.5055L2.00022 43.5033C-0.66674 38.8671 -0.66674 33.1503 2.00022 28.4967L14.0535 7.49455C16.7205 2.85838 21.6388 0 26.9554 0Z' fill='%23C6EFF4'/%3E%3C/svg%3E%0A");
        width: 78px;
        height: 72px;
        top: 166px;
        right: 29px;
        @include break(tablet){
          background-image: url("data:image/svg+xml,%3Csvg width='77' height='77' viewBox='0 0 77 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='77' height='77' rx='16' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
          width: 77px;
          height: 77px;
          right: -26px;
          top: 139px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.2' x='10.6875' y='-2' width='47.3302' height='47.3302' rx='10' transform='rotate(15.5497 10.6875 -2)' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
          width: 54px;
          height: 54px;
          top: 57px;
          left: 31px;
        }
      }
      &-3{
        display: none;
        @include break(small){
          background-image: url("data:image/svg+xml,%3Csvg width='104' height='104' viewBox='0 0 104 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='104' height='104' rx='16' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
          display: block;
          width: 104px;
          height: 104px;
          top: -25px;
          left: 14.7%;
        }
        @include break(ss-small){
          display: none;
        }
      }
    }
  }
  &__title{
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    margin-bottom: 15px;
    text-align: center;
    @include break(medium850){
      font-size: 35px;
      line-height: 41px;
      margin-bottom: 10px;
    }
    @include break(ss-small){
      font-size: 30px;
      line-height: 34px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    &.style-2{
      max-width: 730px;
      margin-left: auto;
      margin-right: auto;
      font-size: 75px;
      line-height: 85px;
      margin-bottom: 20px;
      @include break(medium850){
        font-size: 65px;
        line-height: 62px;
        margin-bottom: 15px;
        max-width: 530px;
      }
      @include break(ss-small){
        max-width: 205px;
        font-size: 25px;
        line-height: 34px;
        margin-bottom: 10px;
      }
    }
  }
  &__text{
    font-size: 23px;
    line-height: 30px;
    margin-bottom: 50px;
    text-align: center;
    max-width: 635px;
    margin-left: auto;
    margin-right: auto;
    color: $azure;
    @include break(large1370){
      margin-bottom: 65px;
    }
    @include break(medium850){
      font-size: 22px;
      line-height: 24px;
      max-width: 515px;
    }
    @include break(ss-small){
      font-size: 18px;
      line-height: 24px;
      max-width: 300px;
      margin-bottom: 60px;
    }
    &.style-2{
      max-width: 615px;
      font-size: 23px;
      line-height: 30px;
      color: $dark_blue3;
      margin-bottom: 0;
      font-family: $main_font;
      @include break(medium850){
        line-height: 35px;
        max-width: 425px;
      }
      @include break(ss-small){
        max-width: 285px;
        font-size: 15px;
        line-height: 21px;
      }
    }
  }
  &__button-wrapper{
    text-align: center;
    margin-top: 45px;
    @include break(medium850){
      margin-top: 30px;
    }
  }
  &__button{
    @extend .azure-btn;
  }
  &__wrapper{
    max-width: 1030px;
    margin-left: auto;
    margin-right: auto;
    &.style-2{
      padding-bottom: 120px;
      max-width: 1200px;
      @include break(medium850){
        padding-bottom: 100px;
      }
      @include break(ss-small){
        padding-bottom: 0;
      }
    }
  }
  &__block{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    column-gap: 20px;
    padding: 130px 0;
    @include break(desktop){
      padding: 80px 0;
    }
    @include break(medium){
      padding:0 0 100px 0;
    }
    @include break(s-small){
      padding:0 0 70px 0;
      display: block;
    }
    &:first-child{
      padding-top: 150px;
      @include break(desktop){
        padding-top: 90px;
      }
      @include break(medium){
        padding-top: 20px;
      } 
      @include break(x-small){
        padding-top: 0;
      }
      .culture-and-values__block-image{
        @include break(small){
          top: -20px;
        }
        @include break(s-small){
          top: auto;
        }
      }
    }
    &.style-2{
      &:first-child{
        @include break(medium){
          padding-top: 100px;
        } 
        @include break(ss-small){
          padding-top: 50px;
        }
      }
      &:nth-child(even){
        @media screen and (min-width:1031px) {
          .culture-and-values__block-content{
            text-align: right;
          }
        }
      }
    }
    &:last-child{
      padding-bottom: 90px;
      @include break(desktop){
        padding-bottom: 40px;
      }
      @include break(s-small){
        padding-bottom: 80px;
      }
    }
    &:nth-child(odd){
      .culture-and-values__block-content-wrapper{
        padding-left: 49px;
        @include break(large1370){
          padding-left: 0;
        }
      }
      .culture-and-values__block-image{
        right: 0;
      }
      .culture-and-values__block-image-wrapper{
        @include break(s-small){
          direction: rtl;
        }
      }
    }
    &:nth-child(even){
      .culture-and-values__block-content-wrapper{
        order: 1;
        padding-right: 49px;
        @include break(large1370){
          padding-right: 0;
        }
      }
      .culture-and-values__block-image-wrapper{
        order: 2;
      }
      .culture-and-values__block-image{
        left: 0;
      }
    }
    &-image-wrapper{
      height: 100%;
      position: relative;
      @include break(s-small){
        margin-right: -20px;
        margin-left: -20px;
        margin-bottom: 35px;
      }
    }
    &-image{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @include break(large1370){
        max-width: 130%;
        height: auto;
      }
      @include break(desktop){
        max-width: 120%;
      }
      @include break(small){
        max-width: none;
        top: auto;
        transform: translateY(0);
      }
      @media screen and (max-width:750px){
        max-width: 110%;
      }
      @include break(s-small){
        max-width: none;
        position: relative;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-content-wrapper{

    }
    &-content{
      @include break(ss-small){
        padding-left: 20px;
        padding-right: 20px;
      }
      &.style-2{
        @media screen and (min-width:1031px) {
          h1,h2,h3,h4,h5,h6{
            color: #606060;
            font-size: 35px;
            line-height: 43px;
          }
        }
      }
      p{
        color: $dark_blue3;
        margin-bottom: 0;
        opacity: 0.7;
        font-family: $second_font;
        font-weight: 300;
        font-size: 22px;
        line-height: 31px;
        @include break(medium850){
          font-size: 18px;
          line-height: 26px;
        }
        @include break(s-small){
          font-size: 18px;
          line-height: 26px;
          text-align: center;
        }
      }
      h1,h2,h3,h4,h5,h6{
        font-weight: 400;
        font-size: 25px;
        line-height: 33px;
        margin-bottom: 10px;
        @include break(medium850){
          font-size: 22px;
          line-height: 24px; 
        }
        @include break(s-small){
          font-size: 20px;
          line-height: 29px;
          margin-bottom: 15px;
          text-align: center;
        }
      }
    }
  }
}