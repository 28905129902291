.single-resources-download-content{
  margin-top: 65px;
  margin-bottom: 65px;
  @include break(medium){
    margin-top: 90px;
    margin-bottom: 35px;
  }
  @include break(ss-small){
    margin-top: 50px;
  } 
  &__wrapper{
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 55.65% 37.9%;
    grid-column-gap: 6.45%;
    column-gap: 6.45%;
    @include break(tablet){
      grid-template-columns: 1fr;
      grid-row-gap: 30px;
      row-gap: 30px;
    }
    @include break(x-small){
      grid-row-gap: 50px;
      row-gap: 50px;
    }
  }
  &__block-container{
    display: flex;
    align-items: center;
    padding:50px 0;
    position: relative;
    @include break(tablet){
      display: block;
      padding: 0;
    }
  }
  &__back{
    position: absolute;
    left: 0;
    bottom: 13px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    font-size: 16px;
    line-height: 23px;
    color: rgba(28, 20, 58, 0.6);
    padding-left: 45px;
    transition: .3s linear;
    @include break(tablet){
      display: none;
    }
    &::before{
      content: '';
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='31' height='16' viewBox='0 0 31 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.292891 7.29289C-0.0976334 7.68342 -0.0976334 8.31658 0.292891 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292891 7.29289ZM31 7L0.999998 7V9L31 9V7Z' fill='%231C143A'/%3E%3C/svg%3E%0A");
      width: 31px;
      height: 23px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
    }
    &:hover{
      color: rgba(28, 20, 58, 1);
    }
  }
  &__block{
    display: grid;
    grid-template-columns: 42.75% 50%;
    grid-column-gap: 7.25%;
    column-gap: 7.25%;
    @include break(tablet){
      grid-template-columns:295px 1fr;
      grid-column-gap: 40px;
      column-gap: 40px;
    }
    @include break(small){
      grid-template-columns: 42.75% 50%;
      grid-column-gap: 7.25%;
      column-gap: 7.25%;
    }
    @include break(x-small){
      grid-template-columns:1fr;
      grid-row-gap: 45px;
      row-gap: 45px;
    }
  }
  &__content-wrapper{
    @include break(tablet){
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include break(x-small){
      order: 1;
    }
  }
  &__img-wrapper{
    position: relative;
    @include break(x-small){
      order: 2;
    }
  }
  &__badge{
    position: absolute;
    left: 0;
    top: 28px;
    padding: 9px 15px;
    font-size: 20px;
    line-height: 29px;
    min-width: 145px;
    text-align: center;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-bottomright: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    @include break(x-small){
      top: 19px;
      min-width: 120px;
      padding: 6px 20px;
    }
  }
  &__img{
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }
  &__title{
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 30px;
    @include break(desktop){
      font-size: 30px;
      line-height: 43px;
      margin-bottom: 15px;
    }
    @include break(ss-small){
      font-size: 18px;
      line-height: 24px;
    }
    @include break(x-small){
      margin-bottom: 30px;
    }
  }
  &__content{
    max-width: 295px;
    @include break(tablet){
      max-width: none;
    }
  }
  &__form-wrapper{
    box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
    border-radius: 20px;
    min-height: 720px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include break(tablet){
      min-height: 470px;
    }
    &.download{
      .single-resources-download-content__form{
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        display: none;
      }
      .single-resources-download-content__success{
        visibility: visible;
        opacity: 1;
        z-index: 100;
      }
    }
  }
  &__form{
    padding: 50px 35px 30px 35px;
    width: 100%;
    transition: .3s linear;
    @include break(tablet){
      padding: 45px 40px 50px 40px;
    }
    @include break(ss-small){
      padding: 40px 25px 30px 25px;
    }
    &-title{
      text-align: center;
      font-size: 22px;
      line-height: 27px;
      font-weight: 400;
      margin-bottom: 35px;
      @include break(tablet){
        margin-bottom: 40px;
      }
      @include break(x-small){
        text-align: left;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 24px;
      }
    }
    .wpcf7-form{
      &.sent{
        .wpcf7-response-output{
          display: none !important;
        }
      }
      .communications{
				line-height: 18px;
				*{
					line-height: 18px;
				}
				.wpcf7-list-item-label{
					display: none;
				}
			}
      .download-form{
        &__wrap{
          @include break(tablet){
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 45px;
            column-gap: 45px;
          }
          @include break(ss-small){
            grid-column-gap: 20px;
            column-gap: 20px;
          }
          @include break(x-small){
            display: block;
          }
        }
        &__col{
          width: 100%;
          margin-bottom: 15px;
        }
        &__label{
          font-style: normal;
          font-family: $second_font;
          font-weight: 400;
          font-size: 15px;
          line-height: 16px;
          display: block;
          color: rgba(28,20,58,0.6);
          margin-bottom: 5px;
          @include break(x-small){
            display: none;
          }
        }
        &__checkbox-wrap{
          width : 100%;
          display : flex;
          align-items : flex-start;
          @include break(tablet){
            margin-top: 10px;
          }
          @include break(x-small){
            mask-type: 5px;
          }
        }
        &__label-checkbox{
          font-family: $main_font;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.6);
          margin-left : 6px;
          @include break(x-small){
            font-size: 10px;
          }
        }
        &__submit{
          text-align: center;
          margin-top: 50px;
          position: relative;
          @include break(tablet){
            margin-top: 25px;
          }
          @include break(x-small){
            margin-top: 30px;
          }
          .wpcf7-submit{
            border: 1px solid $dark_blue;
            border-radius: 14.5737px;
            height : 40px;
            padding : 0 24px;
            cursor: pointer;
            transition: 0.3s;
            font-family: $main_font;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.35;
            display: flex;
            align-items: center;
            background-color : $white;
            position : relative;
            margin-left: auto;
            margin-right: auto;
            color: #1C143A;
            &:hover {
              background-color : $dark_blue;
              color: $white;
            }
          }
          .wpcf7-spinner{
            position: absolute;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
      select{
        width : 100%;
				background: #FFFFFF;
				border: 1px solid rgba(28, 20, 58, 0.17);
				border-radius: 14.5737px;
				height : 40px;
        font-size: 15px;
				padding : 0 10px;
        color: rgba(28, 20, 58, 0.6);
        @media( max-width: 480px) {
					height : 30px;
					border-radius: 10px;
          font-size: 12px;
				}
      }
      input[type="text"],input[type="email"], input[type="tel"] {
				width : 100%;
				background: #FFFFFF;
				border: 1px solid rgba(28, 20, 58, 0.17);
				border-radius: 14.5737px;
				height : 40px;
        font-size: 15px;
				padding : 0 10px;
        color: rgba(28, 20, 58, 0.6);
        &.wpcf7-not-valid{
          border-color: #dc3232;
        }
				@media( max-width: 480px) {
					height : 30px;
					border-radius: 10px;
          font-size: 12px;
				}

				&::placeholder {
					font-family: $second_font;
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 14px;
					display: flex;
					align-items: center;
					color: rgba(28, 20, 58, 0.6);
					opacity: 0;

					@media( max-width: 480px) {
						opacity: 1;
					}
				}
			}
    } 
  }
  &__success{
    padding: 35px;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%,-50%);
    transition: .3s linear;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    &-content{

    }
    &-icon{
      max-width: 137px;
      width: 100%;
      margin: 0 auto 65px;
    }
    &-text{
      text-align: center;
      font-family: $main_font;
      font-size: 22px;
      line-height: 27px;
      margin-bottom: 0;
      @include break(x-small){
        font-size: 20px;
        line-height: 29px;
      }
    }
    &-file{
      display: none !important;
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }
}