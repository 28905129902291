.resources-three-columns-images{
  margin-top: 85px;
  margin-bottom: 130px;
  @include break(tablet) {
    margin-top: 50px;
    margin-bottom: 75px;
  }
  @include break(ss-small){
    margin-top: 40px;
    margin-bottom: 45px;
  }
  &__wrapper{

  }
  &__slider{
    &.swiper{
      &.swiper-initialized{
        overflow: visible;
      }
      .swiper-pagination{
        position: relative;
        bottom: auto;
        margin-top: 20px;
        justify-content: center;
        display: none;
        @include break(x-small){
          display: flex;
        }
        &-bullet{
          width: 14px;
          height: 14px;
          background-color: transparent;
          border: 1px solid $dark_blue;
          margin: 0 5px !important;
          opacity: 1;
          transition: .3s linear;
          &-active{
            background-color: $dark_blue;
          }
        }
      }
    }
  }
  &__item{

  }
  &__image{
    display: block;
    object-fit: cover;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
    max-width: 325px;
    height: auto;
    @include break(small){
      max-width: none;
    }
    &-desktop{
      @include break(small){
        display: none;
      }
    }
    &-tablet{
      display: none;
      @include break(small){
        display: block;
      }
    }
  }
  &__text{
    margin-bottom: 0;
    margin-top: 15px;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    color: $black;
    opacity: 0.5;
    @include break(medium) {
      margin-top: 25px;
      font-size: 15px;
      line-height: 21px;
    }
    @include break(x-small){
      font-size: 13px;
      line-height: 19px;
    }
  }
}