.last-event {
	margin-top    : 88px;
	margin-bottom : 165px;
	@include break(medium) {
		margin-top    : 55px;
		margin-bottom : 180px;
	}
	@include break(ss-small) {
		margin-top    : 35px;
		margin-bottom : 136px;
	}

	&.animate {
		.last-event__description {
			transform : translate3d(0, 0, 0);
			opacity   : 1;
		}

		.square {
			transform : translate3d(0, -50%, 0) rotate(-45deg);
			opacity   : 1;
			@include break(medium) {
				transform : translate3d(0, 0, 0) rotate(-30deg);
			}
			@include break(x-small) {
				transform : translate3d(0, 0, 0) rotate(0);
			}
		}

		.last-event__image {
			transform : translate3d(0, 0, 0);
			opacity   : 1;
		}
	}

	&__container {
		position : relative;

		.floating-element {
			position            : absolute;
			background-position : center;
			background-repeat   : no-repeat;

			&-1 {
				background-image : url("data:image/svg+xml,%3Csvg width='246' height='246' viewBox='0 0 246 246' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='116.623' y='-3.93934' width='188.5' height='170.5' rx='13.25' transform='rotate(45 116.623 -3.93934)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
				width            : 246px;
				height           : 246px;
				top              : -81px;
				left             : -97px;
				@include break(medium) {
					background-image : url("data:image/svg+xml,%3Csvg width='111' height='131' viewBox='0 0 111 131' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='45.2109' y='-5.52161' width='100.031' height='100.031' rx='14.884' transform='rotate(45 45.2109 -5.52161)' stroke='%231C143A' stroke-opacity='0.5' stroke-width='0.676543' stroke-dasharray='4.85 4.85'/%3E%3C/svg%3E%0A");
					width            : 111px;
					height           : 131px;
					left             : 0;
					top              : -33px;
				}
				@include break(ss-small) {
					background-image : url("data:image/svg+xml,%3Csvg width='57' height='82' viewBox='0 0 57 82' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='16.3613' y='-4.56255' width='64.9449' height='64.9449' rx='13.6101' transform='rotate(45 16.3613 -4.56255)' stroke='%231C143A' stroke-opacity='0.4' stroke-width='0.618642' stroke-dasharray='4.44 4.44'/%3E%3C/svg%3E%0A");
					top              : -24px;
					width            : 57px;
					height           : 82px;
				}
			}

			&-2 {
				background-image : url("data:image/svg+xml,%3Csvg width='78' height='72' viewBox='0 0 78 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M26.9554 0H51.0446C56.3786 0 61.2969 2.85838 63.9465 7.49455L75.9998 28.4967C78.6667 33.1329 78.6667 38.8497 75.9998 43.5033L63.9465 64.5055C61.2795 69.1416 56.3612 72 51.0446 72H26.9554C21.6214 72 16.7032 69.1416 14.0535 64.5055L2.00022 43.5033C-0.66674 38.8671 -0.66674 33.1503 2.00022 28.4967L14.0535 7.49455C16.7205 2.85838 21.6388 0 26.9554 0Z' fill='%23C6EFF4'/%3E%3C/svg%3E%0A");
				width            : 78px;
				height           : 72px;
				top              : 88px;
				right            : 29px;
				@include break(large1370) {
					right : 10px;
				}
				@include break(medium) {
					background-image : url("data:image/svg+xml,%3Csvg width='98' height='132' viewBox='0 0 98 132' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='85.0332' y='-5.03125' width='104' height='104' rx='16' transform='rotate(60 85.0332 -5.03125)' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
					width            : 98px;
					height           : 132px;
					right            : 0;
					top              : 10px;
				}
				@include break(ss-small) {
					display : none;
				}
			}

			&-3 {
				background-image : url("data:image/svg+xml,%3Csvg width='100' height='104' viewBox='0 0 100 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.4746 12.8906L48.3899 1.61503C55.0139 -0.88165 62.4543 0.351581 67.9061 4.84557L92.6654 25.1802C98.1387 29.6661 100.804 36.7368 99.6613 43.741L94.4839 75.3593C93.3333 82.3418 88.558 88.1794 81.9555 90.6679L52.0402 101.944C45.4163 104.44 37.976 103.207 32.5242 98.713L7.76472 78.3784C2.29141 73.8925 -0.373683 66.8217 0.768839 59.8176L5.94631 28.1993C7.09696 21.2167 11.8722 15.3792 18.4746 12.8906Z' fill='%23F5E8FD'/%3E%3C/svg%3E%0A");
				width            : 100px;
				height           : 104px;
				right            : 61px;
				bottom           : -63px;
				top              : auto;
				display          : none;
				@include break(medium) {
					display : block;
				}
				@include break(ss-small) {
					background-image : url("data:image/svg+xml,%3Csvg width='62' height='85' viewBox='0 0 62 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5351 10.6528L40.1424 1.37787C45.591 -0.675819 51.7112 0.338595 56.1957 4.03519L76.5619 20.7618C81.0641 24.4517 83.2563 30.2679 82.3165 36.0292L78.0577 62.0374C77.1112 67.781 73.1833 72.5827 67.7523 74.6297L43.145 83.9047C37.6964 85.9584 31.5762 84.9439 27.0918 81.2473L6.72548 64.5208C2.22333 60.8309 0.0311099 55.0147 0.970909 49.2533L5.22972 23.2452C6.1762 17.5015 10.1041 12.6998 15.5351 10.6528Z' fill='%23F5E8FD'/%3E%3C/svg%3E%0A");
					width            : 62px;
					height           : 85px;
					right            : 0;
					bottom           : -44px;
				}
			}
		}
	}

	&__wrapper {
		background-color      : $dark_blue;
		border-radius         : 40px;
		overflow              : hidden;
		display               : grid;
		grid-template-columns : 575px 1fr;
		position              : relative;
		z-index: 100;
		min-height            : 499px;
		box-shadow            : 0px 10px 30px rgba(28, 20, 58, 0.3);
		@include break(large1370) {
			grid-template-columns : 440px 1fr;
		}
		@include break(desktop) {
			grid-template-columns : 420px 1fr;
		}
		@include break(tablet) {
			grid-template-columns : 340px 1fr;
		}
		@include break(medium) {
			min-height     : 499px;
			position       : relative;
			padding-bottom : 145px;
			display        : block;
			padding-top    : 35px;
			padding-left   : 48px;
			border-radius  : 30px;
			box-shadow     : 0px 10px 20px rgba(28, 20, 58, 0.2);
		}
		@include break(ss-small) {
			padding-top    : 20px;
			padding-left   : 30px;
			padding-right  : 30px;
			padding-bottom : 300px;
			border-radius  : 20px;
			min-height     : auto;
		}
		@include break(x-small) {
			padding-bottom : 160px;
		}
	}

	&__description {
		padding-top    : 70px;
		padding-bottom : 72px;
		padding-left   : 96px;
		padding-right  : 50px;
		transition     : 1.3s ease-in-out;
		transform      : translate3d(0, 100%, 0);
		opacity        : 0;
		@include break(large1370) {
			padding-left  : 60px;
			padding-right : 0;
		}
		@include break(desktop) {
			padding-left  : 40px;
			padding-right : 0;
		}
		@include break(medium) {
			padding-top    : 0;
			padding-left   : 0;
			padding-bottom : 0;
			position       : relative;
			z-index        : 100;
			max-width      : 350px;
		}
		@include break(ss-small) {
			max-width    : 380px;
			margin-left  : auto;
			margin-right : auto;
			text-align   : center;
		}
		@include break(x-small) {
			max-width : 270px;
		}
	}

	&__heading {
		display       : flex;
		align-items   : center;
		/*height: 45px;*/
		margin-bottom : 40px;
		@include break(tablet) {
			/*height: 31px;*/
		}
		@include break(medium) {
			/*height: 22px;*/
		}
		@include break(tablet) {
			margin-bottom : 33px;
		}
		@include break(ss-small) {
			justify-content : center;
			margin-bottom   : 30px;
		}

		&-event-logo {
			display     : flex;
			align-items : center;
			height      : 100%;

			img, svg {
				display   : block;
				height    : auto;
				max-width : 100%;
			}

			.evergent {
				@include break(tablet) {
					width : 112px;
				}
				@include break(x-small) {
					width : 87px;
				}
			}

			.event {
				@include break(tablet) {
					/*width: 88px;*/
				}
				@include break(x-small) {
					/*width: 68px;*/
					width      : auto;
					max-height : 50px;
				}
			}

			&:not(:last-child) {
				margin-right : 66px;
				position     : relative;
				@include break(tablet) {
					margin-right : 25px;
				}
				@include break(ss-small) {
					margin-right : 20px;
				}

				&::before {
					content          : '';
					display          : block;
					width            : 1px;
					height           : 100%;
					background-color : rgba(255, 255, 255, 0.4);
					position         : absolute;
					top              : 0;
					right            : -33px;
					@include break(tablet) {
						right : -13px;
					}
					@include break(ss-small) {
						right : -10px;
					}
				}
			}
		}
	}

	&__title {
		font-size     : 60px;
		line-height   : 66px;
		margin-bottom : 5px;
		color         : $white;
		@include break(tablet) {
			font-size   : 48px;
			line-height : 58px;
		}
		@include break(medium) {
			margin-bottom : 8px;
			font-size     : 50px;
			line-height   : 55px;
		}
		@include break(ss-small) {
			margin-bottom : 12px;
			/*br{
			  display: none;
			}*/
		}
		@include break(x-small) {
			font-size   : 30px;
			line-height : 34px;
			max-width   : 276px;
		}
	}

	&__excerpt {
		font-size     : 22px;
		line-height   : 31px;
		margin-bottom : 35px;
		color         : $white;
		font-weight: 300;
		@include break(medium) {
			font-size   : 18px;
			line-height : 26px;
		}
		@include break(ss-small) {
			margin-left   : auto;
			margin-right  : auto;
			margin-bottom : 15px;
		}
		@include break(x-small) {
			font-size     : 13px;
			line-height   : 21px;
			max-width     : 255px;
			margin-left   : auto;
			margin-right  : auto;
			margin-bottom : 15px;
		}
	}

	&__date {
		font-size   : 34px;
		line-height : 38px;
		color       : $white;
		position    : relative;
		display     : inline-block;
		font-family: $main_font;
		margin-bottom: 0;
		@include break(medium) {
			font-size   : 22px;
			line-height : 24px;
		}
		@include break(x-small) {
			font-size   : 15px;
			line-height : 17px;
		}

		&::before {
			content          : '';
			display          : block;
			width            : 100%;
			height           : 1px;
			background-color : #675B96;
			position         : absolute;
			left             : 0;
			top              : 100%;
		}
	}

	&__image-wrapper {
		height   : 100%;
		position : relative;
		overflow : hidden;
		@include break(medium) {
			position : absolute;
			width    : 100%;
			height   : 100%;
			right    : 0;
			bottom   : 0;
		}

		.square {
			position      : absolute;
			width         : 747px;
			height        : 747px;
			border-radius : 116px;
			top           : 50%;
			opacity       : 0;
			@include break(medium) {
				width         : 457px;
				height        : 457px;
				border-radius : 83px;
				top           : auto;
			}
			@include break(x-small) {
				width         : auto;
				height        : auto;
				border-radius : 0;
				transform     : rotate(0);
			}

			&-1 {
				right            : -165px;
				background-color : $dark_blue2;
				transition       : 0.9s ease-in-out;
				transform        : translate3d(100%, -50%, 0) rotate(-45deg);
				@include break(large1470) {
					right : -215px;
				}
				@include break(large1370) {
					right : -315px;
				}
				@include break(desktop) {
					right : -390px;
				}
				@include break(medium) {
					right     : -94px;
					bottom    : -183px;
					transform : translate3d(100%, 0, 0) rotate(-30deg);
				}

				@include break(x-small) {
					right            : 0;
					bottom           : 0;
					background-image : url("data:image/svg+xml,%3Csvg width='258' height='161' viewBox='0 0 258 161' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='267.885' height='267.885' rx='49.84' transform='matrix(0.905871 -0.375099 0.355785 0.955047 -14.334 85.625)' fill='%231B1E62'/%3E%3C/svg%3E%0A");
					width            : 258px;
					height           : 161px;
					background-color : transparent;
					transform        : translate3d(100%, 0, 0) rotate(0);
				}
			}

			&-2 {
				right            : -220px;
				background-color : $azure;
				transition       : 1.1s ease-in-out;
				transform        : translate3d(120%, -50%, 0) rotate(-45deg);
				@include break(large1470) {
					right : -270px;
				}
				@include break(large1370) {
					right : -370px;
				}
				@include break(desktop) {
					right : -420px;
				}
				@include break(medium) {
					right     : -135px;
					bottom    : -193px;
					transform : translate3d(120%, 0, 0) rotate(-30deg);
				}
				@include break(x-small) {
					right            : 0;
					bottom           : 0;
					background-image : url("data:image/svg+xml,%3Csvg width='238' height='153' viewBox='0 0 238 153' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='267.885' height='267.885' rx='43.7988' transform='matrix(0.905871 -0.375099 0.355785 0.955047 -12.1016 87.4414)' fill='%2315BDFF'/%3E%3C/svg%3E%0A");
					width            : 238px;
					height           : 153px;
					background-color : transparent;
					transform        : translate3d(120%, 0, 0) rotate(0);
				}
			}
		}
	}

	&__image {
		position   : absolute;
		right      : 0;
		top        : 0;
		bottom     : 0;
		height     : 100%;
		object-fit : cover;
		display    : block;
		transition : 1.3s ease-in-out;
		transform  : translate3d(130%, 0, 0);
		opacity    : 0;
		@include break(large1470) {
			right : -50px;
		}
		@include break(large1370) {
			right : -150px;
		}
		@include break(desktop) {
			right : -180px;
		}
		@include break(medium) {
			right  : 0;
			bottom : 0;
			width  : 350px;
			height : 313px;
			top    : auto;
		}
		@include break(x-small) {
			width  : 224px;
			height : 147px;
		}
	}
}
.single-events{
	.last-event{
		margin-top    : 30px;
		margin-bottom : 115px;

		@include break(tablet) {
			margin-top    : 40px;
			margin-bottom : 85px;
		}
		@include break(ss-small) {
			margin-top    : 30px;
			margin-bottom : 60px;
		}
		.last-event__description {
			transform : translate3d(0, 0, 0);
			opacity   : 1;
		}
		.square {
			transform : translate3d(0, -50%, 0) rotate(-45deg);
			opacity   : 1;
			@include break(medium) {
				transform : translate3d(0, 0, 0) rotate(-30deg);
			}
			@include break(x-small) {
				transform : translate3d(0, 0, 0) rotate(0);
			}
		}
		.last-event__image {
			transform : translate3d(0, 0, 0);
			opacity   : 1;
		}
		&__container{
			.floating-element{
				&-1{
					bottom: -150px;
					left: -27px;
					top: auto;
					display: block;
					@include break(tablet){
						background-image: url("data:image/svg+xml,%3Csvg width='186' height='186' viewBox='0 0 186 186' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='88.0547' y='-3.19722' width='142.67' height='129.046' rx='10.0285' transform='rotate(45 88.0547 -3.19722)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.1353' stroke-dasharray='12.11 12.11'/%3E%3C/svg%3E%0A");
						width: 186px;
						height: 186px;
						left: -71px;
						bottom: -20px;
					}
					@include break(ss-small){
						background-image: url("data:image/svg+xml,%3Csvg width='69' height='82' viewBox='0 0 69 82' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='28.3594' y='-4.56255' width='64.9449' height='64.9449' rx='13.6101' transform='rotate(45 28.3594 -4.56255)' stroke='%231C143A' stroke-opacity='0.4' stroke-width='0.618642' stroke-dasharray='4.44 4.44'/%3E%3C/svg%3E%0A");
						left: 0;
						bottom: -28px;
						width: 69px;
						height: 82px;
					}
				}
				&-2{
					background-image: url("data:image/svg+xml,%3Csvg width='78' height='72' viewBox='0 0 78 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M26.9554 0H51.0446C56.3786 0 61.2969 2.85838 63.9465 7.49455L75.9998 28.4967C78.6667 33.1329 78.6667 38.8497 75.9998 43.5033L63.9465 64.5055C61.2795 69.1416 56.3612 72 51.0446 72H26.9554C21.6214 72 16.7032 69.1416 14.0535 64.5055L2.00022 43.5033C-0.66674 38.8671 -0.66674 33.1503 2.00022 28.4967L14.0535 7.49455C16.7205 2.85838 21.6388 0 26.9554 0Z' fill='%23C6EFF4'/%3E%3C/svg%3E%0A");
					width: 78px;
					height: 72px;
					right: 11px;
					top: -17px;
					bottom: auto;
					left: auto;
					transform: translate3d(0,0,0) !important;
					display: block;
					@include break(tablet){
						background-image: url("data:image/svg+xml,%3Csvg width='73' height='68' viewBox='0 0 73 68' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M25.2275 0H47.7725C52.7645 0 57.3676 2.67515 59.8474 7.01413L71.128 26.67C73.624 31.009 73.624 36.3593 71.128 40.7146L59.8474 60.3705C57.3514 64.7095 52.7483 67.3846 47.7725 67.3846H25.2275C20.2355 67.3846 15.6325 64.7095 13.1527 60.3705L1.872 40.7146C-0.624001 36.3756 -0.624001 31.0253 1.872 26.67L13.1527 7.01413C15.6487 2.67515 20.2517 0 25.2275 0Z' fill='%23C6EFF4'/%3E%3C/svg%3E%0A");
						width: 73px;
						height: 68px;
						right: 12px;
						top: -34px;
					}
					@include break(ss-small){
						background-image: url("data:image/svg+xml,%3Csvg width='68' height='85' viewBox='0 0 68 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5351 10.6528L40.1424 1.37787C45.591 -0.675819 51.7112 0.338594 56.1957 4.03519L76.5619 20.7618C81.0641 24.4517 83.2563 30.2679 82.3165 36.0292L78.0577 62.0374C77.1112 67.781 73.1833 72.5827 67.7523 74.6297L43.145 83.9047C37.6964 85.9584 31.5762 84.9439 27.0918 81.2473L6.72548 64.5208C2.22333 60.8309 0.0311099 55.0147 0.970909 49.2533L5.22972 23.2452C6.1762 17.5015 10.1041 12.6998 15.5351 10.6528Z' fill='%23E7F4F5'/%3E%3C/svg%3E%0A");
						right: 0;
						top: -42px;
						width: 68px;
						height: 85px;
					}
				}
				&-3{
					display: none;
				}
			}
		}
	}
}