.error-404 {
	min-height : calc(100vh - 200px);
	position : relative;
	display : flex;
	flex-direction : column;
	align-items : center;
	text-align : center;
	justify-content : space-between;
	padding : 100px 0;
	&:before {
		content: "";
		position : absolute;
		top : 0;
		left : 0;
		width : 100%;
		height : 100%;
		background: linear-gradient(177.82deg, #E8F5FA 9.81%, rgba(241, 250, 253, 0) 51.3%);
		transform: matrix(1, 0, 0, -1, 0, 0);

		@media( max-width: 768px) {
			background: linear-gradient(178.08deg, #E8F5FA 1.55%, rgba(241, 250, 253, 0) 63.13%);
			transform: matrix(1, 0, 0, -1, 0, 0);
		}

		@media( max-width: 480px) {
			background: linear-gradient(177.82deg, #E8F5FA 9.81%, rgba(241, 250, 253, 0) 51.3%);
			transform: matrix(1, 0, 0, -1, 0, 0);
		}
	}



	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 60px;
		line-height: 1.10;
		text-align: center;
		color: $dark_blue;
		z-index : 10;
		position : relative;

		@media( max-width: 768px) {
			font-size: 65px;
		}

		@media( max-width: 600px) {
			font-size: 45px;
		}
	}

	&__subtitle {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 35px;
		line-height: 1.43;
		text-align: center;
		color: $dark_blue;
		z-index : 10;
		position : relative;

		@media( max-width: 768px) {
			font-size: 30px;
		}

		@media( max-width: 480px) {
			font-size: 25px;
		}
	}

	&__content {
		font-family: $main_font;
		font-style: normal;
		font-weight: 700;
		font-size: 250px;
		line-height: 1.0;
		text-align: center;
		letter-spacing: -0.02em;
		color: #FFFFFF;
		z-index : 10;
		position : relative;
		margin-bottom: 80px;
		text-shadow: 0px 1px 11px rgba(150, 150, 150, 0.25);

		@media( max-width: 768px) {
			font-size: 200px;
			margin-bottom: 50px;
		}

		@media( max-width: 480px) {
			font-size: 150px;
		}
	}

	&__button {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 26px;
		color: #221B4A;
		text-align: center;
		color: $dark_blue;
		padding : 11px 26px;
		background-color: rgba(255, 255, 255, 0.9);
		border: 1px solid #021D59;
		border-radius: 16px;
		position : relative;
		z-index : 10;
		transition: .3s linear;
		&:hover {
			background-color : $dark_blue;
			color: #fff;
		}
	}

	&__image {
		width : 100%;
		height : 100%;
		position : absolute;
		top : 0;
		left : 0;
		z-index : 1;

		img {
			object-position: center;
			object-fit: cover;
			width : 100%;
			height : 100%;
		}
	}

	&__image-desktop {
		@media( max-width: 768px) {
			display : none;
		}
	}

	&__image-tablet {
		display : none;

		@media( max-width: 768px) {
			display : block;
		}

		@media( max-width: 480px) {
			display : none;
		}
	}


	&__image-mobile {
		display : none;

		@media( max-width: 480px) {
			display : block;
		}
	}

}