.presskit-three{
  margin-top: 130px;
  margin-bottom: 110px;
  @include break(tablet){
    margin-top: 110px;
    margin-bottom: 115px;
  }
  @include break(ss-small){
    margin-top: 70px;
    margin-bottom: 85px;
  }
  &__container{
    max-width: 1190px;
  }
  &__wrapper{
    padding-top: 110px;
    border-top: 1px solid rgba(2, 29, 89, 0.29);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 55px;
    column-gap: 55px;
    grid-row-gap: 45px;
    row-gap: 45px;
    @include break(tablet){
      padding-top: 100px;
    }
    @include break(medium){
      grid-column-gap: 18px;
      column-gap: 18px;
      grid-row-gap: 35px;
      row-gap: 35px;
    }
    @include break(s-small){
      grid-template-columns: 1fr;
      padding-top: 50px;
      grid-row-gap: 20px;
      row-gap: 20px;
    }
  }
  &__item{
    padding: 16px 16px 25px 16px;
    background-color: $white;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include break(tablet){
      padding: 16px 16px 20px 16px;
    }
    &-image-wrapper{
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 25px;
      height: 235px;
      @include break(small){
        height: 150px;
      }
      @include break(s-small){
        height: 170px;
        margin-bottom: 20px;
      }
    }
    &-image{
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
    }
    &-title{
      font-weight: 400;
      font-size: 23px;
      line-height: 30px;
      margin-bottom: 15px;
      @include break(medium){
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 15px;
      }
      @include break(s-small){
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
    &-text{
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      line-height: 23px;
      font-weight: 300;
      padding-left: 9px;
      padding-right: 9px;
      margin-bottom: 0;
      @include break(medium){
        font-size: 18px;
        line-height: 26px;
      }
      @include break(small){
        max-width: 245px;
      }
      @include break(s-small){
        font-size: 16px;
        line-height: 23px;
        max-width: none;
      }
    }
    &-link{
      max-width: 100%;
      width: 100%;
      display: block;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      text-decoration: underline;
      color: $azure;
      font-family: $second_font;
      transition: .3s linear;
      margin-top: 20px;
      @include break(medium){
        margin-top: 15px;
      }
      @include break(s-small){
        font-size: 16px;
        line-height: 23px;
        margin-top: 20px;
      }
      &:hover{
        text-decoration: none;
        color: $dark_blue;
      }
    }
  }
}