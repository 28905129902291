.page-template-page-content #primary-wrapper .container {


	p {
		font-family: $second_font;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 1.43;
		color: $black;
		margin-bottom : 30px;
		@include break(ss-small){
			font-size: 15;
		}
	}

	a {
		color: #15BDFF;

		&:hover {
			text-decoration: underline;
		}
	}

	h2 {
		font-family: $second_font;
		font-style: normal;
		font-weight: 400;
		font-size: 30px;
		line-height: 1.32;
		color: $black;
		margin-bottom : 30px;
		@include break(tablet){
			margin-bottom: 28px;
		}
		@include break(ss-small){
			margin-bottom: 25px;
		}
	}

	h3 {
		 font-family: $main_font;
		 font-style: normal;
		 font-weight: 400;
		 font-size: 23px;
		 line-height: 1.32;
		 color: $black;
		 margin-bottom : 30px;
		 @include break(table){
			font-size: 20px;
		 }
		 @include break(ss-small){
			font-size: 18px;
		}
	 }

	h4 {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 23px;
		line-height: 1.32;
		color: #606060;
		margin-bottom : 0;
		@include break(table){
			font-size: 20px;
		}
		@include break(ss-small){
			font-size: 18px;
		}
	}

	ul, ol {
		margin-bottom : 25px;
		padding-left : 20px;

		li {
			font-family: $second_font;
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 1.43;
			color: $black;
			list-style: disc;
		}
	}

	ol {
		li {
			list-style: decimal;
		}
	}

	table {
		margin-bottom : 49px;

		tr {
			border: 1px solid $dark_blue;

			&:first-child {
				td {
					text-align : center;
					font-family: $main_font;
					font-style: normal;
					font-weight: 400;
					font-size: 22px;
					line-height: 1.09;
					color: $black;
				}
			}

			td {
				width : 50%;
				padding : 17px 23px;
				font-family: $second_font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 1.43;
				color: $black;



				&:first-child {
					border-right: 1px solid $dark_blue;
				}
			}
		}
	}
}

.page-template-page-content #primary-wrapper {
	padding-bottom : 120px;
}