.three-text-columns{
  margin-top: 160px;
  margin-bottom: 195px;
  @include break(tablet){
    margin-top: 140px;
    margin-bottom: 160px;
  } 
  @include break(ss-small){
    margin-top: 70px;
    margin-bottom: 95px;
  }
  &__container{
    position: relative;
    isolation: isolate;
    .floating-element{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
      &-1{
        background-image: url("data:image/svg+xml,%3Csvg width='156' height='152' viewBox='0 0 156 152' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='155' height='151' rx='28.5' stroke='%23B748FF'/%3E%3C/svg%3E%0A");
        width: 156px;
        height: 152px;
        top: 127px;
        left: -39px;
        @include break(desktop){
          left: -89px;
        }
        @include break(tablet){
          background-image: url("data:image/svg+xml,%3Csvg width='177' height='177' viewBox='0 0 177 177' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='139.809' y='-2.61865' width='147.919' height='147.919' rx='13.6101' transform='rotate(74.3343 139.809 -2.61865)' stroke='%231C143A' stroke-opacity='0.4' stroke-width='0.618642' stroke-dasharray='4.44 4.44'/%3E%3C/svg%3E%0A");
          top: 329px;
          left: -74px;
          width: 177px;
          height: 177px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='76' height='76' viewBox='0 0 76 76' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='60.5591' y='-2.18115' width='64.9449' height='64.9449' rx='13.6101' transform='rotate(74.3343 60.5591 -2.18115)' stroke='%231C143A' stroke-opacity='0.4' stroke-width='0.618642' stroke-dasharray='4.44 4.44'/%3E%3C/svg%3E%0A");
          width: 76px;
          height: 76px;
          right: 3px;
          left: auto;
          top: 65px;
        }
      }
      &-2{
        background-image: url("data:image/svg+xml,%3Csvg width='246' height='246' viewBox='0 0 246 246' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='116.625' y='-3.93934' width='188.5' height='170.5' rx='13.25' transform='rotate(45 116.625 -3.93934)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
        width: 246px;
        height: 246px;
        right: -36px;
        top: 136px;
        @include break(desktop){
          right: -86px;
        }
        @include break(tablet){
          bottom: -286px;
          top: auto;
          right: -58px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='59' height='59' viewBox='0 0 59 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.2' x='29.3984' y='-4' width='47.3302' height='47.3302' rx='10' transform='rotate(44.884 29.3984 -4)' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
          width: 59px;
          height: 59px;
          top: 102px;
          bottom: auto;
          right: -29px;
        }
      }
      &-3{
        background-image: url("data:image/svg+xml,%3Csvg width='100' height='104' viewBox='0 0 100 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.4746 12.8945L48.3899 1.61894C55.0139 -0.877743 62.4543 0.355489 67.9061 4.84948L92.6654 25.1841C98.1387 29.67 100.804 36.7407 99.6613 43.7449L94.4839 75.3632C93.3333 82.3457 88.558 88.1833 81.9555 90.6718L52.0402 101.947C45.4163 104.444 37.976 103.211 32.5242 98.7169L7.76472 78.3823C2.29141 73.8964 -0.373682 66.8256 0.768839 59.8215L5.94631 28.2032C7.09696 21.2206 11.8722 15.3831 18.4746 12.8945Z' fill='%23F5E8FD'/%3E%3C/svg%3E%0A");
        width: 100px;
        height: 104px;
        right: -25px;
        top: 127px;
        @include break(desktop){
          right: -75px;
        }
        @include break(tablet){
          bottom: -135px;
          top: auto;
          right: -48px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='67' height='70' viewBox='0 0 67 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3763 8.97222L32.3734 1.43497C36.8012 -0.233957 41.7748 0.590404 45.4191 3.59444L61.9697 17.1872C65.6283 20.1859 67.4098 24.9124 66.6461 29.5943L63.1852 50.7298C62.4161 55.3973 59.224 59.2995 54.8106 60.963L34.8135 68.5002C30.3856 70.1691 25.4121 69.3448 21.7678 66.3407L5.21722 52.7479C1.55856 49.7493 -0.222943 45.0228 0.540783 40.3409L4.0017 19.2054C4.77086 14.5378 7.96288 10.6357 12.3763 8.97222Z' fill='%23F5E8FD'/%3E%3C/svg%3E%0A");
          width: 67px;
          height: 70px;
          right: auto;
          left: -41px;
          bottom: auto;
          top: 174px;
        }
      }
      &-4{
        display: none;
        @include break(tablet){
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg width='144' height='145' viewBox='0 0 144 145' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.2' x='72.0234' y='-3.34375' width='107.229' height='107.229' rx='10' transform='rotate(44.884 72.0234 -3.34375)' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
          width: 144px;
          height: 145px;
          left: 18px;
          top: 413px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='169' height='169' viewBox='0 0 169 169' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='85.2188' y='-3.93934' width='124.744' height='126.087' rx='13.25' transform='rotate(45 85.2188 -3.93934)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
          width: 169px;
          height: 169px;
          top: auto;
          bottom: -137px;
          left: -73px;
        }
      }
      &-5{
        display: none;
        @include break(ss-small){
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg width='67' height='67' viewBox='0 0 67 67' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' x='-2' y='54.2891' width='58' height='58' rx='10' transform='rotate(-76.044 -2 54.2891)' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
          width: 67px;
          height: 67px;
          bottom: -110px;
          right: -20px;
        }
      }
    }
  }
  &__title{
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    margin-bottom: 40px;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: $main_font_regular; 
    @include break(tablet){
      font-size: 65px;
      line-height: 66px;
      margin-bottom: 55px;
      padding-left: 30px;
      padding-right: 30px;
    }
    @include break(ss-small){
      font-size: 25px;
      line-height: 34px;
      margin-bottom: 30px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    grid-column-gap: 50px;
    column-gap: 50px;
    grid-row-gap: 80px;
    row-gap: 80px;
    @include break(tablet){
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      grid-column-gap: normal;
      column-gap: normal;
      grid-row-gap: normal;
      row-gap: normal;
    }
  }
  &__item{
    @include break(tablet){
      padding: 0 2px;
      width: 50%;
      margin-bottom: 30px;
    }
    @include break(ss-small){
      margin-bottom: 20px;
      width: 100%;
      padding: 0;
      max-width: 335px;
    }
    &-wrapper{
      @include break(tablet){
        background-color: $white;
        box-shadow: 0px 10px 20px rgba(28, 20, 58, 0.2);
        border-radius: 21px;
        padding: 35px 45px 30px 45px;
        text-align: center;
        height: 100%;
      }
      @include break(ss-small){
        padding: 40px 45px 35px 45px;
      }
      @include break(small-360){
        padding: 40px 30px 35px 30px;
      }
    }
    &-title{
      font-weight: 400;
      font-size: 23px;
      line-height: 30px;
      margin-bottom: 5px;
      @include break(medium){
        font-size: 22px;
        line-height: 27px;
      }
      @include break(ss-small){
        font-size: 18px;
        line-height: 24px;
      }
    }
    &-text{
      font-weight: 300;
      font-size: 16px;
      line-height:23px;
      color: #606060;
      margin-bottom: 0;
      max-width: 230px;
      @include break(tablet){
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
      @include break(medium){
        max-width: 230px;
        font-size: 12px;
        line-height: 15px;
      }
    }
    &-link-wrapper{
      margin-top: 15px;
    }
    &-link{
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $azure;
      padding-right: 14px;
      position: relative;
      transition: .3s linear;
      @include break(medium){
        font-size: 15px;
        line-height: 26px;
      }
      @include break(ss-small){
        font-size: 12px;
        line-height: 21px;
      }
      &:hover{
        color: $dark_blue;
        &::after{
          background-image: url("data:image/svg+xml,%3Csvg width='9' height='12' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 6L1 11' stroke='%23021D59' stroke-linejoin='round'/%3E%3C/svg%3E");
        }
      }
      &::after{
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='12' viewBox='0 0 9 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L8 6L1 11' stroke='%2315BDFF' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
        width: 9px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transition: .3s linear;
      }
    }
  }
}