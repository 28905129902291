.page-banner{
  margin-bottom: 75px;
  position: relative;
  z-index: 2000;
  @include break(tablet){
    margin-bottom: 40px;
  }
  @include break(ss-small){
    margin-bottom: 35px;
  }
  &__container{
    padding-top: 25px;
    @include break(tablet){
      padding-top: 30px;
      padding-bottom: 30px;
    }
    @include break(ss-small){
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  &__wrapper{
    height: 585px;
    position: relative;
    box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
    overflow: hidden;
    background-color: $dark_blue3;
    border-radius: 35px;
    display: flex;
    align-items: center;
    @include break(tablet){
      min-height: 540px;
      height: auto;
      box-shadow: 0px 10px 20px rgba(28, 20, 58, 0.2);
      border-radius: 25px;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @include break(x-small){
      min-height: 555px;
    }
  }
  &__img-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(90.01deg, rgba(2, 29, 89, 0.9) 44.68%, rgba(2, 29, 89, 0) 114.54%);
      z-index: 10;
      @include break(medium){
        background:  linear-gradient(13.08deg, rgba(2, 29, 89, 0.9) 9.68%, rgba(2, 29, 89, 0) 82.4%);
      }
    }
  }
  &__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__content{
    position: relative;
    z-index: 100;
    width: 100%;
    padding-left: 75px;
    padding-right: 75px;
    margin-top: -10px;
    @include break(tablet){
      margin-top: 0;
      padding-left: 45px;
      padding-right: 45px;
    }
    @include break(ss-small){
      margin-top: -5px;
    }
    @include break(x-small){
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  &__title{
    font-size: 75px;
    line-height: 85px;
    color: $white;
    margin-bottom: 15px;
    @include break(tablet){
      font-size: 65px;
      line-height: 65px;
    }
    @include break(medium){
      text-align: center;
    }
    @include break(ss-small){
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 17px;
    }
    @include break(x-small){
      max-width: 239px;
      margin-left: auto;
      margin-right: auto;
      br{
        display: none;
      }
    }
    
  }
  &__subtitle{
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 10px;
    color: $white;
    font-family: $main_font;
    @include break(tablet){
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 20px;
    }
    @include break(medium){
      text-align: center;
    }
    @include break(ss-small){
      font-size: 18px;
      line-height: 23px;
    }
    @include break(x-small){
      max-width: 245px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__text{
    font-size: 23px;
    line-height: 28px;
    color: $white;
    margin-bottom: 0;
    font-family: $main_font;
    @include break(tablet){
      font-size: 22px;
      line-height: 28px;
    }
    @include break(medium){
      text-align: center;
    }
    @include break(medium850){
      br{
        display: none;
      }
    }
    @include break(x-small){
      font-size: 18px;
      line-height: 23px;
    }
  }
  &__button-wrapper{
    margin-top: 30px;
    @include break(medium){
      text-align: center;
      margin-top: 25px;
    }
  }
  &__button{
    @include break(ss-small){
      padding: 7px 20px
    }
    &:hover{
      background-color: $azure;
    }
  }
  &__figure{
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 50;
    background-image: url("data:image/svg+xml,%3Csvg width='1320' height='160' viewBox='0 0 1320 160' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_2335_12452' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='-77' y='0' width='1440' height='355'%3E%3Crect x='-77' width='1440' height='355' fill='%23021D59'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2335_12452)'%3E%3Cpath d='M121.141 82.0938L5.13558 271.822C-2.6713 284.591 3.52787 300.165 18.9818 306.609C34.4358 313.052 53.2925 307.925 61.0993 295.157L177.104 105.429C184.911 92.6602 178.712 77.0858 163.258 70.6421C147.804 64.1984 128.947 69.3255 121.141 82.0938Z' fill='%23009AD6'/%3E%3Cpath d='M264.281 310.126L237.733 288.195C211.559 270.78 207.436 239.148 228.519 217.527L332.276 69.3285C353.359 47.7076 391.651 44.302 417.825 61.7174L444.373 83.6479C470.547 101.063 474.669 132.695 453.587 154.316L349.829 302.515C328.747 324.135 290.455 327.541 264.281 310.126Z' fill='%231FB0C5'/%3E%3Cpath d='M593.383 314.192L540.199 270.158C503.22 241.64 501.155 193.72 535.569 163.104L627.421 55.6862C661.866 25.0695 719.743 23.36 756.722 51.8526L809.907 95.8868C846.885 124.405 848.95 172.325 814.537 202.941L722.684 310.359C688.239 340.975 630.362 342.685 593.383 314.192Z' fill='%233EC7B4'/%3E%3Cpath d='M1048.75 42.8651L968.915 108.789C921.147 148.237 921.147 212.193 968.915 251.64L1048.75 317.565C1096.51 357.012 1173.96 357.012 1221.73 317.565L1301.56 251.641C1349.33 212.193 1349.33 148.237 1301.56 108.789L1221.73 42.865C1173.96 3.41773 1096.51 3.41778 1048.75 42.8651Z' fill='%235DDDA3'/%3E%3C/g%3E%3C/svg%3E%0A");
    width: 100%;
    height: 160px;
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    @include break(small){
      background-image: url("data:image/svg+xml,%3Csvg width='687' height='109' viewBox='0 0 687 109' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M55.7613 43.2078L-4.63992 167.015C-8.70478 175.347 -5.47701 185.51 2.56953 189.715C10.6161 193.92 20.4343 190.574 24.4992 182.242L84.9004 58.4349C88.9653 50.1029 85.7375 39.9398 77.6909 35.735C69.6444 31.5301 59.8262 34.8758 55.7613 43.2078Z' fill='%23009AD6'/%3E%3Cpath d='M130.132 191.767L116.226 177.506C102.516 166.181 100.356 145.612 111.399 131.552L165.748 35.1827C176.792 21.1232 196.85 18.9086 210.559 30.2334L224.466 44.4942C238.176 55.819 240.335 76.3881 229.292 90.4476L174.943 186.817C163.9 200.877 143.842 203.091 130.132 191.767Z' fill='%231FB0C5'/%3E%3Cpath d='M301.268 194.733L273.541 165.952C254.262 147.312 253.186 115.992 271.127 95.9809L319.014 25.7724C336.971 5.76125 367.145 4.64391 386.423 23.2668L414.151 52.0477C433.429 70.6876 434.506 102.008 416.565 122.019L368.678 192.228C350.72 212.239 320.547 213.356 301.268 194.733Z' fill='%233EC7B4'/%3E%3Cpath d='M540.745 19.6067L499.179 62.6256C474.307 88.367 474.307 130.102 499.179 155.843L540.745 198.862C565.617 224.603 605.942 224.603 630.814 198.862L672.38 155.843C697.252 130.102 697.252 88.3669 672.38 62.6256L630.814 19.6067C605.942 -6.13462 565.617 -6.13458 540.745 19.6067Z' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
      height: 109px;
    }
    @include break(small-375){
      background-image: url("data:image/svg+xml,%3Csvg width='326' height='94' viewBox='0 0 326 94' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.77763 43.3827L-31.5708 112.172C-34.017 116.802 -32.0746 122.449 -27.2323 124.785C-22.39 127.121 -16.4816 125.262 -14.0354 120.633L22.3131 51.8432C24.7592 47.2138 22.8168 41.567 17.9745 39.2307C13.1322 36.8944 7.2238 38.7533 4.77763 43.3827Z' fill='%23009AD6'/%3E%3Cpath d='M29.2373 126.972L21.0199 119.032C12.9186 112.726 11.6425 101.273 18.168 93.4439L50.2834 39.7836C56.809 31.955 68.6613 30.7218 76.7627 37.0277L84.9801 44.9684C93.0814 51.2742 94.3575 62.7275 87.832 70.5561L55.7166 124.216C49.191 132.045 37.3387 133.278 29.2373 126.972Z' fill='%231FB0C5'/%3E%3Cpath d='M116.854 131.63L100.153 115.64C88.5403 105.285 87.8919 87.8845 98.6986 76.7671L127.543 37.7625C138.359 26.6451 156.534 26.0244 168.146 36.3705L184.847 52.3598C196.46 62.7153 197.108 80.1155 186.301 91.2328L157.457 130.238C146.641 141.355 128.466 141.976 116.854 131.63Z' fill='%233EC7B4'/%3E%3Cpath d='M235.115 10.8965L210.101 34.7986C195.134 49.101 195.134 72.2897 210.101 86.592L235.115 110.494C250.082 124.796 274.349 124.797 289.317 110.494L314.331 86.5921C329.298 72.2897 329.298 49.101 314.331 34.7986L289.317 10.8965C274.349 -3.40585 250.082 -3.40582 235.115 10.8965Z' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
      height: 94px;
    }
  }
  &-v2{
    &__container{
      padding-bottom: 0;
    }
    &__wrapper{
      padding-top: 0;
      padding-bottom: 0;
      align-items: flex-end;
      @include break(tablet){
        min-height: 450px;
      }
      @include break(x-small){
        min-height: 385px;
        align-items: flex-start;
      }
    }
    &__img-wrapper{
      &::before{
        background: linear-gradient(90deg, #021D59 13.55%, rgba(151, 71, 255, 0) 99.14%);
        @include break(medium){
          background: linear-gradient(360deg, #021D59 15.7%, rgba(151, 71, 255, 0) 99.87%);
        }
        @include break(x-small){
          background: linear-gradient(360deg, #021D59 6.03%, rgba(151, 71, 255, 0) 99.87%);
        }
      }
    }
    &__content{
      padding-bottom: 150px;
      @include break(tablet){
        padding-top: 50px;
        padding-bottom: 105px;
      }
      @include break(x-small){
        padding-top: 190px;
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
      }
      @include break(small-360){
        padding-bottom: 80px;
      }
    }
    &__title{
      text-align: center;
      font-size: 103px;
      line-height: 110px;
      /*margin-bottom: 15px;*/
      margin-bottom: 0;
      max-width: none;
      @include break(tablet){
        font-size: 65px;
        line-height: 70px;
        /*margin-bottom: 20px;*/
      }
      @include break(ss-small){
        font-size: 45px;
        line-height: 50px;
        /*margin-bottom: 5px;*/
      }
    }
    &__text{
      text-align: center;
      font-size: 35px;
      line-height: 50px;
      max-width: none;
      @include break(tablet){
        font-size: 19px;
        line-height: 27px;
      }
      @include break(ss-small){
        font-size: 20px;
        line-height: 29px;
      }
    }
  }
  &-v3{
    &__container{
      padding-top: 30px;
      padding-bottom: 0;
      margin-bottom: 60px;
      @include break(tablet){
        margin-bottom: 45px;
      }
      @include break(x-small){
        padding-top: 25px;
        margin-bottom: 40px;
      }
    }
    &__wrapper{
      height: 280px;
      background-color: #021D59;
      @include break(tablet){
        min-height: auto;
      }
      @include break(small){
        height: 325px;
        border-radius: 20px;
      }
      @include break(x-small){
        height: 430px;
        box-shadow: 0px 10px 10px rgba(28, 20, 58, 0.1);
      }
    }
    &__img-wrapper{
      left: 0;
      top: 0;
      transform: translate(0,0);
      &::before{
        display: none;
      }
    }
    &__content{
      
    }
    &__title{
      text-align: center;
      margin-bottom: 0;
      max-width: none;
      @include break(small){
        font-size: 50px;
        line-height: 55px;
      }
      @include break(x-small){
        font-size: 45px;
        line-height: 50px;
      }
    }
  }
}