.js-select-wrapper{
  .select2-container{
    width: 100% !important;
    &.select2-container--open{
      .select2-selection__arrow{
        transform: rotate(-180deg);
      }
      .select2-dropdown--below{
        margin-top: 9px;
      }
      .select2-dropdown--above{
        margin-top: -9px;
      }
    }
    .select2-selection--single{
      border: 1px solid rgba(28, 20, 58, 0.17);
      border-radius: 14.5737px !important;
      height: 40px;
      @include break(x-small){
        height: 30px;
        border-radius: 10px !important;
      }
      .select2-selection__rendered{
        line-height: 40px;
        color: rgba(28, 20, 58, 0.6);
        font-family: $second_font;
        font-weight: 400;
        font-size: 15px;
        padding-left: 10px;
        padding-right: 40px;
        @include break(x-small){
          line-height: 30px;
          font-size: 12px;
        }
      }
      .select2-selection__placeholder{
        opacity: 0;
        font-size: 15px;
        color: rgba(28, 20, 58, 0.6);
        @include break(x-small){
          opacity: 1;
          font-size: 12px;
        }
      }
      .select2-selection__arrow{
        height: 38px;
        width: 38px;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.7' d='M5.49137 6.99763C5.30811 6.99809 5.13051 6.91676 4.98939 6.76775L0.283345 1.77034C0.12317 1.60069 0.022441 1.3569 0.00331894 1.09261C-0.0158031 0.828322 0.0482477 0.565176 0.181381 0.361065C0.314514 0.156954 0.505824 0.0285965 0.713224 0.00422933C0.920625 -0.0201378 1.12713 0.0614814 1.2873 0.231132L5.49137 4.70882L9.69544 0.39105C9.77567 0.308027 9.86799 0.246028 9.96708 0.208615C10.0662 0.171203 10.1701 0.159115 10.2729 0.173047C10.3756 0.186979 10.4752 0.226656 10.5659 0.289797C10.6566 0.352938 10.7366 0.438299 10.8014 0.540973C10.8732 0.643741 10.9276 0.764305 10.9612 0.895111C10.9947 1.02592 11.0067 1.16415 10.9964 1.30114C10.9861 1.43813 10.9537 1.57093 10.9013 1.69123C10.8488 1.81152 10.7774 1.91672 10.6916 2.00022L5.98551 6.82772C5.84034 6.95317 5.66636 7.013 5.49137 6.99763Z' fill='black'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        transition: .3s linear;
        @include break(x-small){
          height: 28px;
        }
        b{
          display: none;
        }
      }
    }
    .select2-dropdown{
      padding: 12px 17px 12px 6px;
      background: #FFFFFF;
      box-shadow: 0px 12px 30px rgba(28, 20, 58, 0.2);
      border-radius: 14px;
      border: none;
      @include break(x-small){
        border-radius: 10px;
      }
    }
    .select2-results{
      .select2-results__options{
        padding-right: 2px;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: #C6EFF4;
          border-radius: 13px;
        }
        &::-webkit-scrollbar-thumb {
          background: #15BDFF;
          border-radius: 13px;
        }
      }
      .select2-results__option{
        font-family: $second_font;
        font-weight: 400;
        font-size: 15px;
        line-height: 16px;
        padding: 13px 16px 9px;
        border-radius: 20px;
        color: rgba(28, 20, 58, 0.6);
        background-color: $white;
        transition: .3s linear;
        &:not(:last-child){
          margin-bottom: 6px;
        }
        &.select2-results__option--highlighted{
          background-color: rgba($color: #15BDFF, $alpha: 0.2);
          color: $dark_blue;
        }
      }
    }
    .select2-search--dropdown{
      padding: 0;
      margin-bottom: 10px;
      .select2-search__field{
        width : 100%;
				background: #FFFFFF;
				border: 1px solid rgba(28, 20, 58, 0.17);
				border-radius: 14.5737px;
				height : 40px;
        font-size: 15px;
				padding : 0 10px;
        color: rgba(28, 20, 58, 0.6);
        @media( max-width: 480px) {
					height : 30px;
					border-radius: 10px;
          font-size: 12px;
				}
      }
    }
  }
}
