html {
	position   : relative;
	min-height : 100%;
 
	&.popup-opened {
		overflow : hidden;

		@include break(small) {
			height : 100vh;
		}
	}
}


.my_overlay {
	position   : fixed;
	z-index    : 1;
	left       : 0;
	top        : 0;
	width      : 100%;
	height     : 100%;
	background: rgba(0, 13, 41, 0.52);
	cursor     : pointer;
	display    : block;
}

.popup {
	top        : 0;
	left       : 0;
	width      : 100%;
	height     : 100%;
	position   : fixed;
	z-index    : 9000;
	display    : none;
	overflow-x : hidden;
	overflow-y : scroll;

	.popup-wrapper-inner {
		width      : 642px;
		padding    : 25px 50px 50px;
		margin     : 70px auto 50px;
		position   : relative;
		z-index    : 2;
		background : #EEEEF2;
		//box-shadow: 0px 0px 60px #2C3549;
	}

	input[type="text"],
	input[type="tel"],
	input[type="email"],
	input[type="number"],
	input[type="password"],
	input[type="file"],
	input[type="date"],
	select,
	textarea {

	}

	.wpcf7-not-valid-tip {
		text-align : left;
	}

	@include break(small) {
		.popup-wrapper-inner {
			margin-top : 40px;
			width      : 90% !important;
			padding    : 45px 20px;

			@include break(x-small) {
				width   : calc(100% - 20px) !important;
				padding : 70px 20px;
			}

			input[type="text"],
			input[type="tel"],
			input[type="email"],
			input[type="number"],
			input[type="password"],
			input[type="file"],
			input[type="date"],
			select,
			textarea {
				width      : 100%;
				box-shadow : none;
			}
		}
	}

	.in {
		padding     : 0;
		text-align  : center;

		@include break(x-small) {
			border      : none;
			padding-top : 0px;
		}
	}

	/* end of .in*/
	@include break(x-small) {

	}

	button.popup-close {
		background      : url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBvcGFjaXR5PSIwLjIyNjg2NSIgZD0iTTMyIDEuNzY5MjVMMzAuMjMwNyAwTDE2IDE0LjIzMDhMMS43NjkyNSAwTDAgMS43NjkyNUwxNC4yMzA4IDE2TDAgMzAuMjMwN0wxLjc2OTI1IDMyTDE2IDE3Ljc2OTNMMzAuMjMwNyAzMkwzMiAzMC4yMzA3TDE3Ljc2OTMgMTZMMzIgMS43NjkyNVoiIGZpbGw9IndoaXRlIi8+PC9zdmc+) center center no-repeat;
		text-indent     : -9999px;
		background-size : 32px auto;
		border          : none;
		width           : 40px;
		height          : 40px;
		position        : absolute;
		top             : -40px;
		right           : -40px;
		padding         : 0;
		z-index         : 10;
		opacity         : 0.9;
		cursor          : pointer;

		@include break(small) {
			width           : 20px;
			height          : 20px;
			background-size : 15px auto;
			right           : 10px;
			top             : 10px;
			opacity         : 1;
			filter          : invert(1);
		}

		&:hover {
			opacity : 1;
		}
	}
}

div.wpcf7 .ajax-loader {
	position : absolute;
	right : 0;
}