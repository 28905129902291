.resources-post-card{
  background: #FFFFFF;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 15px 35px 15px 15px;
  @include break(tablet){
    padding: 14px 28px 14px 14px;
  }
  @include break(x-small){
    padding: 15px 15px 17px 15px;
  }
  &-featured{
    padding: 19px 55px 19px 19px;
    @include break(tablet){
      padding: 14px 24px 14px 14px;
    }
    @include break(x-small){
      padding: 16px 16px 17px 16px;
    }
  }
  &-related{
    @include break(x-small){
      padding: 8px 18px 8px 8px;
    }
  }
  &__wrapper{
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-column-gap: 25px;
    column-gap: 25px;
    @include break(x-small){
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      row-gap: 20px;
    }
    &-related{
      @include break(x-small){
        grid-template-columns: 106px 1fr;
        grid-column-gap: 15px;
        column-gap: 15px;
      }
    }
    &-featured{
      grid-template-columns: 220px 1fr;
      grid-column-gap: 50px;
      column-gap: 50px;
      @include break(tablet){
        grid-template-columns: 190px 1fr;
        grid-column-gap: 30px;
        column-gap: 30px;
      }
      @include break(s-small){
        grid-template-columns: 150px 1fr;
        grid-column-gap: 15px;
        column-gap: 15px;
      }
      @include break(x-small){
        grid-template-columns: 1fr;
        grid-row-gap: 25px;
        row-gap: 25px;
      }
    }
  }
  &__image-wrapper{
    height: 230px;
    position: relative;
    @include break(tablet){
      height: 205px;
    }
    @include break(x-small){
      height: auto;
    }
    @include break(small-375){
      height: 165px;
    }
    &-featured{
      height: 280px;
      @include break(tablet){
        height: 255px;
      }
      @include break(x-small){
        height: auto;
      }
      @include break(small-375){
        height: 300px;
      }
    }
    &-related{
      height: 230px;
      @include break(x-small){
        height: 165px;
      }
    }
    a{
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: $dark_blue;
      @include break(x-small){
        border-radius: 8px;
      }
    }
  }
  &__image{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    &-desktop{
      @include break(x-small){
        display: none;
      }
    }
    &-mobile{
      display: none;
      @include break(x-small){
        display: block;
      }
    }
    &-related{
      display: block;
    }
  }
  &__badge{
    position: absolute;
    left: -15px;
    top: 11px;
    font-size: 20px;
    line-height: 28px;
    padding: 7px 13px 7px 27px; 
    color: $black;
    -webkit-border-top-right-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-topright: 6px;
    -moz-border-radius-bottomright: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #5DDDA3;
    @include break(tablet){
      left: -14px;
      top: 10px;
      font-size: 20px;
      line-height: 29px;
      padding: 6px 13px 6px 27px; 
    }
    @include break(x-small){
      min-width: 90px;
      padding: 6px 16px;
      text-align: center; 
      top: 16px;
      left: -15px;
    }
    &-featured{
      background-color: #5DDDA3;
      left: -19px;
      top: 19px;
      padding: 9px 25px 9px 37px; 
      @include break(tablet){
        padding: 8px 16px 8px 32px; 
        font-size: 17px;
        line-height: 25px;
      }
      @include break(x-small){
        padding: 9px 14px;
        font-size: 20px;
        line-height: 29px;
        left: -16px;
        top: 16px;
      }
    }
    &-related{
      min-width: 105px;
      text-align: center;
      @include break(x-small){
        min-width: 60px;
        padding: 4px 10px 4px 20px;
        font-size: 11px;
        line-height: 16px;
        top: 9px;
        left: -8px;
      }
    }
  }
  &__content-wrapper{
    display: flex;
    flex-direction: column;
    padding-top: 29px;
    padding-bottom: 8px;
    justify-content: space-between;
    @include break(tablet){
      padding-top: 26px;
      padding-bottom: 4px;
    }
    @include break(x-small){
      padding: 0;
    }
    &.download-resource{
      padding-bottom: 5px;
      @include break(tablet){
        padding-bottom: 8px;
      }
      .resources-post-card__title{
        @include break(tablet){
          font-size: 25px;
          line-height: 34px;
        }
        @include break(x-small){
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 15px;
        }
      }
    }
    &-related{
      
      @include break(x-small){
        padding-top: 17px;
        padding-bottom: 7px;
      }
      &.download-resource{
        .resources-post-card__title{
          font-size: 22px;
          line-height: 27px;
          @include break(ss-small){
            font-size: 18px;
            line-height: 22px;
          }
          @include break(x-small){
            font-size: 15px;
            line-height: 19px;
          }
        }
      }
    }
    &-featured{ 
      padding-top: 30px;
      @include break(tablet){
        padding-top: 19px;
      }
      @include break(x-small){
        padding: 0;
      }
      &.download-resource{
        padding: 0;
        justify-content: center;
      }
    }
  }
  &__content{
   
    &-featured{
      
    }
  }
  &__title{
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 10px;
    @include break(ss-small){
      font-size: 18px;
      line-height: 22px;
    }
    @include break(x-small){
      text-align: center;
      margin-bottom: 10px;
    }
    &-featured{
      font-size: 45px;
      line-height: 50px;
      margin-bottom: 20px;
      @include break(tablet){
        font-size: 30px;
        line-height: 34px;
      }
      @include break(ss-small){
        font-size: 18px;
        line-height: 22px;
      }
      @include break(x-small){
        text-align: center;
        margin-bottom: 10px;
      }
    }
    &-related{
      text-align: left;
      @include break(x-small){
        font-size: 15px;
        line-height: 19px;
      }
    }
  }
  &__link{
    color: $dark_blue;
    transition: .3s linear;
    &:hover{
      color: $azure;
    }
  }
  &__explore-wrapper{
    text-align: right;
    margin-top: 30px;
    @include break(x-small){
      text-align: center;
      margin-top: 0;
    }
    &-featured{
      text-align: left;
      @include break(x-small){
        text-align: center;
      }
    }
    &-related{
      text-align: right;
    }
  }
  &__explore{
    @extend .primary-btn;
    @include break(tablet){
      font-size: 15px;
      line-height: 19px;
      padding: 9px 24px;
      border-radius: 14px;
    }
    @include break(x-small){
      padding: 7px 16px;
      border-radius: 9px;
    }
  }
  &__posted{
    font-size: 15px;
    line-height: 21px;
    color: $black;
    max-width: 315px;
    font-weight: 300;
    margin-bottom: 0;
    @include break(tablet){
      font-size: 16px;
      line-height: 22px;
    }
    @include break(x-small){
      margin-left: auto;
      margin-right: auto;
      max-width: 250px;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
    }
    &-featured{
      max-width: 460px;
      font-size: 20px;
      line-height: 28px;
      @include break(tablet){
        max-width: 365px;
        font-size: 16px;
        line-height: 22px;
      }
      @include break(x-small){
        max-width: 250px;
        font-size: 14px;
        line-height: 19px;
      }
    }
    &-related{
      text-align: left;
      @include break(tablet){
        font-size: 15px;
        line-height: 21px;
      }
      @include break(x-small){
        max-width: none;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  &__tags{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 25px;
    @include break(x-small){
      margin-top: 15px;
      justify-content: center;
    }
    &-featured{
      margin-top: 20px;
      @include break(x-small){
        margin-top: 30px;
      }
    }
    &-related{
      justify-content: flex-start;
    }
  }
  &__tag{
    text-align: center;
    color: $dark_blue;
    border: 1px solid $dark_blue;
    font-size: 16px;
    line-height: 21px;
    padding: 6px 27px;
    border-radius: 16px;
    margin-bottom: 5px;
    @include break(x-small){
      font-size: 15px;
      line-height: 18px;
      padding: 5px 15px;
      border-radius: 20px;
    }
    &:not(:last-child){
      margin-right: 10px;
    }
    &-featured{

    }
  }
}