.mobile-menu{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: $white;
  transition: .3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  &.show{
    visibility: visible;
    opacity: 1;
  }
  &::before{
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(178.32deg, #E8F5FA 15.44%, rgba(241, 250, 253, 0) 61.56%);
    opacity: 0.9;
    transform: matrix(1, 0, 0, -1, 0, 0);
    z-index: -1;
  }
  &__header,
  &__footer{
    background-color: $white;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    width: 100%;
    height: 80px;
    padding-left: 40px;
    padding-right: 40px;
    @include break(ss-small){
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &__header{
    top: 0;
    &-side{
      display: flex;
      align-items: center;
    }
    &-icon-link{
      display: block;
      width: 35px;
      height: 35px;
      margin-right: 30px;
      @include break(ss-small){
        margin-right: 12px;
      }
      svg{
        display: block;
      }
    }
    &-btn-link{
      border: 1px solid $dark_blue;
      font-size: 15px;
      line-height: 19px;
      padding: 7px 15px;
      text-align: center;
      border-radius: 9.28px;
    }
    &-btn{
      width: 25px;
      height: 25px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      svg{
        width: 100%;
        height: auto;
        pointer-events: none;
      }
      @include break(ss-small){
        width: 20px;
        height: 20px;
      }
    }
  }
  &__footer{
    bottom: 0;
    z-index: 10;
    @include break(ss-small){
      background-color: $white;
      overflow: hidden;
      &::before{
        content: '';
        display: block;
        position: absolute;
        z-index: 10;
        left:50%;
        top: 0px;
        transform: translateX(-50%);
        height: 1px;
        width: calc(100% - 40px);
        background-color: rgba($color: $dark_blue, $alpha: .2);
      }
      &::after{
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        bottom: 0;
        transform: matrix(1, 0, 0, -1, 0, 0);
        background: linear-gradient(178.32deg, #E8F5FA 15.44%, rgba(241,250,253,0) 61.56%);
        z-index: -1;
      }
    }

    &-logo{
      width: 162px;
      @include break(ss-small){
        width: 102px;
      }
      &-img{
        width: 100%;
        display: block;
        height: auto;
      }
    }
    &-socials{
      display: flex;
      align-items: center;
      &-link{
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:not(:last-child){
          margin-right: 25px;
          @include break(ss-small){
            margin-right: 15px;
          }
        }
        @include break(ss-small){
          width: 25px;
          height: 25px;
        }
      }
      &-icon{
        width: 28px;
        height: 28px;
        @include break(ss-small){
          width: 23px;
          height: 23px;
        }
      }
    }
  }
  &__nav{
    margin-top: 120px;
    height: calc(100vh - 240px);
    overflow: auto;
    @media screen and (max-height:800px){
      height: calc(100vh - 180px);
      margin-top: 90px;
    }
  }
  &__menu{
    .menu-item{
      &.current-menu-ancestor,
      &.current-menu-item{
        & > a, & > .nav__link{
          font-weight: 700;
        }
      }
    }
    & > .menu-item{
      &:not(:last-child){
        & > .nav__link,
        & > a{
          position: relative;
          &::before{
            content: '';
            display: block;
            position: absolute;
            left:50%;
            bottom: 0;
            transform: translateX(-50%);
            height: 1px;
            width: calc(100% - 80px);
            background-color: rgba($color: $azure, $alpha: .5);
            transition: .3s ease-in-out;
            @include break(ss-small){
              width: calc(100% - 40px);
            }
          }

        }
      }
      &.menu-item-has-children{
        &.active{
          & > a{
            font-weight: 700;
            &::before{
              background-color:transparent;
            }
          }
          > .sub-menu {
            max-height: none !important;
          }
          .arrow-down{
            transform: rotate(-180deg);
          }
        }
        & > .like-link,
        & > a{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .arrow-down{
            width: 24px;
            height: 37px;
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='11' viewBox='0 0 17 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L8.43902 8.93902L15.878 1.5' stroke='%23021D59' stroke-width='2'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            transition: .3s ease-in-out;
            @include break(ss-small){
              height: 26px;
            }
          }
        }

      }
      & > .nav__link,
      & > a{
        font-size: 26px;
        line-height: 37px;
        padding-top: 29px;
        padding-bottom: 29px;
        padding-right: 105px;
        padding-left: 105px;
        display: block;
        width: 100%;
        @include break(ss-small){
          padding-right: 55px;
          padding-left: 55px;
          padding-top: 24px;
          padding-bottom: 24px;
          font-size: 20px;
          line-height: 26px;
          letter-spacing: 0.06em;
        }
      }
    }
    .sub-menu{
      position: relative;
      max-height: 0;
      overflow: hidden;
      transition: .3s ease-in-out;
      &::before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(2, 29, 89, 0.05);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: 100vw;
        z-index: -1;
      }
      .menu-item{
        padding-left: 105px;
        padding-right: 105px;
        padding-top: 15px;
        padding-bottom: 15px;
        &:first-child{
          padding-top: 30px;
          @include break(ss-small){
            padding-top: 20px;
          }
        }
        &:last-child{
          padding-bottom: 30px;
          @include break(ss-small){
            padding-bottom: 20px;
          }
        }
        @include break(ss-small){
          padding-right: 55px;
          padding-left: 55px;
          padding-top: 12px;
          padding-bottom: 12px;
        }
        a{
          font-size: 18px;
          line-height: 26px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          @include break(ss-small){
            font-size: 16px;
            line-height: 21px;
            letter-spacing: 0.06em;
          }
          
          .arrow-down {
            width: 24px;
            height: 26px;
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='11' viewBox='0 0 17 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L8.43902 8.93902L15.878 1.5' stroke='%23021D59' stroke-width='2'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            transition: .3s ease-in-out;
            @include break(ss-small){
              height: 21px;
            }
          }
        }
        
        &.menu-item-has-children{
          &.active{
            & > a{
              font-weight: 700;
            }
            & > .sub-menu {
              max-height: 1000px;
            }
            & > a .arrow-down{
              transform: rotate(-180deg);
            }
          }
        }
      }
      
      /* Second level submenu styles */
      .sub-menu {
        // padding-left: 20px;
        padding-right: 0;
        margin-top: 15px;
        background: rgba(2, 29, 89, 0.03);
        
        &::before {
          background: rgba(2, 29, 89, 0.02);
        }
        
        .menu-item {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          
          &:first-child {
            padding-top: 20px;
            @include break(ss-small){
              padding-top: 15px;
            }
          }
          
          &:last-child {
            padding-bottom: 20px;
            @include break(ss-small){
              padding-bottom: 15px;
            }
          }
          
          a {
            font-size: 16px;
            line-height: 22px;
            padding-left: 15px;
            @include break(ss-small){
              font-size: 14px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}