.our-breakthroughs{
  margin-top: 150px;
  margin-bottom: 200px;
  @include break(desktop){
    margin-top: 130px;
    margin-bottom: 100px;
  }
  @include break(ss-small){
    margin-top: 120px;
    margin-bottom: 65px;
  } 
  &__container{

  }
  &__wrapper{
    max-width: 1290px;
    margin-left: auto;
    margin-right: auto;
  }
  &__title{
    font-size: 60px;
    line-height: 68px;
    font-weight: 400;
    text-align: center;
    max-width: 660px;
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    @include break(desktop){
      font-size: 50px;
      line-height: 55px;
      margin-bottom: 30px;
      max-width: 550px;
    }
    @include break(ss-small){
      font-size: 30px;
      line-height: 34px;
      max-width: 330px;
    }
  }
  &__timeline{
    //text slider
    &-content{
      overflow: visible !important;
      z-index: 7000 !important;
      .swiper-slide{
        opacity: 0 !important;
        position: relative;
        transition: 0.5s linear !important;
        &-active{
          opacity: 1 !important;
        }
      }
    }
    &-content-slide{
      max-width: 405px;
      margin-left: auto;
      margin-right: auto;
      height: 190px;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px;
      @include break(small){
        max-width: 335px;
        height: 162px;
        padding:18px 17px;
      }
    }
    &-content-text{
      font-size: 20px;
      line-height: 29px;
      font-weight: 400;
      text-align: center;
      color: $white;
      max-width: 345px;
      margin-bottom: 0;
      max-height: 116px;
      overflow: auto;
      background-color: inherit;
      @include break(small){
        font-size: 13px;
        line-height: 21px;
      }
      &::-webkit-scrollbar {
        width: 11px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: inherit;
      }
      &::-webkit-scrollbar-track { 
          background-color: #fff; 
          border-radius: 8px; 
      } 
    }
    //dates slider
    &-dates{
      &-gradient{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        background: linear-gradient(270deg, #FFFFFF 2.23%, rgba(255, 255, 255, 0) 24.07%, rgba(255, 255, 255, 0) 51.73%, rgba(255, 255, 255, 0) 80.32%, #FFFFFF 96.29%);
        z-index: 100;
        @include break(x-small){
          width: 106%;
          left: -3%;
        }
      }
      &.swiper{ 
        padding-bottom: 65px;
        padding-top: 70px;
        @include break(desktop){
          padding-top: 65px;
        }
        @include break(x-small){
          padding-top: 35px;
          padding-bottom: 55px;
        }
      }
      .swiper-button-next,
      .swiper-button-prev{
        margin-top: 0;
        transform: translateY(-50%);
        height: 100%;
        font-size: 0;
        width: 50%;
        z-index: 1000;
        &::after{
          display: none;
        }
      }
      .swiper-button-next{
        right: 0;
      }
      .swiper-button-prev{
        left: 0;
      }
      .swiper-slide{
        display: flex;
        align-items: center;
        justify-content: center;
        position: inherit !important;
        height: auto !important;
      }
      &-dashed-top{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 75px;
        border: 1px dashed transparent;
        transition: 0.3s linear;
        @include break(desktop){
          height: 65px;
        }
        @include break(x-small){
          height: 35px;
        }
        .animate{
          animation-name: fedeDashed;
          animation-duration: 0.5s;
          animation-timing-function: linear;
        }
      }
      &-slide{

      }
      &-dot{
        
      }
      &-dot-img{
        display: block;
        margin: 0 auto;
        cursor: pointer; 
        height: auto;
        @include break(desktop){
          width: auto;
          height: auto;
        }
      }
      &-dashed-line{
        width: 0;
        height: 25px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 45px;
        @include break(desktop){
          bottom: 40px;
        }
        @include break(x-small){
          height: 20px;
          bottom: 25px;
        }
      }
      &-year{
        font-size: 22px;
        line-height: 24px;
        color: $dark_blue;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        @include break(small){
          font-size: 23px;
          line-height: 25px;
        }
        @include break(x-small){
          font-size: 18px;
          line-height: 19px;
        }
      }
    }
    &-date{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      opacity: 0;
      visibility: hidden;
      transition: 0.5s linear;
      &.active{
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
@keyframes fedeDashed {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}