.single-resources-tags{
  margin-top: 75px;
  margin-bottom: 75px;
  @include break(tablet){
    margin-top: 50px;
    margin-bottom: 45px;
  }
  @include break(ss-small){
    margin-top: 35px;
    margin-bottom: 30px;
  }
  &__wrapper{
    max-width: 1025px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @include break(x-small){
      max-width: 300px;
    }
  }
  &__tags{
    display: flex;
    flex-wrap: wrap;
    @include break(medium){
      justify-content: center;
    }
  }
  &__tag{
    border: 1px solid $dark_blue;
    border-radius: 16px;
    padding: 5px 30px;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 18px;
    color: $dark_blue;
    @include break(x-small){
      padding: 5px 15px;
    }
    &:not(:last-child){
      margin-right: 10px;
      @include break(x-small){
        margin-right: 6px;
      }
    }
  }
}