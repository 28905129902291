.resources-related-media{
  margin-bottom: 190px;
  background-color: $white;
  @include break(tablet){
    margin-bottom: 0;
    padding-bottom: 60px;
    padding-top: 60px;
  }
  @include break(ss-small){
    padding-bottom: 50px;
    padding-top: 45px;
  }
  &__title{
    font-size: 35px;
    line-height: 50px;
    font-weight: 400;
    margin-bottom: 70px;
    text-align: center;
    @include break(tablet){
      margin-bottom: 45px;
    }
    @include break(ss-small){
      font-size: 30px;
      line-height: 34px;
    }
  }
  &__wrapper{
    max-width: 1225px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    column-gap: 40px;
    @include break(desktop){
      grid-template-columns: 1fr;
      grid-row-gap: 27px;
      row-gap: 27px;
      max-width: 595px;
    }
  }
}