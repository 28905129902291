.custom-content{
  margin-top: 165px;
  margin-bottom: 220px;
  @include break(desktop){
    margin-top: 180px;
    margin-bottom: 120px;
  }
  @include break(ss-small){
    margin-top: 136px;
    margin-bottom: 90px;
  }
  &__container{
    position: relative;
    .floating-element{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      &-1{
        background-image: url("data:image/svg+xml,%3Csvg width='246' height='246' viewBox='0 0 246 246' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='116.621' y='-3.93543' width='188.5' height='170.5' rx='13.25' transform='rotate(45 116.621 -3.93543)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
        width: 246px;
        height: 246px;
        left: -115px;
        top: 91px;
        z-index: 10;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='195' height='195' viewBox='0 0 195 195' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.478388' width='143.424' height='148.201' rx='14.884' transform='matrix(-0.707107 0.707107 0.707107 0.707107 95.5563 -5.85988)' stroke='%231C143A' stroke-opacity='0.5' stroke-width='0.676543' stroke-dasharray='4.85 4.85'/%3E%3C/svg%3E%0A");
          width: 195px;
          height: 195px;
          left: -94px;
          top: 70px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='67' height='67' viewBox='0 0 67 67' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.437446' width='53.4414' height='55.2425' rx='13.6101' transform='matrix(-0.707107 0.707107 0.707107 0.707107 32.9172 -4.87188)' stroke='%231C143A' stroke-opacity='0.4' stroke-width='0.618642' stroke-dasharray='4.44 4.44'/%3E%3C/svg%3E%0A");
          width: 67px;
          height: 67px;
          left: 13px;
          bottom: -70px;
          top: auto;
        }
      }
      &-2{
        background-image: url("data:image/svg+xml,%3Csvg width='70' height='71' viewBox='0 0 70 71' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.00390625' width='70' height='70' rx='13' fill='%23EEFDFF'/%3E%3C/svg%3E%0A");
        width: 70px;
        height: 71px; 
        bottom: -75px;
        left: 106px;
        z-index: 9;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='77' height='77' viewBox='0 0 77 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='77' height='77' rx='16' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
          width: 77px;
          height: 77px;
          bottom: -50px;
          left: 82px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='39' height='39' viewBox='0 0 39 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.605469' y='0.621094' width='38' height='38' rx='7.05714' fill='%23EEFDFF'/%3E%3C/svg%3E%0A");
          width: 39px;
          height: 39px;
          left: 68px;
          bottom: -56px;
        }
      }
      &-3{
        background-image: url("data:image/svg+xml,%3Csvg width='46' height='42' viewBox='0 0 46 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='46' height='41.2955' rx='9.40909' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
        width: 46px;
        height: 42px;
        top: -96px;
        left: 229px;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='104' height='104' viewBox='0 0 104 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='104' height='104' rx='16' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
          width: 104px;
          height: 104px;
          left: 108px;
          top: -160px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='53' height='55' viewBox='0 0 53 55' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='53' height='55' rx='14' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
          width: 53px;
          height: 55px;
          left: 69px;
          top: -104px;
        }
      }
      &-4{
        background-image: url("data:image/svg+xml,%3Csvg width='104' height='104' viewBox='0 0 104 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='52.2754' y='-3.93934' width='79.5' height='79.5' rx='13.25' transform='rotate(45 52.2754 -3.93934)' stroke='%231C143A' stroke-opacity='0.4' stroke-width='1.5' stroke-dasharray='3 3 3 3'/%3E%3C/svg%3E%0A");
        width: 104px;
        height: 104px;
        bottom: -130px; 
        right: 192px;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='153' height='153' viewBox='0 0 153 153' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.478388' width='113.69' height='117.481' rx='14.884' transform='matrix(-0.707107 0.707107 0.707107 0.707107 74.5309 -5.85988)' stroke='%231C143A' stroke-opacity='0.5' stroke-width='0.676543' stroke-dasharray='4.85 4.85'/%3E%3C/svg%3E%0A");
          width: 153px;
          height: 153px;
          right: -64px;
          bottom: -125px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='128' height='128' viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='64.4863' y='-2.69817' width='95.0126' height='95.0126' rx='9.3907' transform='rotate(45 64.4863 -2.69817)' stroke='%231C143A' stroke-opacity='0.5' stroke-width='0.42685' stroke-dasharray='3.06 3.06'/%3E%3C/svg%3E%0A");
          width: 128px;
          height: 128px;
          right: -44px;
          bottom: -105spx;
        }
      }
      &-5{
        background-image: url("data:image/svg+xml,%3Csvg width='197' height='198' viewBox='0 0 197 198' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.503906' width='196' height='196' rx='28.5' stroke='%23B748FF'/%3E%3C/svg%3E%0A");
        width: 197px;
        height: 198px;
        top: -19px;
        right: -78px;
        z-index: 10;
        @include break(large1370){
          right: -140px;
        }
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='156' height='152' viewBox='0 0 156 152' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='155' height='151' rx='28.5' stroke='%23B748FF'/%3E%3C/svg%3E%0A");
          width: 156px;
          height: 152px;
          right: -29px;
          top: -166px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-0.310185' y='0.310185' width='95.3796' height='95.3796' rx='17.6806' transform='matrix(-1 0 0 1 95.3796 0)' stroke='%23B748FF' stroke-width='0.62037'/%3E%3C/svg%3E%0A");
          width: 96px;
          height: 96px;
          right: -53px;
          top: -122px;
        }
      }
      &-6{
        background-image: url("data:image/svg+xml,%3Csvg width='74' height='74' viewBox='0 0 74 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-4' y='36.1875' width='58' height='58' rx='10' transform='rotate(-43.8564 -4 36.1875)' fill='%23F6E6FF'/%3E%3C/svg%3E%0A");
        width: 74px;
        height: 74px;
        top: -74px;
        right: 51px;
        z-index: 9;
        @include break(large1370){
          right: -10px;
        }
        @include break(desktop){
          display: none;
        }
        @include break(ss-small){
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.203346' y='0.203346' width='26.6005' height='27.1461' rx='6.30373' stroke='%2315BDFF' stroke-width='0.406692'/%3E%3C/svg%3E%0A");
          width: 28px;
          height: 28px;
          left: 17px;
          right: auto;
          top: -119px;
        }
      }
    }
  }
  &__wrapper{
    max-width: 1078px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    font-size: 35px;
    line-height: 60px;
    text-align: center;
    position: relative;
    z-index: 100;
    font-family: $main_font;
    @include break(desktop){
      font-size: 30px;
      line-height: 50px;
    }
    @include break(medium){
      max-width: 520px;
      font-size: 23px;
      line-height: 35px;
    }
    @include break(x-small){
      max-width: 335px;
      font-size: 15px;
      line-height: 26px;
    }
    p{
      font-weight: 400;
      font-size: 35px;
      line-height: 60px;
      text-align: center;
      font-family: $main_font;
      margin-bottom: 0;
      &:not(:last-child){
        margin-bottom: 30px;
        @include break(x-small){
          margin-bottom: 26px;
        }
      }
      @include break(desktop){
        font-size: 30px;
        line-height: 50px;
      }
      @include break(tablet){
        font-size: 23px;
        line-height: 35px;
      }
      @include break(x-small){
        font-size: 15px;
        line-height: 26px;
      }
    }
  }
}