.hero-banner {
	width       : 100%;
	/*height      : calc(100vh - 80px);*/
	position    : relative;
	height: 900px;
	@include break(large1750){
		height: 715px;
  }
	@include break(medium){
    height: 865px;
  }
  @include break(x-small){
   	height: 555px;
		/*height      : calc(100vh - 50px);*/
  }
	&__container{
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		padding-top: 70px;
    padding-bottom: 70px;
	}
	&__content{
    margin-top: -30px;
    text-align: center;
    max-width: 1025px;
  }
}

.header__elements {
	width      : 100%;
	height     : 100%;
	position   : absolute;
	top        : 0;
	left       : 0;
	z-index    : -1;
	overflow   : hidden;
	background : #021D59;
}

.header__elements .hero-vector {
	display    : block;
	position   : absolute;
	left       : -50%;
	transition : 0.25s ease-out;
	width      : 1px;
	height     : 1px;
	bottom     : 0;
	visibility : hidden;
	@include break(medium){
		visibility: visible;
	}
	svg{
		width: 100%;
		height: auto;
		display: block;
	}
}

.header__elements .hero-vector:nth-child(1) {
	//background      : transparent url("../images/vector_1.svg") no-repeat center;
	background-size : contain;
	z-index         : 7;
	@include break(medium){
		width: 6vw;
    height: 13vw;
    left: 20%;
    bottom: -6%;
    transform: rotate(-40deg);
		animation: animate-vector-1-mobile 8s infinite linear;
	}
	@include break(s-small){
		width: 7.5vw;
    height: 14.5vw;
		left: 10%;
    bottom: -4%;
	}
}
@keyframes animate-vector-1-mobile {
	0%,
	100% {
		transform: translate(0,0) rotate(-40deg)
	}
	50% {
		transform: rotate(-58deg) translate(11px,-25px);
	}
}

.header__elements .hero-vector:nth-child(2) {
	//background      : transparent url("../images/vector_2.svg") no-repeat center;
	background-size : contain;
	z-index         : 6;
	left            : 3%;
	@include break(medium){
		width: 16vw;
    height: 18vw;
    left: 22%;
    bottom: 3%;
		transform: rotate(-40deg);
		animation: animate-vector-2-mobile 8s infinite linear;
	}
	@include break(s-small){
		width: 18vw;
    height: 20vw;
		left: 9%;
    bottom: 4%;
	}
}
@keyframes animate-vector-2-mobile {
	0%,
	100% {
		transform: translate(0,0) rotate(-40deg)
	}
	25%{
		transform: rotate(-40deg) translate(0px,-20px);
	}
	/*50% {
		transform: rotate(-40deg) translate(11px,-25px);
	}*/
	75% {
		transform: rotate(-40deg) translate(0px,20px);
	}
}
.header__elements .hero-vector:nth-child(3) {
	//background      : transparent url("../images/vector_3.svg") no-repeat center;
	background-size : contain;
	z-index         : 5;
	left            : 8%;
	bottom          : 8%;
	@include break(medium){
		width: 24vw;
    height: 25vw;
    left: 26%;
    bottom: 8%;
    transform: rotate(-50deg);
		animation: animate-vector-3-mobile 8s infinite linear;
	}
	@include break(s-small){
		width: 28vw;
    height: 29vw;
		left: 18%;
    bottom: 10%;
	}
}
@keyframes animate-vector-3-mobile {
	0%,
	100% {
		transform: translate(0,0) rotate(-50deg)
	}
	33%{
		transform: rotate(-50deg) translate(0px,-35px);
	}
	/*50% {
		transform: rotate(-50deg) translate(11px,-25px);
	}*/
	75% {
		transform: rotate(-50deg) translate(0px,25px);
	}
}
.header__elements .hero-vector:nth-child(4) {
	//background      : transparent url("../images/vector_4.svg") no-repeat center;
	background-size : contain;
	z-index         : 4;
	left            : 18%;
	bottom          : 18%;
	@include break(medium){
		width: 33vw;
    height: 32vw;
    left: 28%;
    bottom: 18%;
    transform: rotate(15deg);
		animation: animate-vector-4-mobile 8s infinite linear;
	}
	@include break(s-small){
		width: 44vw;
    height: 43vw;
    left: 19%;
    bottom: 20%;
	}

}
@keyframes animate-vector-4-mobile {
	0%,
	100% {
		transform: translate(0,0) rotate(15deg)
	}
	25%{
		transform: rotate(15deg) translate(-30px,0);
	}
	/*50% {
		transform: rotate(15deg) translate(11px,-25px);
	}*/
	75% {
		transform: rotate(15deg) translate(30px,0px);
	}
}
.header__elements .hero-vector:nth-child(5) {
	//background      : transparent url("../images/vector_5.svg") no-repeat center;
	background-size : contain;
	z-index         : 3;
	left            : 28%;
	bottom          : 28%;
	@include break(medium){
		width: 40vw;
    height: 38vw;
    left: 31%;
    bottom: 29%;
    transform: rotate(26deg);
		animation: animate-vector-5-mobile 8s infinite linear;
		
	}
	@include break(s-small){
		width: 55vw;
    height: 54vw;
    left: 29%;
    bottom: 33%;
	}
}
@keyframes animate-vector-5-mobile {
	0%,
	100% {
		transform: translate(0,0) rotate(26deg)
	}
	25%{
		transform: rotate(26deg) translate(-60px,0);
	}
	/*50% {
		transform: rotate(26deg) translate(11px,-25px);
	}*/
	75% {
		transform: rotate(26deg) translate(50px,0px);
	}
}
.header__elements .hero-vector:nth-child(6) {
	//background      : transparent url("../images/vector_6.svg") no-repeat center;
	background-size : contain;
	z-index         : 2;
	left            : 44%;
	bottom          : 52%;
	@include break(medium){
		width: 48vw;
    height: 48vw;
    left: 34%;
    bottom: 38%;
		animation: animate-vector-6-mobile 8s infinite linear;
		
	}
	@include break(s-small){
		width: 68vw;
    height: 68vw;
    left: 37%;
    bottom: 47%;
	}
}
@keyframes animate-vector-6-mobile {
	0%,
	100% {
		transform: translate(0,0) rotate(0deg)
	}
	25%{
		transform: translate(-45px,10px) rotate(13deg);
	}
	/*50% {
		transform: translate(11px,-25px);
	}*/
	75% {
		transform: translate(45px,15px) rotate(26deg);
	}
}
.header__elements .hero-vector:nth-child(7) {
	//background      : transparent url("../images/vector_7.svg") no-repeat center;
	background-size : contain;
	z-index         : 1;
	left            : 58%;
	bottom          : 68%;
	@include break(medium){
		width: 60vw;
    height: 60vw;
    left: 39%;
    bottom: 54%;
		animation: animate-vector-7-mobile 8s infinite linear;
	} 
	@include break(s-small){
		width: 80vw;
    height: 80vw;
    left: 40%;
    bottom: 65%;
	}
}
@keyframes animate-vector-7-mobile {
	0%,
	100% {
		transform: translate(0,0);
	}25% {
		transform: translate(50px,20px);
	}
	75% {
		transform: translate(45px,0px);
	}
}


.header__title-box {
	max-width   : 1000px;
	margin      : 0px auto 30px;
	text-align  : center;
	user-select : none;
	@include break(tablet){
		max-width: 820px;
	}
	@include break(x-small){
		max-width: 335px;
	}
	span {
		color          : rgba(255, 240, 240, 0.2);
		text-shadow    : 0 0 rgb(245, 170, 20);
		line-height    : 1;
		font-size      : 130px;
		font-weight    : 400;
		font-family: $main_font_regular;
		mix-blend-mode : color-dodge;
		position: relative;
		z-index: 200;
		@media( max-width: 768px) {
			font-size : 120px;
		}

		@media( max-width: 600px) {
			font-size : 90px;
		}

		@media( max-width: 480px) {
			font-size : 60px;
		}
	}
}

.header__title-box .line-up {
	display   : inline-block;
	animation : anim-lineUp ease-out;
	/*margin: 0 12px;*/
	padding : 0;
}

.header__title-box .line-up:nth-child(1) {
	animation-duration : 0.25s;
}

.header__title-box .line-up:nth-child(2) {
	animation-duration : 0.75s;
}

.header__title-box .line-up:nth-child(3) {
	animation-duration : 1.25s;
}

.header__title-box .line-up:nth-child(4) {
	animation-duration : 1.5s;
}

.header__title-box .line-up:nth-child(5) {
	animation-duration : 1.85s;
}

.header__subtitle-box {
	text-align    : center;
	margin-bottom: 0;
	@include break(small){
		max-width: 570px;
		margin-left: auto;
		margin-right: auto;
	}
	.desktop{
		@include break(ss-small){
			display: none !important;
		}
	}
	.mobile{
		display: none;
		@include break(ss-small){
			display: block;
		}
	}
	.line-up {
		font-family : $main_font;
		font-style  : normal;
		font-weight : 400;
		font-size   : 23px;
		line-height : 1.51;
		color       : #FFFFFF;
		letter-spacing: 0.01em;
		@include break(large1370){
			left: auto;
		}
		@include break(s-small) {
			font-size : 18px;
			padding   : 0 20px;

			br {
				display : none;
			}
		}
	}
}

.header__subtitle-box .line-up {
	display   : block;
	animation : anim-lineUp ease-out;
}

.header__subtitle-box .line-up:nth-child(1) {
	animation-duration : 2.5s;
}

.header__subtitle-box .line-up:nth-child(2) {
	animation-duration : 3.5s;
}

.header__action {
	margin-top : 50px;
	text-align : center;
}

.header__action .btn-get-demo {
	width            : max-content;
	padding          : 10px 22px;
	border: 2px solid $white;
	color            : black;
	border-radius    : 12px;
	background-color : white;
	animation        : 4.75s anim-lineUp ease-out;
	cursor           : pointer;
	display: inline-block;
	font-size: 20px;
	line-height: 26px;
	transition: .3s linear;
	@include break(medium){
		padding-left: 27px;
		padding-right: 27px;
	}
	@include break(ss-small){
		font-size: 15px;
		line-height: 19px;
		border-radius: 9px;
		padding: 5px 11px;
	}
	&:hover{
		background-color: $dark_blue;
		color: $white;
	}
}

@keyframes anim-lineUp {
	0% {
		opacity   : 0;
		transform : translateY(70%);
	}
	20% {
		opacity : 0;
	}
	50% {
		opacity   : 1;
		transform : translateY(0%);
	}
	100% {
		opacity   : 1;
		transform : translateY(0%);
	}
}