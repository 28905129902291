.contact-us-section{
  margin-top: 195px;
  isolation: isolate;
  @include break(tablet){
    margin-top: 160px;
  }
  @include break(ss-small){
    margin-top: 95px;
  }
  
  &__container{
    position: relative;
    &::before{
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%)  matrix(1, 0, 0, -1, 0, 0);
      bottom: 75px;
      width: 100vw;
      height: 717px;
      background: linear-gradient(177.82deg, #E8F5FA 9.81%, rgba(241, 250, 253, 0) 51.3%);
    }
    .floating-element{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
      &-1{
        background-image: url("data:image/svg+xml,%3Csvg width='246' height='246' viewBox='0 0 246 246' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='116.625' y='-3.93934' width='188.5' height='170.5' rx='13.25' transform='rotate(45 116.625 -3.93934)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
        width: 246px;
        height: 246px;
        top: 326px;
        left: -115px;
        @include break(tablet){
          background-image: url("data:image/svg+xml,%3Csvg width='136' height='246' viewBox='0 0 136 246' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='6.625' y='-3.93934' width='188.5' height='170.5' rx='13.25' transform='rotate(45 6.625 -3.93934)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
          width: 136px;
          left: 0;
          top: 358px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='38' height='74' viewBox='0 0 38 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' x='-40' y='36.1875' width='58' height='58' rx='10' transform='rotate(-43.8564 -40 36.1875)' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
          width: 38px;
          height: 74px;
          top: 600px;
        }
      }
      &-2{
        background-image: url("data:image/svg+xml,%3Csvg width='74' height='74' viewBox='0 0 74 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' x='-4' y='36.1875' width='58' height='58' rx='10' transform='rotate(-43.8564 -4 36.1875)' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
        width: 74px;
        height: 74px;
        top: 299px;
        left: 7px;
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='53' height='100' viewBox='0 0 53 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='47.5312' y='-3.93934' width='80.5631' height='72.7887' rx='13.25' transform='rotate(45 47.5312 -3.93934)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
          width: 53px;
          height: 100px;
          right: 0;
          left: auto;
          top: 355;
        }
      }
      &-3{
        background-image: url("data:image/svg+xml,%3Csvg width='108' height='99' viewBox='0 0 108 99' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.3228 0H70.6772C78.0626 0 84.8726 3.93027 88.5413 10.305L105.23 39.183C108.923 45.5577 108.923 53.4183 105.23 59.817L88.5413 88.695C84.8486 95.0697 78.0386 99 70.6772 99H37.3228C29.9374 99 23.1275 95.0697 19.4587 88.695L2.76954 59.817C-0.923179 53.4423 -0.923179 45.5817 2.76954 39.183L19.4587 10.305C23.1515 3.93027 29.9614 0 37.3228 0Z' fill='%23F5E8FD'/%3E%3C/svg%3E%0A");
        width: 108px;
        height: 99px;
        bottom: 171px;
        left: -66px;
        @include break(tablet){
          background-image: url("data:image/svg+xml,%3Csvg width='100' height='104' viewBox='0 0 100 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.4746 12.8945L48.3899 1.61894C55.0139 -0.877743 62.4543 0.355489 67.9061 4.84948L92.6654 25.1841C98.1387 29.67 100.804 36.7407 99.6613 43.7449L94.4839 75.3632C93.3333 82.3457 88.558 88.1833 81.9555 90.6718L52.0402 101.947C45.4163 104.444 37.976 103.211 32.5242 98.7169L7.76472 78.3823C2.29141 73.8964 -0.373682 66.8256 0.768839 59.8215L5.94631 28.2032C7.09696 21.2206 11.8722 15.3831 18.4746 12.8945Z' fill='%23F5E8FD'/%3E%3C/svg%3E%0A");
          width: 100px;
          height: 104px;
          bottom: 178px;
          right: -57px;
          left: auto;
        }
        @include break(ss){
          background-image: url("data:image/svg+xml,%3Csvg width='53' height='63' viewBox='0 0 53 63' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.8451 0H45.1549C49.8733 0 54.2241 2.50108 56.5681 6.55773L67.2306 24.9346C69.5898 28.9913 69.5898 33.9935 67.2306 38.0654L56.5681 56.4423C54.2088 60.4989 49.858 63 45.1549 63H23.8451C19.1267 63 14.7759 60.4989 12.432 56.4423L1.76943 38.0654C-0.589809 34.0087 -0.589809 29.0065 1.76943 24.9346L12.432 6.55773C14.7912 2.50108 19.142 0 23.8451 0Z' fill='%23F5E8FD'/%3E%3C/svg%3E%0A");
          width: 53px;
          height: 63px;
          right: 0;
          bottom: 113px;
        }
      }
    }
  }
  &__wrapper{ 
    max-width: 1205px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 420px 1fr;
    grid-column-gap: 55px;
    column-gap: 55px;
    @include break(desktop){
      grid-column-gap: 30px;
      column-gap: 30px;
    }
    @include break(tablet){
      display: block;
    }
  }
  &__content{
    margin-top: 150px;
    @include break(tablet){
      margin-top: 0;
      margin-bottom: 90px;
    }
    @include break(ss-small){
      margin-bottom: 60px;
    }
  }
  &__title{
    font-family: $main_font_regular;
    font-weight: 400;
    font-size: 60px;
    line-height: 66px;
    margin-bottom: 65px;
    color: $dark_blue;
    @include break(tablet){
      text-align: center;
      font-size: 65px;
    }
    @include break(ss-small){
      font-size: 25px;
      line-height: 34px;
      margin-bottom: 20px;
    }
  }
  &__text{
    @include break(tablet){
      max-width: 515px;
      margin-left: auto;
      margin-right: auto;
    }
    @include break(ss-small){
      padding-left: 20px;
      padding-right: 20px;
    }
    *{
      font-weight: 300;
      font-size: 22px;
      line-height: 31px;
      color: $black;
      margin-bottom: 0;
      @include break(tablet){
        text-align: center;
        font-size: 23px;
      }
      @include break(ss-small){
        font-size: 18px;
        line-height: 26px;
      }
      &:not(:last-child){
        margin-bottom: 30px;
        @include break(ss-small){
          margin-bottom: 26px;
        }
      }
    }
  }
  &__form{
    width: 100%;
    max-width: 100%;
  }
  &__form-wrap{
    margin-top: 0;
  }
  &__bottom{
    height: 525px;
    width: 100%;
    position: relative;
    isolation: isolate;
    margin-top: -75px;
    &.default{
      margin-top: 0;
    }
    @include break(tablet){
      margin-top: -95px;
      height: 610px;
    }
    @include break(ss-small){
      margin-top: -55px;
      height: 355px;
    }
  }
  &__bg{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
    z-index: -1;
		top: 0;
    background-color: #201A51;
		background-repeat : no-repeat;
		background-position : right bottom;
		background-image: url(../images/next-step-bg-hd.jpg);
		@media(min-width : 1921px){
			background-size: cover;
		}
		@media(max-width : 1440px){
			background-image: url(../images/next-step-bg-desktop.jpg);
		}
		@media(max-width : 768px) {
			
			/*background-size     : 100% 325px;*/
			background-image: url(../images/next-step-bg-tablet.jpg);
		}
		
		@media(max-width : 500px) {
			/*background-size : 100% 150px;*/ 
		}
	
		@media(max-width : 480px) {
			/*background-size : 100% 148px;*/
			background-size   : contain;
			background-image: url(../images/next-step-bg-mobile.jpg);
		}
		@media(max-width : 375px) {
			background-size: auto;
		} 
	}
  &__bottom-wrapper{
    max-width: 1030px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 135px;
    position: relative;
    z-index: 10;
    @include break(tablet){
      max-width: 450px;
      padding-top: 190px;
    }
    @include break(ss-small){
      padding-top: 115px;
      max-width: 280px;
    }
  }
  &__email{
    font-family: $second_font;
    font-weight: 300;
    font-size: 27px;
    line-height: 36px;
    position: relative;
    padding-left: 80px;
    color: $white;
    display: inline-block;
    transition: .3s linear;
    @include break(tablet){
      font-size: 30px;
      line-height: 43px;
      padding-left: 90px;
    }
    @include break(ss-small){
      padding-left: 65px;
      font-size: 20px;
      line-height: 29px;
    }
    &:hover{
      color: $azure;
      &::before{
        background-image: url("data:image/svg+xml,%3Csvg width='54' height='36' viewBox='0 0 54 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4120_22748)'%3E%3Cpath d='M53.7734 28.5048V7.49176C53.7734 3.47714 50.5395 0.222656 46.5503 0.222656L7.4425 0.222656C3.45324 0.222656 0.219315 3.47714 0.219315 7.49176V28.5048C0.219315 32.5195 3.45324 35.7739 7.4425 35.7739H46.5503C50.5395 35.7739 53.7734 32.5195 53.7734 28.5048Z' stroke='%2315BDFF' stroke-miterlimit='10'/%3E%3Cpath d='M8.625 10.2305L23.0312 20.0797C25.4211 21.713 28.5467 21.7623 30.9857 20.2098L45.3785 10.2305' stroke='%2315BDFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M24.1861 20.7266L8.625 30.6431' stroke='%2315BDFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M30.0156 20.7266L45.5811 30.6431' stroke='%2315BDFF' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4120_22748'%3E%3Crect width='54' height='36' fill='%2315BDFF'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        
      }
    }
    &::before{
      content: '';
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='54' height='36' viewBox='0 0 54 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4120_22748)'%3E%3Cpath d='M53.7734 28.5048V7.49176C53.7734 3.47714 50.5395 0.222656 46.5503 0.222656L7.4425 0.222656C3.45324 0.222656 0.219315 3.47714 0.219315 7.49176V28.5048C0.219315 32.5195 3.45324 35.7739 7.4425 35.7739H46.5503C50.5395 35.7739 53.7734 32.5195 53.7734 28.5048Z' stroke='white' stroke-miterlimit='10'/%3E%3Cpath d='M8.625 10.2305L23.0312 20.0797C25.4211 21.713 28.5467 21.7623 30.9857 20.2098L45.3785 10.2305' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M24.1861 20.7266L8.625 30.6431' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M30.0156 20.7266L45.5811 30.6431' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4120_22748'%3E%3Crect width='54' height='36' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      transition: .3s linear;
      width: 54px;
      height: 36px;
      position: absolute;
      top: -3px;
      left: 0;
      @include break(tablet){
        background-image: url("data:image/svg+xml,%3Csvg width='65' height='43' viewBox='0 0 65 43' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4120_23028)'%3E%3Cpath d='M64.7344 34.051V8.95207C64.7344 4.15683 60.8417 0.269531 56.0398 0.269531L8.96564 0.269531C4.16376 0.269531 0.271072 4.15683 0.271072 8.95207V34.051C0.271072 38.8463 4.16376 42.7336 8.96564 42.7336H56.0398C60.8417 42.7336 64.7344 38.8463 64.7344 34.051Z' stroke='white' stroke-miterlimit='10'/%3E%3Cpath d='M10.3828 12.2188L27.7237 23.9831C30.6004 25.9339 34.3627 25.9929 37.2984 24.1385L54.6232 12.2188' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M29.1137 24.7578L10.3828 36.6025' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M36.1328 24.7578L54.8691 36.6025' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4120_23028'%3E%3Crect width='65' height='43' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") !important;
        width: 65px;
        height: 43px;
      }
      @include break(ss-small){
        background-image: url("data:image/svg+xml,%3Csvg width='38' height='25' viewBox='0 0 38 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4120_22889)'%3E%3Cpath d='M37.8438 19.7967V5.20424C37.8438 2.41631 35.568 0.15625 32.7608 0.15625L5.2405 0.15625C2.43324 0.15625 0.157516 2.41631 0.157516 5.20424V19.7967C0.157516 22.5846 2.43324 24.8446 5.2405 24.8446L32.7608 24.8446C35.568 24.8446 37.8438 22.5846 37.8438 19.7967Z' stroke='white' stroke-miterlimit='10'/%3E%3Cpath d='M6.07031 7.10547L16.208 13.9452C17.8898 15.0794 20.0893 15.1137 21.8056 14.0355L31.9339 7.10547' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M17.0207 14.3945L6.07031 21.281' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21.125 14.3945L32.0785 21.281' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4120_22889'%3E%3Crect width='38' height='25' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A") !important;
        width: 38px;
        height: 25px;
      }
    }
  }
  &__phone-wrapper{
    margin-top: 35px;
    position: relative;
    padding-left: 66px;
    @include break(tablet){
      margin-top: 45px;
      padding-left: 70px;
    }
    @include break(ss-small){
      margin-top: 25px;
      padding-left: 60px;
    }
    &::before{
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='39' height='55' viewBox='0 0 39 55' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4120_22751)'%3E%3Cpath d='M26.1577 35.7395L16.2134 14.4003L20.9945 12.1723C21.7679 11.8118 22.0981 10.8939 21.74 10.1255L17.9977 2.09505C17.6373 1.32164 16.7193 0.99144 15.9509 1.34952L4.01829 6.9103C1.96423 7.86752 1.07389 10.3119 2.03111 12.366L19.8439 50.5896C20.8011 52.6437 23.2454 53.534 25.2995 52.5768L37.2372 47.0137C38.0106 46.6532 38.3408 45.7353 37.9827 44.9669L32.7589 33.7574C32.3985 32.984 31.4805 32.6538 30.7122 33.0119L26.1651 35.7422L26.1577 35.7395Z' stroke='white' stroke-width='0.81' stroke-miterlimit='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4120_22751'%3E%3Crect width='19.2595' height='50.8305' fill='white' transform='translate(0 8.53125) rotate(-24.9862)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      width: 39px;
      height: 55px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @include break(tablet){
        background-image: url("data:image/svg+xml,%3Csvg width='43' height='59' viewBox='0 0 43 59' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4120_23031)'%3E%3Cpath d='M28.4352 38.4214L17.6241 15.2223L22.8219 12.8001C23.6628 12.4082 24.0217 11.4102 23.6324 10.5749L19.564 1.84451C19.1721 1.00369 18.1742 0.644709 17.3388 1.034L4.36609 7.07946C2.133 8.12011 1.16505 10.7775 2.2057 13.0106L21.571 54.5658C22.6117 56.7989 25.2691 57.7669 27.5022 56.7262L40.4803 50.6782C41.3211 50.2864 41.6801 49.2884 41.2908 48.453L35.6118 36.2666C35.2199 35.4257 34.2219 35.0668 33.3866 35.4561L28.4432 38.4243L28.4352 38.4214Z' stroke='white' stroke-width='0.81' stroke-miterlimit='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4120_23031'%3E%3Crect width='20.9382' height='55.2609' fill='white' transform='translate(0 8.84375) rotate(-24.9862)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 43px;
        height: 59px;
      }
      @include break(ss-small){
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='41' viewBox='0 0 30 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4120_22892)'%3E%3Cpath d='M19.744 26.6754L12.2389 10.5705L15.8472 8.88898C16.431 8.61697 16.6802 7.92416 16.4099 7.34425L13.5856 1.28361C13.3136 0.699907 12.6207 0.450703 12.0408 0.720949L3.03515 4.91772C1.48493 5.64014 0.81298 7.48493 1.5354 9.03515L14.9788 37.8829C15.7013 39.4331 17.5461 40.1051 19.0963 39.3826L28.1058 35.1841C28.6895 34.9121 28.9387 34.2193 28.6684 33.6394L24.726 25.1795C24.454 24.5958 23.7612 24.3466 23.1813 24.6168L19.7496 26.6774L19.744 26.6754Z' stroke='white' stroke-width='0.81' stroke-miterlimit='10'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4120_22892'%3E%3Crect width='14.5353' height='38.3623' fill='white' transform='translate(0 6.14062) rotate(-24.9862)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 30px;
        height: 41px;
      }
    }
  }
  &__phone{
    font-family: $second_font;
    font-weight: 300;
    font-size: 27px;
    line-height: 36px;
    color: $white;
    display: inline-block;
    transition: .3s linear;
    @include break(tablet){
      font-size: 30px;
      line-height: 43px;
    }
    @include break(ss-small){
      font-size: 20px;
      line-height: 29px;
    }
    &:hover{
      color: $azure;
    }
  }
  &__phone-text{
    font-size: 20px;
    line-height: 29px;
    color: $white;
    margin-bottom: 0; 
    @include break(tablet){
      font-size: 18px;
      line-height: 26px;
    }
    @include break(ss-small){
      font-size: 15px;
      line-height: 21px;
    }
  }
  &__bottom-text-wrapper{
    background-color: $dark_blue;
    padding-top: 43px;
    padding-bottom: 43px;
  }
  &__bottom-text{
    font-family: $main_font_regular;
    margin-bottom: 0;
    text-align: center;
    color: $white;
    font-size: 16px;
    line-height: 21px;
    @include break(tablet){
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      font-size: 13px;
      line-height: 17px;
    }
    @include break(ss-small){

    }
  }
}