.site-footer {
	background-color :  #021D59;
	padding : 35px 0 58px;

	@media( max-width: 768px) {
		padding : 39px 0 33px;
	}

	&__top {
		display : flex;
		justify-content : space-between;
		flex-wrap: wrap;
		align-items : start;
		position : relative;

	}

	&__menu-container {
		@media( max-width: 700px) {
			width : 100%;
			margin-bottom : 47px;
		}
	}

	&__menu-nav-list {
		display: inline-grid;
		grid-template-columns: repeat(4, auto);
		grid-template-rows: repeat(3, auto);
		grid-column-gap: 76px;
		grid-row-gap: 15px;
		grid-auto-flow: column;

		@media( max-width: 1030px) {
			grid-template-rows: repeat(4, auto);
			grid-template-columns: repeat(3, auto);
		}

		@media( max-width: 992px) {
			grid-column-gap: 32px;
		}

		@media( max-width: 700px) {
			grid-column-gap: 20px;
			width : 100%;
			justify-content : space-around;
		}



		li {
			display : inline-block;

			a, span {
				font-family: $main_font;
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 1.31;
				color: $white;

				@media( max-width: 768px) {
					font-size: 13px;
				}

				&:hover {
					opacity: 0.8;
					transition: 0.3s;
				}
			}

			span {
				color: #55bcf9;
			}
		}
	}

	&__logo {
		img {
			@media( max-width: 768px) {
				max-width : 129px;
				height : auto;
			}
		}

		@media( max-width: 700px) {
			width : 100%;
			margin-bottom : 43px;
			text-align : center;
		}
	}

	&__social {
		display : flex;


		a {
			margin-right : 25px;

			@media( max-width: 1030px) {
				margin-right : 15px;
			}

			&:last-of-type {
				margin-right : 0;
			}

			img {
				@media( max-width: 768px) {
					max-width : 17px;
					height : auto;
				}
			}
		}
	}


	&__bottom {
		width : 100%;
		margin-top : 35px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media( max-width: 768px) {
			display : none;
		}

		&.tablet {
			display : none;
			margin-bottom : 9px;

			@media( max-width: 768px) {
				display : block;
				margin-top : 5px;
			}
		}


	}

	&__content {
		margin : 0;
		font-family: $second_font;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.12;
		color: #FFFFFF;

		@media( max-width: 768px) {
			font-size: 10px;
		}

		@media( max-width: 480px) {
			font-size: 12px;
		}

		@media( max-width: 390px) {
			font-size: 10px;
		}
	}

	&__social-wrap {
		@media( max-width: 700px) {
			display : flex;
			justify-content : space-between;
			align-items : center;
			width : 100%;
		}
	}
	.powered-by{
		font-family: $second_font;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.12;   
		color: #FFFFFF;
		&-mobile{
			display: none;
			margin-top: 30px;
			text-align: center;
			font-size: 13px;
			text-align: center;
			@media( max-width:768px) {
				display: block;
			}
		}
		a{
			transition: .3s linear;
			color: $azure;
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
		}
	}
}

/*.wpcf7 {
	position : relative;

	.wpcf7-not-valid-tip {
		position : absolute;
		bottom: -20px;
		font-size : 10px;
	}

	.wpcf7-response-output {
		position : absolute;
		bottom: -40px;
		border: none !important;
		font-size : 10px !important;
		width : 100%;
		text-align : center;
		padding : 0 20px;
		left : 0;
	}
}*/