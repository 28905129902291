.single-events{
  &__container{

  }
  &__wrapper{
    margin-top: 115px;
    margin-bottom: 160px;
    padding-left: 40px;
    display: grid;
    grid-template-columns: 295px 1fr;
    grid-column-gap: 105px;
    column-gap: 105px;
    position: relative;
    @include break(desktop){
      padding-left: 0;
      grid-column-gap: 50px;
      column-gap: 50px;
    }
    @include break(tablet){
      margin-top: 85px;
      margin-bottom: 70px;
    }
    @include break(medium){
      display: block;
    }
    @include break(ss-small){
      margin-top: 60px;
    }
    .floating-element{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 10;
      @include break(ss-small){
        display: none;
      }
      &-1{
        background-image: url("data:image/svg+xml,%3Csvg width='74' height='74' viewBox='0 0 74 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-4' y='36.1875' width='58' height='58' rx='10' transform='rotate(-43.8564 -4 36.1875)' fill='%23EDFAFF'/%3E%3C/svg%3E%0A");
        width: 74px;
        height: 74px;
        top: -52px;
        right: -113px;
        @include break(medium){
          background-image: url("data:image/svg+xml,%3Csvg width='51' height='77' viewBox='0 0 51 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='38.1406' y='-3.21965' width='58.4899' height='58.4899' rx='9.74831' transform='rotate(45 38.1406 -3.21965)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.10358' stroke-dasharray='11.77 11.77'/%3E%3C/svg%3E%0A");
          width: 51px;
          height: 77px;
          right: -40px;
          top: 35px;
        }
      }
      &-2{
        background-color: #EDFAFF;
        width: 114px;
        height: 114px;
        border-radius: 50%;
        right: -9px;
        top: 321px;
        @include break(medium){
          display: none;
        }
      }
      &-3{
        background-image: url("data:image/svg+xml,%3Csvg width='246' height='246' viewBox='0 0 246 246' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='116.625' y='-3.93934' width='188.5' height='170.5' rx='13.25' transform='rotate(45 116.625 -3.93934)' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
        width: 246px;
        height: 246px;
        right: -215px;
        top: 192px;
        @include break(medium){
          display: none;
        }
      }
      &-4{
        background-image: url("data:image/svg+xml,%3Csvg width='74' height='74' viewBox='0 0 74 74' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-4' y='36.1875' width='58' height='58' rx='10' transform='rotate(-43.8564 -4 36.1875)' fill='%23EDFAFF'/%3E%3C/svg%3E%0A");
        width: 74px;
        height: 74px;
        right: -45px;
        bottom: -96px;
        @include break(medium){
          background-image: none;
          width: 76px;
          height: 76px;
          border-radius: 50%;
          background-color:  #EDFAFF;
          right: auto;
          left: -41px;
          bottom: 175px;
        }
      }
      &-5{
        background-color: #EDFAFF;
        width: 114px;
        height: 114px;
        border-radius: 50%;
        left: -183px;
        bottom: -202px;
        z-index: -1;
        @include break(medium){
          display: none;
        }
      }
      &-6{
        background-image: url("data:image/svg+xml,%3Csvg width='104' height='104' viewBox='0 0 104 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='52.2734' y='-3.93934' width='79.5' height='79.5' rx='13.25' transform='rotate(45 52.2734 -3.93934)' stroke='%231C143A' stroke-opacity='0.4' stroke-width='1.5' stroke-dasharray='3 3 3 3'/%3E%3C/svg%3E%0A");
        width: 104px;
        height: 104px;
        left: -297px;
        top: 39px;
        @include break(medium){
          display: none;
        }
      }
      &-7{
        background-image: url("data:image/svg+xml,%3Csvg width='191' height='192' viewBox='0 0 191 192' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='191' height='192' rx='29' fill='%23EDFAFF'/%3E%3C/svg%3E%0A");
        width: 191px;
        height: 192px;
        left: -166px;
        bottom: 336px;
        @include break(medium){
          background-image: url("data:image/svg+xml,%3Csvg width='58' height='117' viewBox='0 0 58 117' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-58' width='116' height='117' rx='29' fill='%23EDFAFF'/%3E%3C/svg%3E%0A");
          width: 58px;
          height: 117px;
          left: -40px;
          top: 324px;
        }
        @include break(ss-small){
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg width='62' height='66' viewBox='0 0 62 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='-5' y='32.4766' width='53' height='55' rx='14' transform='rotate(-45 -5 32.4766)' fill='%23F0FBFF'/%3E%3C/svg%3E%0A");
          width: 62px;
          height: 66px;
          right: -20px;
          top: -90px;
          left: auto;
          bottom: auto;
          z-index: -1;
        }
      }
    }
  }
  &__speaker-wrapper{

  }
  &__speaker{
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    z-index: 200;
    width: 100%;
    @include break(medium){
      top: auto;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      max-width: 510px;
    }
    &.has-img{
      padding-top: 92px;
      @include break(medium){
        padding-top: 113px;
      }
      @include break(ss-small){
        padding-top: 102px;
      }
    }
    &.sticky{
      .sticky-show{
        display: block;
      }
    }
    &-image-wrapper{
      width: 230px;
      height: 230px;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      z-index: 10;
      background-color: $azure;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @include break(ss-small){
        width: 195px;
        height: 195px;
      }
    }
    &-image{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: auto;
      max-height: 100%;
    }
    &-content{
      box-shadow: 0px 4px 45px #DAE3E9;
      border-radius: 19px;
      background-color: $white;
      padding: 150px 15px 50px;
      @include break(medium){
        padding: 132px 20px 35px 20px;
      }
      @include break(ss-small){
        padding: 120px 20px 35px 20px;
      }
      .mobile{
        display: none;
        @include break(medium){
          display: block;
          text-align: center;
        }
      }
      &-desctop{
        margin-bottom: 85px;
        @include break(medium){
          display: none;
        }
      }
    }
    &-text{
      font-size: 35px;
      line-height: 50px;
      color: $azure;
      margin-bottom: 5px;
      font-family: $main_font;
      @include break(medium){
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 15px;
      }
      @include break(ss-small){
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 20px;
      }
    }
    &-name{
      font-size: 75px;
      font-weight: 400;
      line-height: 85px;
      color: $dark_blue3;
      margin-bottom: 10px;
      &.sticky-show{
        @media screen and (min-width:992px){
          font-size: 23px;
          line-height: 30px;
          margin-bottom: 10px;
          text-align: center;
          color: $azure;
        }
      }
      @include break(medium){
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 5px;
      }
      @include break(ss-small){
        font-size: 23px;
        line-height: 30px;
        margin-bottom: 5px;
      }
    }
    &-position{
      font-weight: 300;
      font-size: 27px;
      line-height: 36px;
      color: $dark_blue3;
      margin-bottom: 0;
      &.sticky-show{
        @media screen and (min-width:992px){
          font-size: 16px;
          line-height: 23px;
          color: $azure;
          text-align: center;
          margin-bottom: 10px;
        }
      }
      @include break(medium){
        font-size: 18px;
        line-height: 26px;
        opacity: 0.7;
        margin-bottom: 5px;
      }
      @include break(ss-small){
        font-size: 16px;
        line-height: 23px;
      }
    }
    &-socials{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-social{
      width: 26px;
      height: 26px;
      display: flex;
      margin: 0 10px;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 100;
      transition: .3s linear;
      @include break(ss-small){
        width: 23px;
        height: 23px;
      }
      &:hover{
        transform: scale(1.1);
        path{
          fill: $dark_blue;
        }
      }
      svg,img{
        width: 100%;
        height: auto;
      }
      path{
        transition: .3s linear;
      }
    }
  }
  &__info-wrapper{
    position: relative;
    z-index: 100;
  }
  &__info{
    margin-top: 45px;
    max-width: 780px;
    width: 100%;
    position: relative;
    @include break(medium){
      max-width: none;
      margin-top: 75px;
    }
    @include break(ss-small){
      margin-top: 55px;
    }
    &-text{
      font-size: 35px;
      line-height: 50px;
      color: $gray2;
      margin-bottom: 5px;
      font-family: $main_font;
      @include break(medium){
        text-align: center;
        font-size: 19px;
        line-height: 27px;
      }
      @include break(ss-small){
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 0;
      }
    }
    &-start{
      font-weight: 300;
      font-size: 22px;
      line-height: 24px;
      color: $dark_blue3;
      margin-bottom: 45px;
      @include break(medium){
        text-align: center;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 30px;
      }
      @include break(ss-small){
        font-size: 15px;
        line-height: 26px;
        margin-bottom: 15px;
      }
    }
    &-block{
      @include break(medium){
        display: flex;
        justify-content: center;
      }
    }
    &-label{
      width: 105px;
      color: $dark_blue3;
      padding-right: 5px;
      display: inline-block;
      @include break(medium){
        width: 90px;
      }
      @include break(ss-small){
        width: 75px;
      }
    }
    &-date,
    &-address{
      font-family: $main_font_regular;
      font-size: 22px;
      line-height: 24px;
      color: $dark_blue3;
      margin-bottom: 0;
      @include break(medium){
        text-align: left;
        font-size: 18px;
        line-height: 23px;
      }
      @include break(ss-small){
        font-size: 15px;
        line-height: 26px;
      }
    } 
    &-date{
      margin-bottom: 15px;
      @include break(medium){
        margin-bottom: 3px;
      }
    }
    &-buttons-wrapper{
      margin-top: 55px;
      @include break(medium){
        margin-top: 35px;
        display: flex;
        justify-content: center;
      }
      @include break(ss-small){
        flex-direction: column;
        align-items: center;
      }
    }
    &-button-wrapper{
      margin-bottom: 10px;
      @include break(medium){
        margin-bottom: 0;
        margin-right: 15px;
      }
      @include break(ss-small){
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
    &-button,
    &-website{
      box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.15);
      @include break(medium){
        padding: 7px 15px;
        font-size: 15px;
        line-height: 19px;
        border-radius: 9px;
      }
    }
  }
  &__content{
    &-wrapper{
      position: relative;
      margin-top: 90px;
      @include break(medium){
        margin-top: 60px;
      }
    }
    &-title{
      font-size: 23px;
      line-height: 30px;
      padding-bottom: 40px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      margin-bottom: 40px;
      position: relative;
      z-index: 100;
      @include break(medium){
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 27px;
        margin-bottom: 35px;
      }
      @include break(ss-small){
        font-size: 18px;
        line-height: 24px;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
    &-text{
      position: relative;
      z-index: 100;
      p{
        font-weight: 300;
        font-size: 22px;
        line-height: 31px;
        color: $dark_blue3;
        margin-bottom: 0;
        &:not(:last-child){
          margin-bottom: 30px;
        }
        @include break(medium){
          font-size: 18px;
          line-height: 23px;
          &:not(:last-child){
            margin-bottom: 23px;
          }
        }
        @include break(ss-small){
          font-size: 15px;
          line-height: 21px;
          &:not(:last-child){
            margin-bottom: 21px;
          }
        }
      }
      li{
        list-style: inside;
      }
    }
  }
  &__video{
    &-wrapper{
      margin-top: 40px;
      position: relative;
      @include break(medium){
        margin-top: 55px;
      }
      @include break(ss-small){
        margin-top: 40px;
      }
      .floating-element{
        background-image: url("data:image/svg+xml,%3Csvg width='108' height='108' viewBox='0 0 108 108' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.86505 59.3707C0.448677 55.9474 0.44868 52.0762 1.86505 48.6529L2.55808 48.9397C3.20424 47.3779 4.16225 45.914 5.43361 44.6427L10.3352 39.7411L9.80488 39.2107L19.6081 29.4075L20.1384 29.9379L29.9416 20.1346L29.4113 19.6043L39.2145 9.8011L39.7449 10.3314L44.6465 5.42983C45.9178 4.15847 47.3817 3.20046 48.9434 2.55429L48.6567 1.86127C52.08 0.444898 55.9512 0.444898 59.3745 1.86127L59.0878 2.55429C60.6496 3.20046 62.1134 4.15847 63.3848 5.42982L68.3725 10.4176L68.9029 9.88724L78.8784 19.8627L78.348 20.3931L88.3235 30.3686L88.8539 29.8382L98.8293 39.8137L98.299 40.344L103.287 45.3318C104.558 46.6032 105.516 48.067 106.162 49.6288L106.855 49.3421C108.272 52.7654 108.272 56.6366 106.855 60.0599L106.162 59.7731C105.516 61.3349 104.558 62.7988 103.287 64.0701L98.3852 68.9717L98.9155 69.5021L89.1123 79.3053L88.5819 78.7749L78.7787 88.5782L79.3091 89.1085L69.5058 98.9117L68.9755 98.3814L64.0739 103.283C62.8026 104.554 61.3387 105.512 59.7769 106.159L60.0637 106.852C56.6404 108.268 52.7691 108.268 49.3458 106.852L49.6326 106.159C48.0708 105.512 46.6069 104.554 45.3356 103.283L40.3478 98.2952L39.8175 98.8256L29.842 88.8501L30.3723 88.3197L20.3968 78.3442L19.8665 78.8746L9.89102 68.8991L10.4214 68.3687L5.43361 63.381C4.16225 62.1096 3.20424 60.6458 2.55807 59.084L1.86505 59.3707Z' stroke='%231C143A' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='16 16'/%3E%3C/svg%3E%0A");
        width: 108px;
        height: 108px;
        left: -58px;
        bottom: -47px;
        z-index: 10;
        @include break(medium){
          display: none;
        }
      }
      .video-wrapper__iframe{
        width: 100%;
        height: 400px;
        position: relative;
        z-index: 20;
      }
    }
    &-creator{
      padding: 0;
      width: 100%;
      box-shadow: none;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      z-index: 20;
      @include break(medium){
        border-radius: 15px;
      }
    }
    &-preview{
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    
    &-play{
      width: 61px;
      height: 68px;
      opacity: 0.5;
      transition: .3s linear;
      @include break(ss-small){
        width: 35px;
        height: 37px;
      }
      &:hover{
        opacity: 1;
        scale: 1.1;
      }
    }
  }
  &__bottom{
    margin-top: 105px;
    text-align: center;
    position: relative;
    z-index: 100;
    padding-left: 45px;
    padding-right: 45px;
    @include break(medium){
      margin-top: 90px;
    }
    @include break(ss-small){
      margin-top: 105px;
    }
  }
  &__prev,
  &__next{
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $dark_blue;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s linear;
    path{
      transition: .3s linear;
    }
    &:hover{
      background-color: $dark_blue;
      path{
        stroke: $white;
      }
    }
  }
  &__prev{
    left: 0;
  }
  &__next{
    right: 0;
  }
  &__back{
    @extend .primary-btn;
    max-width: 245px;
    width: 100%;
    background-color: transparent;
    @include break(medium){
      max-width: none;
      width: auto;
      padding: 7px 15px;
      font-size: 15px;
      line-height: 19px;
      border-radius: 9px;
    }
  }
}

.single-resources-download-content__wrapper.custom_content{
  grid-template-columns: 60.65% 35.9%;
  @include break(tablet){
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
    row-gap: 30px;
  }
  @include break(x-small){
    grid-row-gap: 50px;
    row-gap: 50px;
  }
}
.single-resources-download-content__block.custom_block{
display: block;
}
.single-events__info-wrapper.custom_info-wrapper{
  display: flex;
}
.right-content-speakers{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include break(x-small){
   padding-left: 15%;
   align-content: space-between;
  }
  .single-events__speaker{
    position: relative !important;
    flex:50%;
    margin-bottom: 10px;
    // margin: 10px;
    top: 0;
    // max-height: 370px;
    .mobile{
      display: block !important;
    }
    .single-events__speaker-content{
      padding: 0;
      padding-top: 240px;
      width: 230px;
      padding-bottom: 20px;
      box-shadow : none !important;
    }
    .single-events__speaker-image-wrapper{
           left: auto; 
     -webkit-transform: translateX(0%); 
     transform: translateX(0%);
    //  position: static;
    }
  }
 
}
.single-events__info.custom-sigle-events{
max-width:100%;
}
.left-floating-element{
  width: 60%;
  @include break(tablet){
    width: 100%;
  }
  @include break(x-small){
    width: 100%;
  }  
}
 
.single-resources-download-content__block-container{ 
  padding:0 0!important;
}

.single-resources-download-content {   
  &__form-wrapper{
    min-height: 620px!important;
  }
  
&__form {
    &__wrap{
      @include break(tablet){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 45px;
        column-gap: 45px;
      }
      @include break(ss-small){
        grid-column-gap: 20px;
        column-gap: 20px;
      }
      @include break(x-small){
        display: block;
      }
    }
    &__col{
      width: 100%;
      margin-bottom: 15px;
    }
    &__label{
      font-style: normal;
      font-family: $second_font;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      display: block;
      color: rgba(28,20,58,0.6);
      margin-bottom: 5px;
      @include break(x-small){
        display: none;
      }
    }
    &__checkbox-wrap{
      width : 100%;
      display : flex;
      align-items : flex-start;
      @include break(tablet){
        margin-top: 10px;
      }
      @include break(x-small){
        mask-type: 5px;
      }
    }
    &__label-checkbox{
      font-family: $main_font;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.6);
      margin-left : 6px;
      @include break(x-small){
        font-size: 10px;
      }
    }
    &__submit{
      text-align: center;
      margin-top: 50px;
      position: relative;
      @include break(tablet){
        margin-top: 25px;
      }
      @include break(x-small){
        margin-top: 30px;
      }
      .wpcf7-submit{
        border: 1px solid $dark_blue;
        border-radius: 14.5737px;
        height : 40px;
        padding : 0 24px;
        cursor: pointer;
        transition: 0.3s;
        font-family: $main_font;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.35;
        display: flex;
        align-items: center;
        background-color : $white;
        position : relative;
        margin-left: auto;
        margin-right: auto;
        color: #1C143A;
        &:hover {
          background-color : $dark_blue;
          color: $white;
        }
      }
      .wpcf7-spinner{
        position: absolute;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .hs-form-field label {
      font-style: normal;
      font-family: "Helvetica",sans-serif;
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      display: block;
      margin-bottom: 5px;
    }
    .hs-form-field label span{
      color: rgba(28,20,58,0.6) !important;
    }
    .hs-form-field label.hs-error-msg {
      font-style: normal;
      font-family: "Helvetica",sans-serif;
      font-weight: 400;
      font-size: 12px;
    }
    .hs_error_rollup .hs-main-font-element{
      font-size: 12px;
      color: #dc3232;
    }
  input[type="text"],input[type="email"], input[type="tel"] {
    width : 100%;
    background: #FFFFFF;
    border: 1px solid rgba(28, 20, 58, 0.17);
    border-radius: 14.5737px;
    height : 40px;
    font-size: 15px;
    padding : 0 10px;
    margin-bottom: 10px;
    display: table-row;
    color: rgba(28, 20, 58, 0.6);   
    &.wpcf7-not-valid{
      border-color: #dc3232;
    }
    @media( max-width: 480px) {
      height : 30px;
      border-radius: 10px;
      font-size: 12px;
    }

    &::placeholder {
      font-family: $second_font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      color: rgba(28, 20, 58, 0.6);
      opacity: 0;

      @media( max-width: 480px) {
        opacity: 1;
      }
    }
   
      }
  }

}
.primary{
  border: 1px solid $dark_blue;
  border-radius: 14.5737px;
  height : 40px;
  padding : 0 24px;
  cursor: pointer;
  transition: 0.3s;
  font-family: $main_font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.35;
  display: flex;
  align-items: center;
  background-color : $white;
  position : relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  color: #1C143A;
  &:hover {
    background-color : $dark_blue;
    color: $white;
  }
}
.single-resources-download-content__form {
  .hbspt-form {
  .hs-form-field {    
    line-height: 40px;
  }
}
ul li{
  margin-top: 0px;
}
.hs-error-msg{
  color: #dc3232;
}
}
.single-events__speaker-social {
  width: 18px;
  height: 18px;
}
.single-events .last-event{
  margin-bottom: 69px !important; 
}
.left-floating-element .single-events__speaker-content-desctop{
  margin-bottom: 5px !important;
} 
