.next-step-gradiend{
	display: block;
	width: 100%;
	height: 1500px;
	background: linear-gradient(177.82deg, #E8F5FA 9.81%, rgba(241, 250, 253, 0) 51.3%);
	left: 0;
	bottom: 370px;
	position: absolute;
	z-index: -1;
	transform: matrix(1, 0, 0, -1, 0, 0);
	@media(max-width : 1030px){
		display: block;
		height: 2000px;
		bottom: 34px;
	}
	@media(max-width : 480px){
		height: 2390px;
		bottom: 36px;
	}
}
.next-step {
	min-height        : 480px;
	position          : relative;
	z-index           : 20;
	margin-top: 200px;
	@include break(desktop){
		margin-top: 100px;
	}
	@include break(medium){
		margin-top: 50px;
	}
	@include break(x-small){
		margin-top: 60px;
	}
	@media(max-width : 768px) {
		padding-bottom      : 200px;
		
	}
	@media(max-width : 480px) {
		padding-bottom      : 75px;
		
	}
	&.animate {
		.next-step__form {
			opacity : 1;
			top     : -75px;

			@media( max-width: 768px) {
				top     : 0px;
			}
		}
	}

	&__bg{
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
    z-index: -1;
		top: 0;
		background-repeat : no-repeat;
		background-position : right bottom;
		background-image: url(../images/next-step-bg-hd.jpg);
		@media(min-width : 1921px){
			background-size: cover;
		}
		@media(max-width : 1440px){
			background-image: url(../images/next-step-bg-desktop.jpg);
		}
		@media(max-width : 768px) {
			
			/*background-size     : 100% 325px;*/
			background-image: url(../images/next-step-bg-tablet.jpg);
		}
		
		@media(max-width : 500px) {
			/*background-size : 100% 150px;*/
		}
	
		@media(max-width : 480px) {
			/*background-size : 100% 148px;*/
			background-size   : contain;
			background-image: url(../images/next-step-bg-mobile.jpg);
		}
		@media(max-width : 375px) {
			background-size: auto;
		}
	}
	&__wrapper {
		display   : flex;
		flex-wrap : wrap;

		@media(max-width : 1200px) {
			justify-content : space-between;
		}

		@media(max-width : 768px) {
			justify-content : center;
		}
	}

	&__content {
		width        : 50%;
		padding-left : 98px;
		display: flex;
		align-items: flex-end;
		@media(max-width : 1370px) {
			padding-left : 0px;
		}

		@media(max-width : 992px) {
			width : 48%;
		}

		@media(max-width : 768px) {
			width : 100%;
			display: block;
		}

		h2 {
			font-family : $main_font;
			font-style  : normal;
			font-weight : 400;
			font-size   : 62px;
			line-height : 109%;
			color       : #FFFFFF;
			margin-bottom  : 240px;

			@media(max-width : 1200px) {
				font-size : 60px;
			}

			@media(max-width : 1024px) {
				font-size : 55px;
			}

			@media(max-width : 992px) {
				font-size : 50px;
				margin-top : 40px;
			}

			@media(max-width : 768px) {
				font-size     : 40px;
				color         : #021D59;
				margin-bottom : 40px;
				text-align    : center;
				margin-top : 35px;
			}

			@media(max-width : 480px) {
				font-size   : 34px;
				line-height : 114%;
				margin-top : 35px;
			}

			@media(max-width : 390px) {
				font-size : 30px;
				padding   : 0 50px;
				margin-top : 30px;
			}
		}
	}

	&__form {
		width         : 50%;
		transition    : 0.5s;
		opacity       : 0;
		max-width     : 449px;
		background    : #FFFFFF;
		border        : 1px solid #E0E0E0;
		box-shadow    : 0px 0px 44px rgba(64, 67, 208, 0.19);
		border-radius : 24px;
		padding       : 62px 0;
		position      : relative;
		top           : 0;
		margin-left   : 80px;

		@media(max-width : 1200px) {
			max-width   : 400px;
			margin-left : 0;
		}

		@media(max-width : 1024px) {
			padding   : 55px 0px;
			max-width : 380px;
		}

		@media(max-width : 768px) {
			padding       : 50px 0px;
			top           : 75px;
			max-width     : 100%;
			width         : 515px;
			border-radius : 22px;
		}

		@media(max-width : 600px) {
			/*width         : 80%;*/
			border-radius : 20px;
		}

		@media(max-width : 480px) {
			padding       : 45px 0 30px;
			width         : 100%;
			border-radius : 17px;
		}

		@media(max-width : 390px) {
			padding : 41px 0 27px;
		}

		h4 {
			font-family   : $main_font;
			font-style    : normal;
			font-weight   : 400;
			font-size     : 22px;
			line-height   : 109%;
			color         : #021D59;
			margin-bottom : 23px;
			text-align    : center;

			@media(max-width : 1200px) {
				padding : 0 32px;
			}

			@media(max-width : 1024px) {
				padding : 0 28px;
			}

			@media(max-width : 768px) {
				font-size : 21px;
				padding   : 0 55px;
			}

			@media(max-width : 480px) {
				font-size     : 20px;
				margin-bottom : 22px;
			}

			@media(max-width : 390px) {
				font-size     : 18px;
				margin-bottom : 19px;
			}
		}

		form {
			width   : 100%;
			padding : 0 62px;

			@media(max-width : 1200px) {
				padding : 0 32px;
			}

			@media(max-width : 1024px) {
				padding : 0 28px;
			}

			@media(max-width : 768px) {
				padding : 0 55px;
			}

			@media(max-width : 480px) {
				padding : 0 50px;
			}

			@media(max-width : 390px) {
				padding : 0 47px;
			}

			.form-row {
				width         : 100%;
				margin-bottom : 19px;

				@media(max-width : 768px) {
					margin-bottom : 18px;
				}

				@media(max-width : 480px) {
					margin-bottom : 15px;
				}

				@media(max-width : 390px) {
					margin-bottom : 11px;
				}

				&.textarea-wrap {
					margin-bottom : 18px;

					@media(max-width : 480px) {
						margin-bottom : 22px;
					};
				}

				.wpcf7-form-control-wrap {
					width : 100%;
				}
			}

			input[type="text"], input[type="email"], textarea {
				width         : 100%;
				border        : 0.910859px solid rgba(28, 20, 58, 0.17);
				border-radius : 14.5737px;
				height        : 40px;
				font-family   : 'Rubik', sans-serif;
				font-style    : normal;
				font-weight   : 400;
				font-size     : 14.5737px;
				line-height   : 17px;
				display       : flex;
				align-items   : center;
				color         : rgba(28, 20, 58, 0.6);
				padding       : 0 12px;

				@media(max-width : 768px) {
					height : 38px;
				}

				@media(max-width : 480px) {
					height    : 35px;
					font-size : 13px;
					padding   : 0 10px;
				}

				@media(max-width : 390px) {
					height    : 31px;
					font-size : 12px;
				}
			}

			textarea {
				height  : 129px;
				padding : 10px 12px;
				resize: none;
				@media(max-width : 768px) {
					height : 115px;
				}

				@media(max-width : 480px) {
					height  : 100px;
					padding : 6px 10px;
				}

				@media(max-width : 390px) {
					height : 96px;
				}
			}
			.submit-wrapper{
				display: inline-block;
				@media(max-width : 768px) {
					display: block;
					text-align: center;
				}
			}
			input[type="submit"] {
				font-family      : $main_font;
				font-style       : normal;
				font-weight      : 400;
				font-size        : 15px;
				line-height      : 20px;
				text-align       : center;
				color            : #021D59;
				border           : 1px solid #021D59;
				border-radius    : 14px;
				height           : 40px;
				padding          : 0 24px;
				background-color : #fff;
				cursor           : pointer;
				transition: .3s linear;
				&:hover{
					background-color: #021D59;
					color: $white;
				}
				@media(max-width : 768px) {
					height  : 38px;
					padding : 0 22px;
				}

				@media(max-width : 480px) {
					height  : 36px;
					padding : 0 18px;
				}

				@media(max-width : 390px) {
					height  : 33px;
					padding : 0 16px;
				}

				&:hover {
					transition : 0.3s;
					opacity    : 0.8;
				}
			}
		}
	}
}

