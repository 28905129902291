.single-resources-banner{
  position: relative;
  height: 480px;
  box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
  margin-bottom: 75px;
  @include break(tablet){
    margin-bottom: 50px;
  }
  @include break(small){
    min-height: 465px;
    height: auto;
  }
  @include break(ss-small){
    margin-bottom: 35px;
  }
  @include break(x-small){
    min-height: 455px;
  }
  &__image-wrapper{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10; 
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(89.91deg, #021D59 43.47%, rgba(2, 29, 89, 0.47) 76.05%, rgba(2, 29, 89, 0) 96.56%);
      z-index: 20;
      @include break(medium){
        background: linear-gradient(89.98deg, rgba(2, 29, 89, 0.95) 5.54%, rgba(2, 29, 89, 0.56) 71.24%, rgba(2, 29, 89, 0) 101.13%);
      }
      @include break(x-small){
        background: linear-gradient(89.91deg, #021D59 43.47%, rgba(2, 29, 89, 0.47) 76.05%, rgba(2, 29, 89, 0) 96.56%);
      }
    }   
  }
  &__image{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &-desktop{
      @include break(medium){
        display: none;
      }
    }
    &-tablet{
      display: none;
      @include break(medium){
        display: block;
      }
      @include break(x-small){
        display: none;
      }
    }
    &-mobile{
      display: none;
      @include break(x-small){
        display: block;
      }
    }
  }
  &__container{
    position: relative;
    z-index: 100;
    padding-top: 75px;
    display: flex;
    align-items: flex-end;
    height: 100%;
    @include break(x-small){
      padding-top: 30px;
    }
  }
  &__back{
    position: absolute;
    padding: 0;
    border: none;
    background-color: transparent;
    top: 34px;
    display: block;
    width: 39px;
    height: 39px;
    left: 60px;
    @include break(large1370) {
      left  : 40px;
    }
    @include break(ss-small){
      left: 20px;
    }
    @include break(x-small) {
      display: none;
    }
    svg{
      pointer-events: none;
    }
    path{
      transition: .3s linear;
    }
    &:hover{
      path{
        fill: $azure;
      }
    }
  }
  &__content{
    padding-bottom: 100px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 1025px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include break(medium){
      padding-bottom: 115px;
      justify-content: flex-end;
    }
    @include break(small){
      min-height: 390px;
    }
    @include break(x-small){
      min-height: 425px;
    }
  }
  &__category{
    font-size: 30px;
    line-height: 43px;
    color: $azure;
    text-transform: uppercase;
    margin-bottom: 20px;
    @include break(medium){
      margin-bottom: 15px;
    }
    @include break(s-small){
      font-size: 25px;
      line-height: 34px;
    }
    @include break(x-small){
      text-align: center;
    }
  }
  &__title{
    max-width: 870px;
    font-size: 50px;
    line-height: 55px;
    color: $white;
    margin-bottom: 10px;
    @include break(tablet){
      font-size: 45px;
      line-height: 50px;
    }
    @include break(s-small){
      font-size: 30px;
      line-height: 34px;
    }
    @include break(x-small){
      text-align: center;
      margin-bottom: 35px;
      max-width: 345px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__info{
    color: $white;
    font-size: 23px;
    line-height: 30px;
    margin-bottom: 0;
    font-family: $main_font;
    @include break(s-small){
      font-size: 18px;
      line-height: 31px;
    }
    .sep{
      color: $white;
    }
    @include break(x-small){
      .sep{
        display: none;
      }
    }
    .author,
    .date{
      color: $white;
      @include break(x-small){
        display: block;
        text-align: center;
      }
    }
  }
  &__share-wrapper{
    position: absolute;
    left: 0;
    bottom: 47px;
    display: flex;
    align-items: center;
    @include break(medium){
      bottom: 36px;
    }
    @include break(x-small){
      justify-content: center;
      width: 100%;
      bottom: 45px;
    }
    .copy-text{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -25px;
      color: $white;
      font-size: 15px;
      line-height: 19px;
      text-align: center;
      visibility: hidden;
      opacity: 0;
      transition: .3s linear;
      &.show{
        visibility: visible;
        opacity: 1;
      }
    }
    /*#copy-link-url{
      visibility: hidden;
      opacity: 0;
      position: absolute;
      width: 0;
      height: 0;
      z-index: -1;
    }*/
  }
  &__share{
    width: 38px;
    height: 38px;
    padding: 0;
    background-color: transparent;
    border: none;
    margin: 0 10px;
    @include break(medium){
      width: 32px;
      height: 32px;
    }
    @include break(x-small){
      width: 27px;
      height: 27px;
      margin: 0 8px;
    }
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    circle,
    path{
      transition: .3s linear;
    }
    &:hover{
      circle,
      path{
        fill: $azure;
      }
    }
    &#copy-link-btn{
      &:hover{
        path{
          fill: $dark_blue;
        }
      }
    }
    svg{
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }
}