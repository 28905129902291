.already-partner {
	position : relative;

	&__wrap {
		position : relative;
		background: $dark_blue;
		width : 100%;
		max-width: 937px;
		box-shadow: 0px 10px 30px rgba(28, 20, 58, 0.3);
		border-radius: 35px;
		overflow: hidden;
		padding : 70px 75px;
		margin : 75px auto 94px;
		min-height : 458px;

		@media( max-width: 768px) {
			margin : 50px auto 66px;
			padding : 100px 112px 150px;
			text-align : center;
			max-width : 100%;
			width : calc(100% - 40px);
			min-height : 319px;
		}

		@media( max-width: 600px) {
			padding : 50px 100px 150px;
		}

		@media( max-width: 550px) {
			padding : 50px 90px 150px;
		}

		@media( max-width: 400px) {
			padding : 50px 55px 150px;
		}

		@media( max-width: 480px) {
			border-radius: 20px;
			margin : 28px auto 49px;
			padding : 50px 39px 140px;
			width : 100%;
			min-height : 422px;
		}
	}


	&__background {
		position : absolute;
		width : 100%;
		height : 100%;
		display : flex;
		flex-direction : column;
		top : 0;
		left: 0; 
		background-image: url("data:image/svg+xml,%3Csvg width='937' height='450' viewBox='0 0 937 450' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1923_9648' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='937' height='450'%3E%3Crect width='937' height='450' rx='17' fill='%23021D59'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1923_9648)'%3E%3Cpath d='M463.447 417.644L325.776 477.733C316.511 481.777 312.279 492.469 316.325 501.616C320.37 510.762 331.16 514.899 340.425 510.855L478.097 450.766C487.362 446.722 491.593 436.03 487.548 426.883C483.503 417.737 472.712 413.6 463.447 417.644Z' fill='%23009AD6'/%3E%3Cpath d='M530.746 488.684L515.243 473.29C499.96 461.065 497.552 438.862 509.863 423.685L570.45 319.659C582.761 304.482 605.121 302.091 620.405 314.316L635.907 329.71C651.191 341.934 653.598 364.138 641.288 379.315L580.7 483.341C568.39 498.518 546.03 500.908 530.746 488.684Z' fill='%231FB0C5'/%3E%3Cpath d='M662.722 366.148L646.826 325.644C635.178 298.825 647.679 267.661 674.745 256.018L754.282 208.486C781.365 196.85 812.753 209.148 824.408 235.95L840.304 276.455C851.953 303.274 839.452 334.438 812.385 346.081L732.848 393.613C705.765 405.249 674.378 392.951 662.722 366.148Z' fill='%233EC7B4'/%3E%3Cpath d='M841.916 50.0477L795.399 96.2391C767.564 123.879 767.564 168.692 795.399 196.331L841.916 242.523C869.751 270.162 914.879 270.162 942.714 242.523L989.231 196.331C1017.07 168.692 1017.07 123.879 989.231 96.2391L942.714 50.0476C914.879 22.4079 869.751 22.408 841.916 50.0477Z' fill='%235DDDA3'/%3E%3C/g%3E%3C/svg%3E%0A");
		background-position: bottom right;
		background-repeat: no-repeat;
		@media( max-width: 850px){
			background-image: url("data:image/svg+xml,%3Csvg width='678' height='714' viewBox='0 0 678 714' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1923_9928' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='678' height='714'%3E%3Crect y='0.523438' width='678' height='713' rx='17' fill='%23021D59'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1923_9928)'%3E%3Cpath d='M232.698 665.087L108.174 709.175C99.7935 712.142 95.9663 719.987 99.6253 726.698C103.284 733.409 113.044 736.444 121.424 733.477L245.949 689.389C254.329 686.422 258.156 678.577 254.497 671.866C250.838 665.155 241.079 662.12 232.698 665.087Z' fill='%23009AD6'/%3E%3Cpath d='M298.712 678.075L284.69 666.78C270.866 657.811 268.688 641.52 279.823 630.385L334.625 554.059C345.76 542.924 365.985 541.17 379.809 550.139L393.831 561.434C407.655 570.403 409.832 586.694 398.697 597.829L343.896 674.155C332.761 685.29 312.536 687.044 298.712 678.075Z' fill='%231FB0C5'/%3E%3Cpath d='M418.085 588.172L403.707 558.454C393.171 538.776 404.478 515.911 428.96 507.368L500.901 472.493C525.398 463.956 553.788 472.979 564.331 492.644L578.709 522.363C589.245 542.04 577.937 564.906 553.456 573.448L481.514 608.323C457.018 616.86 428.627 607.838 418.085 588.172Z' fill='%233EC7B4'/%3E%3Cpath d='M580.169 356.241L538.094 390.132C512.917 410.412 512.917 443.291 538.094 463.571L580.169 497.462C605.345 517.742 646.164 517.742 671.341 497.462L713.416 463.571C738.592 443.291 738.592 410.412 713.416 390.132L671.341 356.241C646.164 335.961 605.345 335.961 580.169 356.241Z' fill='%235DDDA3'/%3E%3C/g%3E%3C/svg%3E%0A");
			background-size: contain;
		}
		@media( max-width: 670px){
			background-image: url("data:image/svg+xml,%3Csvg width='299' height='217' viewBox='0 0 299 217' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M75.2623 182.688L8.17482 208.973C3.65999 210.742 1.59807 215.419 3.56939 219.42C5.54072 223.421 10.7988 225.23 15.3136 223.462L82.4011 197.177C86.9159 195.408 88.9778 190.731 87.0065 186.73C85.0352 182.729 79.7771 180.92 75.2623 182.688Z' fill='%23009AD6'/%3E%3Cpath d='M108.055 213.763L100.5 207.029C93.0527 201.682 91.8795 191.969 97.8786 185.331L127.403 139.827C133.402 133.188 144.298 132.142 151.746 137.49L159.3 144.223C166.748 149.571 167.921 159.283 161.922 165.922L132.398 211.426C126.399 218.065 115.502 219.11 108.055 213.763Z' fill='%231FB0C5'/%3E%3Cpath d='M172.369 160.164L164.622 142.447C158.946 130.715 165.038 117.083 178.227 111.99L216.986 91.1984C230.183 86.1086 245.479 91.488 251.158 103.212L258.905 120.93C264.581 132.661 258.489 146.293 245.3 151.386L206.541 172.178C193.344 177.268 178.048 171.889 172.369 160.164Z' fill='%233EC7B4'/%3E%3Cpath d='M259.692 21.8914L237.024 42.0968C223.46 54.1871 223.46 73.7894 237.024 85.8798L259.692 106.085C273.256 118.176 295.247 118.176 308.811 106.085L331.479 85.8798C345.043 73.7895 345.043 54.1871 331.479 42.0968L308.811 21.8914C295.247 9.80102 273.256 9.80103 259.692 21.8914Z' fill='%235DDDA3'/%3E%3C/svg%3E%0A");
			background-size: auto;
		}
	}

	img {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	&__background-desktop {

		@media( max-width: 768px) {
			display : none;
		}
	}

	&__background-tablet {
		display : none;

		@media( max-width: 768px) {
			display : block;
		}

		@media( max-width: 480px) {
			display : none;
		}
	}

	&__background-mobile {
		display : none;

		@media( max-width: 480px) {
			display : block;
		}
	}

	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 75px;
		line-height: 1.13;
		color: $white;
		margin-bottom : 33px;
		position : relative;
		z-index : 1;

		@media( max-width: 992px) {
			font-size: 60px;
		}

		@media( max-width: 768px) {
			font-size: 50px;
		}

		@media( max-width: 480px) {
			font-size: 45px;
			margin-bottom : 19px;
		}
	}

	&__content {

		max-width: 520px;
		position : relative;
		z-index : 1;
		p {
			font-family: $second_font;
			font-style: normal;
			font-weight: 300;
			font-size: 25px;
			line-height: 1.43;
			color: $white;
			

			@media( max-width: 992px) {
				font-size: 22px;
			}

			@media( max-width: 768px) {
				font-size: 18px;
				max-width: 463px;
				margin-left : auto;
				margin-right : auto;
				text-align : center;
			}

			@media( max-width: 480px) {
				font-size: 15px;
				max-width : 240px;
			}
		}
	}

	&__floating-element {
		position : absolute;
		background-repeat : no-repeat;
		background-position : center center;

		&.floating-element-1 {
			top : 0;
			left : 0;
			width : 100px;
			height : 126px;
			background-image: url(data:image/png+xml;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAB/CAMAAAAuLZc+AAAAclBMVEUAAACvQP+vSP+3SP+1Rf+1Sv+6Sv+zSP+3SP+ySf+2Rv+2Sf+1SP+3SP+4R/+2R/+2Sf+3SP+1SP+3SP+2R/+4Sf+1R/+2R/+2Sf+3SP+2SP+3SP+4R/+4Sf+3SP+2R/+2Sf+4R/+3SP+4R/+4Sf+3SP+FgEyPAAAAJXRSTlMAECAgMDAwQEBQUFBgYG9wcH+AgI+PkJCQn6Cgr6+/z8/P3+/vupuaKQAAAYhJREFUeNrtmWdyg0AUg9/L4l5obrT15m24/xWTG7gAmliD/jMaoW+7/Ek3p9asf0UW2mKj8qw09dGn26WTV+SW28JHv3/yK6vWKm9qXffVUzYqQ+RKq5xMLnezvQBs7KwyufQWHCBMaUQuQQBqzgATtTXAZW0KcKkLxA+DEFZ4nu69AOQzRJQfBMY3CMaRBmMBTWE0GDsMxmcIxgnAJYNgfOdZiX2BwBgSpaggGCO6P2BW4h0Nxh0GY8hK3CIwNiKM95+/oZzPRfO5iCcKZH9UXCAbcAHIEsTckgFM0gpgknxDFnuAiULw6nlMWIpfeYBJipghqwyxoCQkvacVyS3bykh29gdEEAMEOVYkJ+Dqig1CgC/BhJIj8I0IfEuSC1WjwdeR4NuT4LswIcE3AFqnwddI8N3S4NuytE6D73zimU88HxtEbc/xs/IA8DDH4KHnMLmHC41OHSOP2fQW1RO/yqqVvKvVKT73jOjSu7XpKnkxwdcmraIv9fnmthdv/UuK1l0OKv9Jv8vJJ1V+MjfCAAAAAElFTkSuQmCC);

		}

		&.floating-element-2 {
			width : 44px;
			height : 100px;
			top: -36px;
			left: 0;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDQiIGhlaWdodD0iMTAwIiB2aWV3Qm94PSIwIDAgNDQgMTAwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0tMjYuNjc3MiAwLjAyMzQzNzVINi42NzcxOEMxNC4wNjI2IDAuMDIzNDM3NSAyMC44NzI2IDMuOTUzNzEgMjQuNTQxMyAxMC4zMjg0TDQxLjIzMDUgMzkuMjA2NEM0NC45MjMyIDQ1LjU4MTIgNDQuOTIzMiA1My40NDE3IDQxLjIzMDUgNTkuODQwNEwyNC41NDEzIDg4LjcxODRDMjAuODQ4NiA5NS4wOTMyIDE0LjAzODYgOTkuMDIzNCA2LjY3NzE4IDk5LjAyMzRILTI2LjY3NzJDLTM0LjA2MjYgOTkuMDIzNCAtNDAuODcyNSA5NS4wOTMyIC00NC41NDEzIDg4LjcxODRMLTYxLjIzMDUgNTkuODQwNEMtNjQuOTIzMiA1My40NjU3IC02NC45MjMyIDQ1LjYwNTEgLTYxLjIzMDUgMzkuMjA2NEwtNDQuNTQxMyAxMC4zMjg0Qy00MC44NDg1IDMuOTUzNzEgLTM0LjAzODYgMC4wMjM0Mzc1IC0yNi42NzcyIDAuMDIzNDM3NVoiIGZpbGw9IiNGNUU4RkQiLz48L3N2Zz4=);

		}

		&.floating-element-3 {
			width : 176px;
			height : 256px;
			bottom: -255px;
			left : 0;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTcxIiBoZWlnaHQ9IjI0NiIgdmlld0JveD0iMCAwIDE3MSAyNDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iNDEuNjIzIiB5PSItMy45MTU5IiB3aWR0aD0iMTg4LjUiIGhlaWdodD0iMTcwLjUiIHJ4PSIxMy4yNSIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNDEuNjIzIC0zLjkxNTkpIiBzdHJva2U9IiMxQzE0M0EiIHN0cm9rZS1vcGFjaXR5PSIwLjMiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtZGFzaGFycmF5PSIxNiAxNiIvPjwvc3ZnPg==);


		}
	}


}
