.busines-sidekick {
	padding-top : 68px;

	h2, h3 {
		font-family   : $main_font;
		font-style    : normal;
		font-weight   : 400;
		font-size     : 35px;
		line-height   : 143%;
		text-align    : center;
		color         : #021D59;
		margin-bottom : 5px;

		@media(max-width : 992px) {
			font-size : 32px;
		}

		@media(max-width : 768px) {
			font-size : 30px;
		}

		@media(max-width : 480px) {
			font-size : 28px;
		}

		@media(max-width : 390px) {
			font-size : 25px;
			padding   : 0 64px;
		}
	}

	h3 {
		font-family   : $second_font;
		font-size     : 20px;
		margin-bottom : 70px;

		@media(max-width : 992px) {
			margin-bottom : 65px;
		}

		@media(max-width : 768px) {
			margin-bottom : 55px;
			font-size     : 18px;
		}

		@media(max-width : 480px) {
			margin-bottom : 50px;
			font-size     : 16px;
		}

		@media(max-width : 390px) {
			margin-bottom : 45px;
			font-size     : 15px;
			padding       : 0 20px;
		}
	}

	&__tab-links {
		display       : inline-flex;
		position      : relative;
		left          : 50%;
		transform     : translateX(-50%);
		margin-bottom : 70px;

		@media(max-width : 992px) {
			margin-bottom : 60px;
		}

		@media(max-width : 768px) {
			margin-bottom : 40px;
		}

		@media(max-width : 700px) {
			justify-content : space-between;
			display         : flex;
		}

		@media(max-width : 480px) {
			margin-bottom : 25px;
		}

		@media(max-width : 390px) {
			margin-bottom : 17px;
		}
	}

	&__tab-links-item {
		display         : inline-flex;
		justify-content : center;
		align-items     : center;
		background      : #F2F2F2;
		border-radius   : 16px;
		width           : 255px;
		height          : 66px;
		margin-right    : 37px;
		font-family     : $second_font;
		font-style      : normal;
		font-weight     : 400;
		font-size       : 19.8556px;
		line-height     : 119.5%;
		text-align      : center;
		color           : #021D59;

		@media(max-width : 992px) {
			font-size    : 18px;
			margin-right : 25px;
			width        : 200px;
		}

		@media(max-width : 768px) {
			font-size    : 16px;
			margin-right : 20px;
			height       : 66px;
			width        : 208px;
		}

		@media(max-width : 700px) {
			width        : calc(33.33% - 20px);
			margin-right : 0;
		}

		@media(max-width : 600px) {
			font-size : 15px;
		}

		@media(max-width : 480px) {
			width     : calc(33.33% - 10px);
			font-size : 14px;
			height    : 60px;
		}

		@media(max-width : 390px) {
			font-size : 12px;
		}


		&:hover {
			transition : 0.3s;
			color      : #FFFFFF;
			background : #15BDFF;
		}

		&.active {
			color      : #FFFFFF;
			background : #15BDFF;
		}


		&:last-of-type {
			margin-right : 0;
		}
	}

	&__tab-content-item {
		display : none;

		&.active {
			display : block;
		}
	}

	&__tab-sublinks-items {
		display         : flex;
		justify-content : center;
		padding-bottom  : 6px;
		position        : relative;
		left            : 50%;
		transform       : translateX(-50%);

		@media(max-width : 1030px) {
			display : none;
		}

		&:before {
			content       : "";
			position      : absolute;
			top           : 100%;
			left          : 0;
			height        : 6px;
			background    : #F2F2F2;
			border-radius : 4px;
			width         : 100%;
			display       : block;
		}
	}

	&__tab-sublinks-item {
		width           : auto;
		flex            : 1;
		height          : 70px;
		display         : inline-flex;
		justify-content : center;
		align-items     : center;
		font-family     : $second_font;
		font-style      : normal;
		font-weight     : 400;
		font-size       : 16px;
		line-height     : 143%;
		text-align      : center;
		color           : #021D59;
		padding         : 0 15px;

		@media(max-width : 1370px) {
			padding : 0 8px;
		}

		@media(max-width : 1200px) {
			font-size : 14px;
		}

		&:hover {
			color : #111111;
		}

		&.active {
			position    : relative;
			font-weight : 700;

			&:before {
				content       : "";
				position      : absolute;
				display       : block;
				bottom        : -12px;
				width         : 100%;
				left          : 0;
				height        : 6px;
				border-radius : 4px;
				background    : #15BDFF;
				box-shadow    : 1px 3px 11px rgba(49, 197, 255, 0.7);
			}
		}
	}

	&__tab-subcontent-item {
		display : none;
		padding : 92px 60px 109px;

		@media(max-width : 992px) {
			padding : 50px 30px 50px;
		}

		@media(max-width : 768px) {
			padding : 35px 0px 50px;
		}

		&.active {
			display   : flex;
			flex-wrap : wrap;
		}
	}

	&__tab-subcontent-item-left {
		width : 50%;

		@media(max-width : 600px) {
			width      : 100%;
			order      : 1;
			text-align : center;
		}

		h4 {
			font-family   : $main_font;
			font-style    : normal;
			font-weight   : 400;
			font-size     : 60px;
			line-height   : 110%;
			color         : #021D59;
			margin-bottom : 20px;

			@media(max-width : 1200px) {
				font-size : 55px;
			}

			@media(max-width : 1024px) {
				font-size : 50px;
			}

			@media(max-width : 992px) {
				font-size : 45px;
			}

			@media(max-width : 768px) {
				font-size : 40px;
			}

			@media(max-width : 480px) {
				font-size     : 35px;
				margin-bottom : 10px;
			}

			@media(max-width : 390px) {
				font-size : 30px;
			}
		}

		p {
			font-family   : $second_font;
			font-style    : normal;
			font-weight   : 300;
			font-size     : 22px;
			line-height   : 143%;
			color         : #021D59;
			margin-bottom : 98px;

			@media(max-width : 1200px) {
				margin-bottom : 100px;
			}

			@media(max-width : 1024px) {
				margin-bottom : 80px;
			}

			@media(max-width : 992px) {
				margin-bottom : 60px;
			}

			@media(max-width : 768px) {
				font-size     : 20px;
				margin-bottom : 50px;
			}

			@media(max-width : 480px) {
				font-size     : 18px;
				margin-bottom : 40px;
			}

			@media(max-width : 390px) {
				font-size     : 15px;
				margin-bottom : 34px;
			}
		}


	}

	&__tab-subcontent-item-button {
		@extend .primary-btn;
	}

	&__tab-subcontent-item-right {
		width           : 50%;
		display         : flex;
		justify-content : flex-end;

		@media(max-width : 600px) {
			width           : 100%;
			margin-bottom   : 45px;
			justify-content : center;
		}


		svg, img {
			max-width : 80%;
			height    : auto !important;

			@media(max-width : 992px) {
				max-width : 90%;
			}

			@media(max-width : 768px) {
				max-width : 94%;
			}

			@media(max-width : 600px) {
				max-width : 80%;
			}
		}
	}

	&__tab-sublinks-select-wrap {
		position : relative;
		width    : 50%;
		margin   : 0 auto;
		display  : none;

		@media(max-width : 992px) {
			display : block;
		}

		@media(max-width : 768px) {
			width : 100%;
		}


		&:before {
			content             : "";
			position            : absolute;
			display             : block;
			width               : 10px;
			height              : 5px;
			background-repeat   : no-repeat;
			background-position : center center;
			top                 : 50%;
			right               : 20px;
			transform           : translateY(-50%);
			z-index             : 1;
			background-image    : url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMUw2IDZMMTEgMSIgc3Ryb2tlPSIjMDIxRDU5Ii8+PC9zdmc+);

		}
	}

	&__tab-sublinks-select {

		background    : #F2F2F2;
		border        : 1px solid #15BDFF;
		box-shadow    : 0px 4px 4px rgba(49, 197, 255, 0.25);
		border-radius : 8.19848px;
		height        : 41px;
		appearance    : none;
		padding       : 0 15px;
		width         : 100%;
	}
}