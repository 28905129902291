.partners-slider {
	//box-shadow: 0px 0px 44px rgba(34, 27, 74, 0.08);
	background-color : $white;
	padding-top      : 25px;
	padding-bottom   : 15px;

	@include break(medium) {
		padding-top : 30px;
	}
	@keyframes slide {
		from {
			transform : translateX(0);
		}
		to {
			transform : translateX(-100%);
		}
	}

	&__wrapper {
		display         : flex;
		justify-content : center;
		width           : 100%;
		overflow        : hidden;
	}

	&__slider {
		display         : flex;
		gap             : 40px;
		justify-content : center;
		align-items     : center;
		animation       : 5s slide infinite linear;

		img {
			width  : 80px;
			height : auto;
		}
	}

	&.partners-page {
		.partners-slider__title {
			font-family   : $main_font;
			font-style    : normal;
			font-weight   : 400;
			font-size     : 35px;
			line-height   : 143%;
			text-align    : center;
			color         : $dark_blue;
			margin-bottom : 20px;

			@media(max-width : 768px) {
				font-size     : 35px;
				max-width     : 420px;
				margin-left   : auto;
				margin-right  : auto;
				margin-bottom : 10px;
			}

			@media(max-width : 480px) {
				font-size : 30px;
			}
		}
	}

	&__title {
		font-style    : normal;
		font-weight   : 400;
		font-size     : 16px;
		line-height   : 1.43;
		text-align    : center;
		color         : #021D59;
		font-family   : $second_font;
		margin-bottom : 6px;
		position      : relative;
		z-index       : 100;

		@include break(medium) {
			margin-bottom : 16px;
		}

		@include break(s-small) {
			font-size     : 15px;
			margin-bottom : 22px;
		}


	}

	&__subtitle {
		font-family : $second_font;
		font-style  : normal;
		font-weight : 400;
		font-size   : 20px;
		line-height : 1.43;
		text-align  : center;
		color       : $dark_blue;
		margin      : 0 auto 65px;
		max-width   : 718px;
		position    : relative;
		z-index     : 100;

		@media(max-width : 768px) {
			font-size     : 23px;
			margin-bottom : 40px;
			max-width     : 534px;
		}

		@media(max-width : 480px) {
			font-size     : 15px;
			margin-bottom : 60px;
			max-width     : 297px;
		}
	}

	&__slider {
		.swiper-wrapper {
			display  : flex;
			position : relative;
			z-index  : 100;
			gap      : 50px;
			width    : auto;
			overflow : hidden;
		}

		&-slide {
			display : flex;
			height  : 57px;

		}
	}
}


.single-solution {
	.partners-slider__title {
		color       : $black;
		text-align  : center;
		font-family : $main_font;
		font-size   : 18px;
		font-style  : normal;
		font-weight : 400;
		line-height : 144%;
		max-width   : 270px;
		margin      : 0 auto 75px auto;
		@media screen and (min-width : 1024px) {
			font-size : 23px;
			max-width : 714px;
			margin    : 0 auto 32px auto;
		}
	}

	.partners-slider {
		padding : 40px 0 40px 0;
		@media screen and (min-width : 1024px) {
			padding : 77px 0 77px 0;
		}
	}
}