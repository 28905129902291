.big-ideas {
	position       : relative;
	margin-bottom : 225px;

	@media( max-width: 768px) {
		margin-bottom : 58px;
	}

	@media( max-width: 480px) {
		margin-bottom : 45px;
	}

	@media( max-width: 390px) {
		margin-bottom : 35px;
	}

	&:before {
		position       : absolute;
		content        : '';
		display        : none;
		left           : 0;
		top            : 0;
		width          : 100%;
		height         : 100%;
		z-index        : 1;
		background     : linear-gradient(177.82deg, #E8F5FA 9.81%, rgba(241, 250, 253, 0) 51.3%);
		transform      : matrix(1, 0, 0, -1, 0, 0);
		pointer-events : none;
	}

	&__container {
		position        : relative;
		z-index         : 2;
		display         : flex;
		justify-content : center;
		flex-wrap       : wrap;
	}

	&__caption {
		font-family   : $main_font;
		font-style    : normal;
		font-weight   : 400;
		font-size     : 35px;
		line-height   : 1.43;
		text-align    : center;
		color         : #021D59;
		margin-bottom : 5px;
		width         : 100%;

		@media( max-width: 768px) {
			font-size : 30px;
		}

		@media( max-width: 480px) {
			font-size : 28px;
		}

		@media( max-width: 390px) {
			font-size : 25px;
		}
	}

	&__sub_title {
		font-family   : $second_font;
		font-style    : normal;
		font-weight   : 400;
		font-size     : 20px;
		line-height   : 1.43;
		text-align    : center;
		color         : #021D59;
		margin-bottom : 65px;
		width         : 100%;

		@media( max-width: 768px) {
			font-size: 18px;
			margin-bottom : 48px;
		}

		@media( max-width: 480px) {
			font-size: 17px;
			margin-bottom : 35px;
		}

		@media( max-width: 390px) {
			font-size: 15px;
			margin-bottom : 29px;
		}
	}

	&__items {
		display         : flex;
		flex-wrap       : wrap;
		justify-content : center;
		align-items     : flex-start;

		&.assembled {
			position   : relative;
			min-height : 305px;
			width      : 100%;

			.big-ideas__item {
				position : relative;
				top      : 0;

				@media( max-width: 768px) {
					position: static;
					transform: none !important;
				}

				&:nth-child(1) {
					left         : 20%;
					right: auto;
					transform: translateY(17px) rotate(2.01deg);
					.big-ideas__item-content {
						opacity: 0;
						@media( max-width: 1200px){
							opacity: 1;
						}
					}
					@media( min-width: 1201px){
						margin-left  : 0;
						margin-right : 0;
					}
					@media( max-width: 1370px) {
						left : 21.5%;
						transform: translateY(14px) rotate(2.01deg);
					}
					@media( max-width: 1200px) {
						right: auto;
						left: auto;
						transform: none;
					}
				}


				&:nth-child(2) {
					right        : 20%;
					left: auto;
					transform:translateX(-15px) translateY(19px) rotate(-2.54deg);
					@media( min-width: 1201px){
						margin-left  : 0;
						margin-right : 0;
					}
					.big-ideas__item-content {
						opacity: 0;
						@media( max-width: 1200px){
							opacity: 1;
						}
					}

					@media( max-width: 1370px) {
						right: 21%;
					}

					@media( max-width: 1200px) {
						right: auto;
						left: auto;
						transform: none;
					}
				}

				&:nth-child(3) {
					transform: translateY(calc(-100% - 60px)) rotate(4.1deg);
					@media( max-width: 1200px) {
						right: auto;
						left: auto;
						transform: none;
					}
				}
			}

		}
	}

	&__item {
		width           : 535px;
		
		margin          : 0 35px 60px;
		transition      : all 0.8s ease;
		top      : 0;
		position: relative;
		@media( max-width: 1370px) {
			width: 470px;
		}

		@media( max-width: 768px) {
			margin : 0 0px 34px;
			width : 535px;
		}

		@media( max-width: 500px) {
			width : 100%;
		}
		&-wrapper{
			height          : 245px;
			background      : #FFFFFF;
			box-shadow      : 0 24px 34px rgba(28, 20, 58, 0.12);
			border-radius   : 16px;
			padding         : 12px 12px 12px 42px;
			display         : flex;
			justify-content : space-between;
			position: relative;
			width: 100%;
			
			@media( max-width: 500px) {
				height: 200px;
			}
	
			@media( max-width: 480px) {
				height : 186px;
				padding : 12px 12px 12px 22px;
			}
			@media( max-width: 390px) {
				height : 174px;
			}
			@media( max-width: 360px) {
				min-height : 174px;
				height: auto;
			}
			&::after {
				position   : absolute;
				content    : '';
				display    : block;
				width      : 17px;
				height     : 37px;
				top      : 42px;
				right: -10px;
				z-index: -1;
			}
		}
		.big-ideas__item-content {
			opacity: 1;
		}
		
		&:nth-child(1) {
			left: 0;
			.big-ideas__item-wrapper::after{
				background-image: url("data:image/svg+xml,%3Csvg width='17' height='37' viewBox='0 0 17 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.19642 31.9961C16.1964 17.1961 16.5297 5.16276 15.6964 0.996094L0.196289 9.99609C-0.470335 23.4961 0.196416 46.7961 8.19642 31.9961Z' fill='%233EB482'/%3E%3C/svg%3E%0A");
			}
			.big-ideas__item-type{
				background-color: #5DDDA3;
			}
		}

		&:nth-child(2) {
			right: 0;
			.big-ideas__item-wrapper::after{
				background-image: url("data:image/svg+xml,%3Csvg width='17' height='37' viewBox='0 0 17 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.19642 31.9961C16.1964 17.1961 16.5297 5.16276 15.6964 0.996094L0.196289 9.99609C-0.470335 23.4961 0.196416 46.7961 8.19642 31.9961Z' fill='%238828C7'/%3E%3C/svg%3E%0A");
			}
			.big-ideas__item-type{
				background-color: #B748FF;
			}
		}

		&:nth-child(3) {
			.big-ideas__item-wrapper::after{
				background-image: url("data:image/svg+xml,%3Csvg width='17' height='37' viewBox='0 0 17 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.19642 31.9961C16.1964 17.1961 16.5297 5.16276 15.6964 0.996094L0.196289 9.99609C-0.470335 23.4961 0.196416 46.7961 8.19642 31.9961Z' fill='%230B94CC'/%3E%3C/svg%3E%0A");
			}
			.big-ideas__item-type{
				background-color: #15BDFF;
			}
		}
	}

	&__item-content {
		padding-right : 70px;
		display : flex;
		flex-direction : column;

		@media( max-width: 768px) {
			opacity : 1 !important;
		}

		@media( max-width: 500px) {
			padding-right : 20px;
		}
	}

	&__item-caption {
		font-family   : $main_font;
		font-style    : normal;
		font-weight   : 400;
		font-size     : 22px;
		line-height   : 1.2;
		color         : #1C143A;
		margin-bottom : 3px;

		@media( max-width: 768px) {
			font-size: 20px;
			line-height   : 1.13;
		}

		@media( max-width: 500px) {
			font-size: 15px;
			max-width: 155px;
		}
		a{
			color         : #1C143A;
			transition: .2s linear;
			&:hover{
				color: $azure;
			}
		}
	}

	&__item-excerpt {
		font-family   : $main_font;
		font-style    : normal;
		font-weight   : 400;
		font-size     : 16px;
		line-height   : 1.59;
		color         : #1C143A;
		margin-bottom : 8px;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;  
		overflow: hidden;
		@media( max-width: 500px) {
			font-size : 12px;
			-webkit-line-clamp: 3;
		}
	}

	&__item-download {
		font-family          : $main_font;
		font-style           : normal;
		font-weight          : 400;
		font-size            : 16px;
		line-height          : 1.26;
		text-decoration-line : underline;
		color                : #15BDFF;
		margin-top : auto;
		margin-bottom : 10px;

		@media( max-width: 500px) {
			font-size: 13px;
			margin-bottom : 0;
		}

		&:hover {
			text-decoration : none;
		}
	}

	&__item-svg {
		margin-bottom : 5px;
		max-width: 100%;
		height : auto;
		flex-shrink: 0;

		@media( max-width: 480px) {
			max-width: 74%;
		}
	}

	&__item-image {
		width       : 160px;
		flex-shrink : 0;
		position    : relative;

		img {
			width : 100%;
			display: block;
			height : auto;
			box-shadow: 2px 4px 18px -3px rgba(187, 187, 187, 0.25);
			border-radius: 16px;
			@media( max-width: 480px) {
				border-radius: 10px;
			}
		}

		@media( max-width: 500px) {
			width : 130px;
		}

		@media( max-width: 480px) {
			width : 120px;
		}

		@media( max-width: 390px) {
			width : 112px;
		}
	}
	&__link{
		display: block;
		width: 100%;
		height: auto;
	}
	&__item-type {
		position            : absolute;
		top                 : 13px;
		right               : -22px;
		z-index             : 3;
		font-size: 13px;
		background-position : 0 0;
		background-repeat   : no-repeat;
		margin : 0;
		line-height : 1.0;
		color: #fff;
		padding: 9px 10px;
		border-radius: 10px;
		min-width: 75px;
		text-align: center;
		&:after {
			position   : absolute;
			content    : '';
			display    : none;
			width      : 17px;
			height     : 37px;
			right      : 16px;
			right: 0;
		}
	}

	&__more-btn {
		margin-top : 40px;

		@media( max-width: 768px) {
			margin-top : 27px;
		}
	}
}