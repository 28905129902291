.use-case{
  margin-bottom: 0;
  @include break(medium){
    margin-top: 150px;
    margin-bottom: 160;
  }
  @include break(ss-small){
    margin-top: 65px;
    margin-bottom: 60px;
  }
  &__container{
    padding-top: 115px;
    padding-bottom: 125px;
    padding-left: 70px;
    padding-right: 70px;
    border-radius: 40px;
    overflow: hidden;
    text-align: center;
    position: relative;
    z-index: 100;
    @include break(large1370){
      padding-top: 90px;
      padding-bottom: 90px;
    }
    @include break(medium){
      padding-left: 54px;
      padding-right: 54px;
    }
    @include break(ss-small){
      padding-top:40px;
      padding-bottom:40px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  &__title{
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 10px;
    color: $white;
    @include break(medium){
      margin-bottom: 15px;
    } 
    @include break(small){
      font-size: 35px;
      line-height: 41px;
    }
    @include break(ss-small){
      margin-bottom: 12px;
    }
    @include break(x-small){
      font-size: 20px;
      line-height: 29px;
    }
  }
  &__text{
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    color: $white;
    font-size: 20px;
    font-weight: 300;
    line-height: 29px;
    @include break(desktop){
      margin-bottom: 90px;
    }
    @include break(small){
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
    }
    @include break(ss-small){
      margin-bottom: 35px;
    }
    @include break(x-small){
      font-size: 13px;
      line-height: 21px;
      font-weight: 300;
    }
  }
  &__heading{
    font-size: 60px;
    font-weight: 400;
    line-height: 86px;
    color: $white;
    margin-bottom: 100px;
    @include break(desktop){
      margin-bottom: 57px;
    }
    @include break(small){
      font-size: 50px;
      line-height: 55px;
    }
    @include break(ss-small){
      margin-bottom: 40px;
    }
    @include break(x-small){
      font-size: 30px;
      line-height: 34px;
    }
  }
  &__wrapper{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 80px;
    column-gap: 80px;
    @include break(large1370){
      grid-column-gap: 40px;
      column-gap: 40px;
    }
    @include break(tablet){
      grid-column-gap: 65px;
      column-gap: 65px;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 95px;
      row-gap: 95px;
      padding-left: 26px;
      padding-right: 26px;
    }
    @include break(ss-small){
      padding-left: 12px;
      padding-right: 12px;
      grid-row-gap: 35px;
      row-gap: 35px;
      grid-column-gap: 30px;
      column-gap: 30px;
    }
  }
  &__item{
    &-image{
      max-width: 170px;
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      @include break(large1370){
        width: 150px;
      }
      @include break(small){
        margin-bottom: 15px;
      }
      @include break(ss-small){
        width: 100px;
        height: 100px;
      }
      @include break(x-small){
        width: 70px;
        height: 70px;
      }
      img, svg{
        display: block;
        width: 100%;
        height: auto;
      }
    }
    &-description{
      font-size: 18px;
      line-height: 26px;
      font-weight: 300;
      margin-bottom: 0;
      text-align: center;
      color: $white;
      @include break(x-small){
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}