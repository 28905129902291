.upcoming-events{
  margin-top: 170px;
  margin-bottom: 225px;
  @include break(large1370){
    margin-top: 120px;
    margin-bottom: 150px;
  }
  @include break(medium){
    margin-top: 90px;
    margin-bottom: 105px;
  }
  @include break(ss-small){
    margin-top: 100px;
    margin-bottom: 90px;
  }
  &__container{
    @include break(medium850){
      padding-right: 0;
      padding-left: 0;
    }
    @include break(x-small){
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  &__title{
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 5px;
    text-align: center;
    @include break(medium){
      font-size: 30px;
      line-height: 43px;
      margin-bottom: 5px;
    }
    @include break(x-small){
      font-size: 25px;
      line-height: 34px;
      margin-bottom: 7px;
    }
  }
  &__text{
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    margin-bottom: 0;
    @include break(medium){
      font-size: 18px;
      line-height: 26px;
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;
    }
    @include break(x-small){
      max-width: 265px;
      font-size: 15px;
      line-height: 21px;
    }
  }
  &__wrapper{
    margin-top: 80px;
    max-width: 1150px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    column-gap: 20px;
    @include break(large1370){
      margin-top: 50px;
    }
    @include break(medium){
      grid-column-gap: 18px;
      column-gap: 18px;
    }
    @include break(medium850){
      -webkit-overflow-scrolling: touch;
      overflow-x: scroll;
      overflow-y: hidden;
      padding-left: 40px;
      padding-right: 40px;
      &::-webkit-scrollbar-track {
        height: 0;
      }
      &::-webkit-scrollbar {
        height: 0;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
    @include break(ss-small){
      margin-top: 30px;
    }
    @include break(x-small){
      overflow: hidden;
      grid-template-columns: 1fr;
      grid-row-gap: 15px;
      row-gap: 15px;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__item{
    padding: 17px 17px 20px 17px;
    border: 1px solid #DADADA;
    border-radius: 22px;
    display: flex;
    flex-direction: column;
    @include break(tablet){
      border-radius: 18px;
      padding: 15px;
    }
    @include break(medium850){
      min-width: 325px;
    }
    @media screen and (max-width:750px){
      min-width: 290px;
    }
    @include break(ss-small){
      min-width: 260px;
    }
    @include break(x-small){
      min-width: auto;
    }
    @include break(x-small){
      border-radius: 20px;
      padding: 15px 15px 20px 15px;
    }
    &:hover img{
      transform: scale(1.1);
    }
    &-image-wrapper{
      position: relative;
      margin-bottom: 30px;
      @include break(medium){
        margin-bottom: 27px;
      }
      @include break(x-small){
        margin-bottom: 25px;
      }
    }
    &-date{
      position: absolute;
      top: 13px;
      left: -8px;
      font-size: 13px;
      line-height: 19px;
      padding: 6px 17px;
      color: $dark_blue;
      background-color: #D9FBFF;
      border-radius: 10px;
      @include break(medium){
        font-size: 11px;
        line-height: 16px;
        padding: 6px 16px;
        border-radius: 8px;
        top: 11px;
        left: -7px;
      }
      @include break(x-small){
        font-size: 12px;
        line-height: 17px;
        padding: 6px 16px;
        border-radius: 9px;
      }
      &::before{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='36' viewBox='0 0 17 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.4989 31.0664C0.498897 16.2664 0.165563 4.23307 0.998896 0.0664062L16.499 9.06641C17.1656 22.5664 16.4989 45.8664 8.4989 31.0664Z' fill='%2396D1D8'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        width: 17px;
        height: 36px;
        top: 16px;
        z-index: -1;
        @include break(medium){
          background-image: url("data:image/svg+xml,%3Csvg width='15' height='33' viewBox='0 0 15 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.39865 28.2613C0.359438 15.2388 0.0661373 4.65063 0.799389 0.984375L14.438 8.90349C15.0245 20.7822 14.4379 41.2839 7.39865 28.2613Z' fill='%2396D1D8'/%3E%3C/svg%3E%0A");
          width: 15px;
          height: 33px;
          top: 14px;
        }
      }
    }
    &-image{
      border-radius: 18px;
      overflow: hidden;
      box-shadow: 0px 10px 20px rgba(28, 20, 58, 0.2);
      overflow: hidden;
      display: block;
      width: 100%;
      isolation: isolate;
      img{
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: .3s linear;
      }
    }
    &-content{
      padding-left: 15px;
      padding-right: 15px;
      height: 100%;
      position: relative;
      padding-bottom: 24px;
      @include break(medium){
        padding-bottom: 21px;
      }
      @include break(x-small){
        padding-bottom: 23x;
      }
    }
    &-title{
      font-size: 22px;
      line-height: 31px;
      margin-bottom: 12px;
      color: $dark_blue;
      font-weight: 400;
      a{
        color: $dark_blue;
        transition: .3s linear;
        &:hover{
          color: $azure;
        }
      }
      @include break(medium){
        font-size: 19px;
        line-height: 27px;
        margin-bottom: 7px;
      }
      @include break(x-small){
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 8px;
      }
    }
    &-excerpt{
      //margin-bottom: 30px;
      margin-bottom: 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      @include break(medium){
        //margin-bottom: 25px;
        font-size: 13px;
        line-height: 19px;
      }
      @include break(x-small){
        font-size: 13px;
        line-height: 21px;
        //margin-bottom: 10px;
      }
    }
    &-more{
      position: absolute;
      left: 15px;
      bottom: 0;
      color: $azure;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      text-decoration: underline;
      transition: .3s linear;
      &:hover{
        color: $dark_blue;
      }
      @include break(medium){
        font-size: 15px;
        line-height: 21px;
      }
      @include break(x-small){
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}