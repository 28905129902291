.line-numbers{
  margin-top: 75px;
  margin-bottom: 175px;
  @include break(tablet){
    margin-top: 40px;
    margin-bottom: 100px;
  }
  @include break(ss-small){
    margin-top: 35px;
  }
  &__container{
    position: relative;
    .floating-element{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      &-1{
        background-image: url("data:image/svg+xml,%3Csvg width='133' height='133' viewBox='0 0 133 133' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='66.5391' y='-3.93934' width='99.6707' height='99.6707' rx='13.25' transform='rotate(45 66.5391 -3.93934)' stroke='%23021D59' stroke-opacity='0.3' stroke-width='1.5' stroke-dasharray='3 3 3 3'/%3E%3C/svg%3E%0A");
        width: 133px;
        height: 133px;
        bottom: 216px;
        left: -58px;
        z-index: 10;
        @include break(desktop){
          bottom: 376px;
        }
        @include break(ss-small){
         
        }
      }
      &-2{
        background-image: url("data:image/svg+xml,%3Csvg width='62' height='62' viewBox='0 0 62 62' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='31' cy='31' r='31' fill='%23E9FBF3'/%3E%3C/svg%3E%0A");
        width: 62px;
        height: 62px;
        bottom: 297px;
        left: 20px;
        z-index: 9;
        @include break(desktop){
          bottom: 457px;
        }
        @include break(ss-small){
         
        }
      }
      &-3{
        background-image: url("data:image/svg+xml,%3Csvg width='215' height='212' viewBox='0 0 215 212' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M202.848 151.07L202.848 151.07L155.855 198.836L155.855 198.836C145.592 209.276 130.449 213.46 116.119 209.838L50.5122 193.257C36.182 189.635 24.856 178.741 20.7898 164.699C20.7898 164.699 20.7898 164.699 20.7897 164.699L2.17594 100.352C-1.88521 86.2924 1.92986 71.2215 12.1872 60.8015L12.1873 60.8014L59.1801 13.0358C69.4384 2.61486 84.5863 -1.58881 98.9165 2.03303L164.523 18.6145C178.853 22.2363 190.18 33.131 194.246 47.1723C194.246 47.1723 194.246 47.1723 194.246 47.1724L212.86 111.519C216.921 125.579 213.106 140.65 202.848 151.07Z' stroke='%23B9C3DA'/%3E%3C/svg%3E%0A");
        width: 215px;
        height: 212px;
        top: -167px;
        right: -58px;
        z-index: 10;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='122' height='120' viewBox='0 0 122 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M115.14 85.6151L115.14 85.6152L88.6857 112.505L88.6856 112.505C82.9081 118.382 74.3834 120.738 66.316 118.699L29.3827 109.364C21.3155 107.325 14.9395 101.192 12.6504 93.2877L2.17176 57.0636C2.17175 57.0635 2.17174 57.0635 2.17173 57.0635C-0.114451 49.1484 2.03326 40.6643 7.80761 34.7984L7.80767 34.7984L34.2623 7.9087C34.2623 7.90868 34.2623 7.90867 34.2624 7.90865C40.0373 2.04219 48.5647 -0.324251 56.632 1.71466L93.5652 11.0492C101.632 13.0881 108.009 19.2213 110.298 27.1259L120.776 63.35C120.776 63.35 120.776 63.3501 120.776 63.3501C123.063 71.2651 120.915 79.7492 115.14 85.6151Z' stroke='%23B9C3DA' stroke-width='0.562951'/%3E%3C/svg%3E%0A");
          width: 122px;
          height: 120px;
          top: -111px;
          right: -6px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='72' height='71' viewBox='0 0 72 71' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M52.0775 66.293L52.0775 66.2931C48.6875 69.7415 43.6856 71.1237 38.9521 69.9273L17.2815 64.4503C12.548 63.2539 8.80692 59.6553 7.46381 55.0173L1.31546 33.7628C-0.0259872 29.1186 1.23418 24.1406 4.6223 20.6987L4.62233 20.6987L20.1446 4.92118C23.533 1.47901 28.5365 0.0904912 33.27 1.28683L54.9406 6.76386C59.674 7.96019 63.4152 11.5588 64.7583 16.1969L70.9067 37.4514C70.9067 37.4514 70.9067 37.4514 70.9067 37.4514C72.2481 42.0956 70.9879 47.0736 67.5998 50.5154L67.713 50.6268L67.5998 50.5155L52.0775 66.293Z' stroke='%23B9C3DA' stroke-width='0.330311'/%3E%3C/svg%3E%0A");
          width: 72px;
          height: 71px;
          right: 7px;
          top: -121px;
        }
      }
      &-4{
        background-image: url("data:image/svg+xml,%3Csvg width='76' height='80' viewBox='0 0 76 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M39.8255 2.02226L62.5247 10.9817C67.5418 12.962 71.1265 17.4534 71.9321 22.7579L75.5718 46.7712C76.3747 52.0825 74.2762 57.3994 70.0647 60.7237L51.0051 75.7774C46.791 79.1085 41.1077 79.9339 36.0907 77.9537L13.3914 68.9942C8.37437 67.014 4.78973 62.5226 3.98414 57.218L0.344398 33.2048C-0.458519 27.8935 1.64006 22.5766 5.8515 19.2523L24.9111 4.19859C29.1225 0.874276 34.8084 0.0420249 39.8255 2.02226Z' fill='%23EBFBFD'/%3E%3C/svg%3E%0A");
        z-index: 9;
        width: 76px;
        height: 80px;
        top: -9px;
        right: 84px;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='44' height='45' viewBox='0 0 44 45' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.8725 1.58735L35.6511 6.6311C38.4754 7.74588 40.4934 10.2743 40.9469 13.2605L42.9959 26.7788C43.4479 29.7688 42.2665 32.7619 39.8957 34.6334L29.1661 43.1079C26.7938 44.9831 23.5943 45.4478 20.77 44.333L7.99142 39.2893C5.16708 38.1745 3.1491 35.6461 2.69559 32.6599L0.6466 19.1416C0.194597 16.1516 1.37599 13.1585 3.74683 11.287L14.4764 2.81252C16.8473 0.941093 20.0482 0.472577 22.8725 1.58735Z' fill='%23EBFBFD'/%3E%3C/svg%3E%0A");
          width: 44px;
          height: 45px;
          top: -22px;
          right: 72px;
        }
        @include break(ss-small){
          display: none;
        }
      }
      &-5{
        background-image: url("data:image/svg+xml,%3Csvg width='157' height='157' viewBox='0 0 157 157' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' x='80.707' y='-12' width='128.454' height='127.645' rx='31' transform='rotate(46.5757 80.707 -12)' fill='%23C6EFF4'/%3E%3C/svg%3E%0A");
        width: 157px;
        height: 157px;
        bottom: 23px;
        right: -73px;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='122' height='122' viewBox='0 0 122 122' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' x='63.0508' y='-9' width='99.8329' height='99.2044' rx='24.0928' transform='rotate(46.5757 63.0508 -9)' fill='%23C6EFF4'/%3E%3C/svg%3E%0A");
          width: 122px;
          height: 122px;
          bottom: 73px;
          right: -45px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' x='26.6211' y='-4' width='42.4298' height='42.1627' rx='10.2397' transform='rotate(46.5757 26.6211 -4)' fill='%23C6EFF4'/%3E%3C/svg%3E%0A");
          width: 52px;
          height: 52px;
          bottom: 112px;
          right: 3px;
        }
      }
    }
  }
  &__item{
    position: relative;
    z-index: 1000;
    transition: 1s ease-in-out;
    &:nth-child(odd){
      transform: translate3d(-50%,0,0);
      opacity: 0;
      .line-numbers__item-text{
        padding-left: 20px;
        @include break(desktop){
          padding-left: 60px;
        }
        @include break(ss-small){
          padding-left: 35px;
        }
        &::before{
          right: 100%;
        }
      }
      .line-numbers__item-number{
        right: 100%;
      }
    }
    &:nth-child(even){
      transform: translate3d(50%,0,0);
      opacity: 0;
      .line-numbers__item-text{
        padding-right: 20px;
        @include break(desktop){
          padding-right: 60px;
        }
        @include break(ss-small){
          padding-right: 35px;
        }
        &::before{
          left: 100%;
        }
      }
      .line-numbers__item-number{
        left: 100%;
      }
    }
    &:not(:last-child){
      @include break(desktop){
        margin-bottom: 65px;
      }
      @include break(ss-small){
        margin-bottom: 65px;
      }
    }
    @include break(desktop){
      padding-bottom: 43px;
      position: relative;
    }
    @include break(ss-small){
      padding-bottom: 29px;
    }
    &-wrapper{
      margin-bottom: 0;
    }
    &-1{
      margin-bottom: 30px;

      .line-numbers__item-wrapper{
        @include break(desktop){
          text-align: right;
        }
      }
      .line-numbers__item-text{
        margin-left: 590px;
        @include break(desktop){
          right: 0;
        }
      }
      .line-numbers__item-number{
        color: #DFFAFE;
        -webkit-text-stroke: 2px #55BBF9;
      }
    }
    &-2{
      margin-bottom: 55px;
      .line-numbers__item-text{
        margin-left: 70px;
        @include break(desktop){
          left: 0;
        }
      }
      .line-numbers__item-number{
        color: #E4FFF4;
        -webkit-text-stroke: 2px #80DAA7;
      }
    }
    &-3{
      .line-numbers__item-wrapper{
        @include break(desktop){
          text-align: right;
        }
      }
      .line-numbers__item-text{
        margin-left: 710px;
        @include break(desktop){
          right: 0;
        }
      }

      .line-numbers__item-number{
        color: #F3E7FD;
        -webkit-text-stroke: 2px #A956F6;
      }
    }
    &:nth-child(odd),
    &:nth-child(even),
    &-1, &-2, &-3{
      &.animate{
        transform: translate3d(0,0,0);
        opacity: 1;
      }
      @include break(desktop){
        .line-numbers__item-number{
          right: auto;
          left: auto;
  
        }
        .line-numbers__item-text{
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    &-text{
      font-size: 40px;
      line-height: 57px;
      position: relative;
      display: inline-block;
      padding-top: 195px;
      @include break(desktop){
        padding-top: 176px;
        font-size: 30px;
        line-height: 43px;
        padding-top: 0;
        position: absolute;
        bottom: 0;
        margin-right: 0;
        margin-left: 0;
      }
      @include break(ss-small){
        font-size: 20px;
        line-height: 29px;
      }
      &::before{
        content: '';
        display: block;
        width: 100vw;
        height: 1px;
        background-color: $dark_blue;
        position: absolute;
        bottom: 15px;
        @include break(desktop){
          bottom: 19px;
        }
        @include break(ss-small){
          bottom: 13px;
        }
      }
    }
    &-number{
      font-family: $kanit;
      font-weight: 700;
      font-size: 228px;
      line-height: 251px;
      letter-spacing: -0.025em;
      position: absolute;
      bottom: 0;
      &-mobile{
        display: none;
        @include break(desktop){
          display: block;
        }
      }
      &-desktop{
        @include break(desktop){
          display: none;
        }
      }
      @include break(desktop){
        font-size: 160px;
        line-height: 176px;
        position: relative;
        bottom: auto;
        text-align: center;
      }
      @include break(ss-small){
        font-size: 105px;
        line-height: 115px;
        margin-bottom: 18px;
      }
      @include break(small-360){
        font-size: 27vw;
        line-height: 1;
      }
    }
  }
}