.resources-video{ 
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 75px;
  margin-bottom: 85px;
  @include break(tablet){
    margin-top: 35px;
    margin-bottom: 50px;
  }
  @include break(ss-small){
    margin-top: 35px;
    margin-bottom: 45px;
  }
  &__video{
    &-wrapper{
      margin-top: 40px;
      position: relative;
      @include break(medium){
        margin-top: 55px;
      }
      @include break(ss-small){
        margin-top: 40px;
      }
      .video-wrapper__iframe{
        width: 100%;
        height: 400px;
        position: relative;
        z-index: 20;
        @include break(x-small){
          height: auto;
          min-height: 200px;
        }
      }
    }
    &-creator{
      padding: 0;
      width: 100%;
      box-shadow: none;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      z-index: 20;
      @include break(medium){
        border-radius: 15px;
      }
      img.resources-video__video-preview{
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover;
        @include break(medium){
          height: 350px;
        }
        @include break(x-small){
          height: 190px;
        }
      }
    }    
    &-play{
      width: 66px;
      height: 74px;
      opacity: 0.5;
      transition: .3s linear;
      @include break(ss-small){
        width: 36px;
        height: 39px;
      }
      &:hover{
        opacity: 1;
        scale: 1.1;
      }
    }
  }
}
