.site-header {
  background-color: $white;
  position: fixed;
  box-shadow: 0px 0px 44px rgba(34, 27, 74, 0.08);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 8000;
  &__container{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include break(ss-small){
      height: 50px;
    }
  }
  &__logo{
    display: flex;
    align-items: center;
    &-img{
      display: block;
      height: auto;
      @include break(medium){
        width: 162px;
      }
      @include break(ss-small){
        width: 106px;
      }
    }
  }
  &__right-section{
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__nav{
    height: 100%;
    margin-right: 95px;
    @include break(large1370){
      margin-right: 40px;
    }
    @include break(desktop){
      margin-right: 20px;
    }
    @include break(medium) {
      display: none;
    }
  }
  &__menu{
    list-style: none;
    display: flex;
    align-items: center;
    height: 100%;
    a{
      text-decoration: none;
    }
    & > .menu-item{
      margin: 0 20px;
      height: 100%;
      display: flex;
      align-items: center;
      @include break(large1370){
        margin: 0 10px;
      }
      @include break(desktop){
        margin: 0 7px;
      }
      &.current-menu-ancestor,
      &.current-menu-item{
        & > a, & > .nav__link{
          font-weight: 700;
        }
      }
      &.menu-item-has-children{
        position: relative;
        &:hover{
          > .sub-menu{
            visibility: visible;
            opacity: 1;
          }
        }
        & > .like-link,
        & > a{
          position: relative;
          padding-right: 10px;
          &:hover::after{
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.75001 6.16581C4.41668 6.74316 3.58335 6.74316 3.25001 6.16581L1.00912 2.28447C0.628606 1.6254 1.22219 0.831226 1.96204 1.00954L3.79708 1.45183C3.93045 1.48397 4.06955 1.48397 4.20292 1.45183L6.03799 1.00954C6.77783 0.831224 7.37142 1.6254 6.9909 2.28447L4.75001 6.16581Z' fill='%2315BDFF'/%3E%3C/svg%3E%0A");
          }
          &::after{
            content: '';
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.75001 6.16581C4.41668 6.74316 3.58335 6.74316 3.25001 6.16581L1.00912 2.28447C0.628606 1.6254 1.22219 0.831226 1.96204 1.00954L3.79708 1.45183C3.93045 1.48397 4.06955 1.48397 4.20292 1.45183L6.03799 1.00954C6.77783 0.831224 7.37142 1.6254 6.9909 2.28447L4.75001 6.16581Z' fill='%23021D59'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            width: 8px;
            height: 7px;
            position: absolute;
            right: 0;
            top: 8px;
            transition: .3s linear;
          }
        }
        .sub-menu{
          position: absolute;
          left: -20px;
          top: 60px;
          background-color: $white;
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
          border-radius: 20px;
          padding: 12px 11px;
          min-width: 280px;
          visibility: hidden;
          opacity: 0;
          transition: .3s ease-in-out;
          .menu-item{
            &:not(:last-child){
              margin-bottom: 4px;
            } 
            &.current-menu-item{
              a, .nav__link{
                background-color: rgba($color: $azure, $alpha: .2);
                font-weight: 700;
              }
            }
            &:hover {
              > a {
                background-color: rgba($color: $azure, $alpha: .2);
              }
              > .sub-menu {
                visibility: visible;
                opacity: 1;
              }
            }
            .nav__link,
            a{
              padding: 10px 20px;
              border-radius: 20px;
              display: block;
              transition: .3s ease-in-out;
            }
            
            &.menu-item-has-children {
              position: relative;
              
              > a::after {
                content: '';
                display: block;
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.75001 6.16581C4.41668 6.74316 3.58335 6.74316 3.25001 6.16581L1.00912 2.28447C0.628606 1.6254 1.22219 0.831226 1.96204 1.00954L3.79708 1.45183C3.93045 1.48397 4.06955 1.48397 4.20292 1.45183L6.03799 1.00954C6.77783 0.831224 7.37142 1.6254 6.9909 2.28447L4.75001 6.16581Z' fill='%23021D59'/%3E%3C/svg%3E%0A");
                background-position: center;
                background-repeat: no-repeat;
                width: 8px;
                height: 7px;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%) rotate(-90deg);
                transition: .3s linear;
              }
              
              &:hover > a::after {
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.75001 6.16581C4.41668 6.74316 3.58335 6.74316 3.25001 6.16581L1.00912 2.28447C0.628606 1.6254 1.22219 0.831226 1.96204 1.00954L3.79708 1.45183C3.93045 1.48397 4.06955 1.48397 4.20292 1.45183L6.03799 1.00954C6.77783 0.831224 7.37142 1.6254 6.9909 2.28447L4.75001 6.16581Z' fill='%2315BDFF'/%3E%3C/svg%3E%0A");
              }
            }
          }
          
          .sub-menu {
            position: absolute;
            top: 0;
            left: 100%;
            margin-left: 1px;
            min-width: 280px;
            background-color: $white;
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            padding: 12px 11px;
            visibility: hidden;
            opacity: 0;
            transition: .3s ease-in-out;
          }
        }
      }
      & > a, & > .nav__link{
        line-height: 21px;
        transition: .3s linear;
        &:hover{
          color: $azure;
        }
      }
    }
    .nav__link,
    a{
      font-size: 16px;
      color: $dark_blue;
      line-height: 18px;
    }
  }
  &__right-btn-link{
    @extend .azure-btn;
    font-size: 16px;
    line-height: 21px;
    padding: 7px 14px;
    @include break(medium) {
      display: none;
    }
  }
  &__right-icon-link{
    display: block;
    margin-left: 40px;
    width: 33px;
    height: 33px;
    transition: .3s linear;
    &:hover{
      transform: scale(1.1);
    }
    @include break(large1370){
      margin-left: 20px;
    }
    @include break(medium) {
      display: none;
    }
  }
  &__mobile-btn{
    display: none;
    width: 31px;
    height: 20px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    background-image: url("data:image/svg+xml,%3Csvg width='31' height='20' viewBox='0 0 31 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='31' height='3' rx='1.5' fill='%23021D59'/%3E%3Crect y='8.30078' width='31' height='3' rx='1.5' fill='%23021D59'/%3E%3Crect y='16.5996' width='31' height='3' rx='1.5' fill='%23021D59'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    @include break(medium) {
      display: block;
    }
    @include break(ss-small){
      width: 20px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.359375' width='19.6397' height='2.23814' rx='1.11907' fill='%23021D59'/%3E%3Crect x='0.359375' y='6.71484' width='19.6397' height='2.23814' rx='1.11907' fill='%23021D59'/%3E%3Crect x='0.359375' y='13.4297' width='19.6397' height='2.23814' rx='1.11907' fill='%23021D59'/%3E%3C/svg%3E%0A");
    }
  }
}