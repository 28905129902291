.platform-description {
	padding-top : 62px;
	padding-bottom : 47px;

	@media( max-width: 768px) {
		padding-top : 35px;
		padding-bottom : 70px;
	}

	@media( max-width: 480px) {
		padding-top : 20px;
		padding-bottom : 49px;
	}

	&__container {
		display : flex;
		flex-wrap: wrap;
	}

	&__text {
		padding-top : 25px;
		width : 50%;
		padding-left : 86px;

		@media( max-width: 992px) {
			padding-left : 30px;
		}

		@media( max-width: 600px) {
			padding-left : 0;
			width : 100%;
			order : 1;
			text-align : center;
			padding-top : 29px;
		}
	}

	&__image {
		width : 50%;
		display : flex;
		justify-content : flex-end;
		padding-right : 43px;

		@media( max-width: 992px) {
			padding-right : 0;
			padding-right : 0;
		}

		@media( max-width: 600px) {
			width : 100%;
			text-align : center;
			justify-content : center;
		}

		img, svg {
			max-width : 90%;
			height: auto;
		}
	}

	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 60px;
		line-height: 1.10;
		color: $dark_blue;
		margin-bottom : 12px;
		max-width : 470px;

		@media( max-width: 1024px) {
			font-size: 55px;
		}

		@media( max-width: 992px) {
			font-size: 45px;
		}

		@media( max-width: 768px) {
			font-size: 35px;
			margin-bottom : 21px;
			max-width : 290px;
		}

		@media( max-width: 600px) {
			margin-left : auto;
			margin-right : auto;
		}

		@media( max-width: 480px) {
			font-size: 30px;
			text-align : center;
			max-width: 80%;
			margin-bottom : 21px;
		}
	}

	&__content {
		p {
			font-family: $second_font;
			font-style: normal;
			font-weight: 300;
			font-size: 22px;
			line-height: 1.43;
			color: $dark_blue;
			margin-bottom : 0;
			max-width : 510px;

			@media( max-width: 1024px) {
				font-size: 20px;
			}

			@media( max-width: 992px) {
				font-size: 18px;
			}

			@media( max-width: 768px) {
				font-size: 16px;
			}
		}
	}


}