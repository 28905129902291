.reviews-block{
  margin-top: 220px;
  margin-bottom: 0;
  @include break(desktop){
    margin-top: 120px;
  }
  @include break(medium){
    margin-bottom: 150px;
  }
  @include break(ss-small){
    margin-top: 90px;
    margin-bottom: 60px;
  }
  &.style-2{
    margin-top: 180px;
    margin-bottom: 80px;
    @include break(medium){
      
    }
    @include break(ss-small){
      margin-top: 75px;
      margin-bottom: 70px;
    }
  }
  &__container{

  }
  &__wrapper{
    padding-left: 313px;
    max-width: 955px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 85px;
    position: relative;
    z-index: 100;
    padding-top: 11px;
    margin-top: -11px;
    @include break(medium){
      padding-left: 0;
      padding-top: 0;
      margin-top: 0;
      padding-top: 280px;
      padding-bottom: 0;
      overflow: visible;
    }
    @include break(ss-small){
      padding-top: 140px;
    }
    &.style-2{
      @include break(medium){
        padding-top: 275px;
      }
      @include break(ss-small){
        padding-top: 160px;
      }
    }
    .floating-element{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
      &-1{
        
      }
      &-2{
       
      }
      &-3{
        
      }
    }
    .floating-element-wrapper{
      &.style-1{
        .floating-element{
          &-1{
            background-image: url("data:image/svg+xml,%3Csvg width='279' height='269' viewBox='0 0 279 269' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M80.2052 24.3353L25.3998 77.2374C-7.40582 108.904 -7.40582 160.245 25.3998 191.911L80.2052 244.813C113.011 276.479 166.199 276.479 199.005 244.813L253.81 191.911C286.616 160.245 286.616 108.904 253.81 77.2374L199.005 24.3353C166.199 -7.33091 113.011 -7.3309 80.2052 24.3353Z' fill='%2315BDFF'/%3E%3C/svg%3E%0A");
            width: 279px;
            height: 269px;
            top: 0;
            left: 0;
            &.animate{
              animation-name: moveDown1;
              animation-duration: 0.8s;
              animation-timing-function: ease-in-out;
              @include break(medium){
                animation-name: none;
              }
            }
            @include break(medium){
              background-image: url("data:image/svg+xml,%3Csvg width='224' height='217' viewBox='0 0 224 217' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M64.6629 20.0186L20.6752 62.4786C-5.65509 87.8944 -5.65509 129.102 20.6752 154.517L64.6629 196.978C90.9932 222.393 133.683 222.393 160.013 196.978L204.001 154.517C230.331 129.102 230.331 87.8944 204.001 62.4786L160.013 20.0186C133.683 -5.39727 90.9932 -5.39727 64.6629 20.0186Z' fill='%2315BDFF'/%3E%3C/svg%3E%0A");
              width: 224px;
              height: 217px;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
            }
            @include break(ss-small){
              background-image: url("data:image/svg+xml,%3Csvg width='124' height='120' viewBox='0 0 124 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M35.5037 11.3558L11.2677 34.7501C-3.23955 48.7535 -3.23955 71.4575 11.2677 85.4609L35.5037 108.855C50.0109 122.859 73.5318 122.859 88.0391 108.855L112.275 85.4609C126.782 71.4575 126.782 48.7535 112.275 34.7501L88.0391 11.3558C73.5318 -2.64765 50.0109 -2.64764 35.5037 11.3558Z' fill='%2315BDFF'/%3E%3C/svg%3E%0A");
              width: 124px;
              height: 120px;
            }    
          }
          &-2{
            background-image: url("data:image/svg+xml,%3Csvg width='230' height='205' viewBox='0 0 230 205' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M212.96 151.217L176.362 186.602C153.296 210.012 114.949 210.922 90.7103 188.644L19.475 136.42C-4.76345 114.142 -5.70531 77.1047 17.3606 53.6941L53.9586 18.309C77.0245 -5.10164 115.372 -6.01135 139.61 16.2668L210.845 68.4905C235.084 90.7686 236.026 127.806 212.96 151.217Z' fill='%234B96FF'/%3E%3C/svg%3E%0A");
            width: 230px;
            height: 205px;
            top: 230px;
            left: 20px;
            &.animate{
              animation-name: moveDown2;
              animation-duration: 0.8s;
              animation-timing-function: ease-in-out;
              @include break(medium){
                animation-name: moveCenter1;
              }
              @include break(ss-small){
                animation-name: moveCenter1Mobile;
              }
            }
            @include break(medium){
              background-image: url("data:image/svg+xml,%3Csvg width='164' height='154' viewBox='0 0 164 154' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M144.495 127.522L112.633 146.751C92.3737 159.658 64.7896 154.11 51.0197 134.367L8.42555 85.7557C-5.34433 66.0121 -0.0776466 39.5453 20.1815 26.6382L52.0438 7.40922C72.303 -5.49787 99.8872 0.0500663 113.657 19.7937L156.251 68.4048C170.021 88.1484 164.754 114.615 144.495 127.522Z' fill='%234B96FF'/%3E%3C/svg%3E%0A");
              width: 164px;
              height: 154px;
              top: 45px;
              left: 50%;
              transform: translateX(-50%);
              margin-left: -180px;
            }
            @include break(ss-small){
              background-image: url("data:image/svg+xml,%3Csvg width='91' height='86' viewBox='0 0 91 86' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M79.4282 70.833L61.873 81.4277C50.7107 88.5391 35.5126 85.4823 27.9258 74.6042L4.45763 47.8208C-3.12919 36.9426 -0.227393 22.3602 10.9348 15.2487L28.4901 4.65409C39.6523 -2.45735 54.8504 0.599406 62.4372 11.4776L85.9054 38.2609C93.4922 49.1391 90.5904 63.7216 79.4282 70.833Z' fill='%234B96FF'/%3E%3C/svg%3E%0A");
              width: 91px;
              height: 86px;
              top: 24px;
              margin-left: -97px;
            }
          }
          &-3{
            background-image: url("data:image/svg+xml,%3Csvg width='181' height='142' viewBox='0 0 181 142' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M172.109 110.783L153.684 128.58C140.329 143.674 116.759 145.507 101.052 132.673L13.1506 81.2993C-2.55638 68.4651 -4.46434 45.8156 8.89138 30.722L27.3157 12.9246C40.6714 -2.16896 64.2415 -4.00239 79.9484 8.83175L167.849 60.2053C183.556 73.0395 185.464 95.689 172.109 110.783Z' fill='%23816FFF'/%3E%3C/svg%3E%0A");
            width: 181px;
            height: 142px;
            top: 455px;
            left: 44px;
            &.animate{
              animation-duration: 0.8s;
              animation-timing-function: ease-in-out;
              @include break(medium){
                animation-name: moveCenter2;
              }
              @include break(ss-small){
                animation-name: moveCenter2Mobile;
              }
            }
            @include break(medium){
              background-image: url("data:image/svg+xml,%3Csvg width='122' height='116' viewBox='0 0 122 116' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M108.603 103.801L91.8053 111.85C79.1398 119.19 62.3227 115.115 54.2524 102.751L4.52519 47.5917C-3.54507 35.2267 0.180971 19.2456 12.8465 11.9057L29.6439 3.85641C42.3094 -3.48351 59.1265 0.591137 67.1968 12.9561L116.924 68.1149C124.994 80.4799 121.268 96.461 108.603 103.801V103.801Z' fill='%23816FFF'/%3E%3C/svg%3E%0A");
              width: 122px;
              height: 116px;
              top: 75px;
              left: 50%;
              transform: translateX(-50%);
              margin-left: -335px;
            }
            @include break(ss-small){
              background-image: url("data:image/svg+xml,%3Csvg width='67' height='64' viewBox='0 0 67 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M59.7545 57.2521L50.4996 61.687C43.5213 65.7311 34.2555 63.4861 29.809 56.6733L2.41074 26.2824C-2.03575 19.4696 0.0171919 10.6645 6.99554 6.6204L16.2504 2.18547C23.2288 -1.85863 32.4945 0.386391 36.941 7.19915L64.3393 37.5901C68.7858 44.4029 66.7329 53.208 59.7545 57.2521V57.2521Z' fill='%23816FFF'/%3E%3C/svg%3E%0A");
              width: 67px;
              height: 64px;
              top: 40px;
              margin-left: -180px;
            }
          }
        }
      }
      &.style-2{
        .floating-element{
          &-1{
            background-image: url("data:image/svg+xml,%3Csvg width='210' height='210' viewBox='0 0 210 210' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M111.693 3.36132L174.294 27.7321C188.133 33.1168 197.925 45.1853 199.992 59.3937L209.321 123.66C211.38 137.863 205.4 152.047 193.621 160.866L140.349 200.761C128.57 209.58 112.797 211.695 98.9588 206.311L36.3574 181.94C22.519 176.555 12.7268 164.487 10.6594 150.278L1.33043 86.0118C-0.728834 71.8084 5.25127 57.6247 17.0305 48.8061L70.3029 8.91054C82.0821 0.0918848 97.8544 -2.02337 111.693 3.36132Z' fill='%23816FFF'/%3E%3C/svg%3E%0A");
            width: 210px;
            height: 210px;
            left: 50px;
            top: 0;
            &.animate{
              animation-name: moveDown1;
              animation-duration: 0.8s;
              animation-timing-function: ease-in-out;
              @include break(medium){
                animation-name: none;
              }
            }
            @include break(medium){
              background-image: url("data:image/svg+xml,%3Csvg width='223' height='223' viewBox='0 0 223 223' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M219.126 118.322L193.238 184.82C187.518 199.52 174.698 209.922 159.605 212.118L91.3383 222.028C76.2508 224.215 61.1841 217.863 51.8164 205.35L9.43726 148.762C0.0696125 136.249 -2.17732 119.495 3.54259 104.795L29.4306 38.2965C35.1505 23.5965 47.9702 13.1947 63.0632 10.9986L131.33 1.08894C146.418 -1.09853 161.485 5.25386 170.852 17.7663L213.231 74.3551C222.599 86.8676 224.846 103.622 219.126 118.322Z' fill='%23816FFF'/%3E%3C/svg%3E%0A");
              width: 223px;
              height: 223px;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
            }
            @include break(ss-small){
              background-image: url("data:image/svg+xml,%3Csvg width='129' height='129' viewBox='0 0 129 129' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M126.362 68.4292L111.485 106.644C108.198 115.091 100.831 121.069 92.1571 122.331L52.9262 128.026C44.2558 129.283 35.5975 125.632 30.2142 118.442L5.86026 85.922C0.476977 78.7314 -0.814263 69.1033 2.47279 60.6557L17.3498 22.4412C20.6368 13.9936 28.0039 8.01598 36.6774 6.75395L75.9083 1.05917C84.5786 -0.197893 93.237 3.45262 98.6203 10.6431L122.974 43.1629C128.358 50.3534 129.649 59.9816 126.362 68.4292Z' fill='%23816FFF'/%3E%3C/svg%3E%0A");
              width: 129px;
              height: 129px;
            }    
          }
          &-2{
            background-image: url("data:image/svg+xml,%3Csvg width='170' height='134' viewBox='0 0 170 134' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.48367 81.1772C0.497364 73.1278 -1.50586 61.5798 1.84791 53.1581L2.68662 50.9737C2.80997 50.2631 17.9394 13.8556 18.7493 14.0782C22.112 5.66222 33.772 1.13084 44.1159 0.771512L95.9273 0.490198C106.276 0.123652 120.166 5.74805 127.152 13.7974L146.683 33.9764C147.502 34.2047 162.81 51.945 162.687 52.6555C169.673 60.7049 171.676 72.2528 168.323 80.6746L167.484 82.859C167.36 83.5695 152.231 119.977 151.421 119.755C148.058 128.17 136.398 132.702 126.055 133.061L74.2431 133.342C63.8941 133.709 50.0045 128.085 43.0182 120.035L23.4874 99.8563C22.6687 99.6279 7.36031 81.8877 7.48367 81.1772Z' fill='%234B96FF'/%3E%3C/svg%3E%0A");
            width: 170px;
            height: 134px;
            top: 200px;
            left: 70px;
            &.animate{
              animation-name: moveDown2;
              animation-duration: 0.8s;
              animation-timing-function: ease-in-out;
              @include break(medium){
                animation-name: moveCenter1Style2;
              }
              @include break(ss-small){
                animation-name: moveCenter1MobileStyle2;
              }
            }
            @include break(medium){
              background-image: url("data:image/svg+xml,%3Csvg width='142' height='181' viewBox='0 0 142 181' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M56.0109 8.43731C64.5614 1.01609 76.8283 -1.11184 85.7743 2.45071L88.0947 3.34164C88.8494 3.47267 127.523 19.544 127.287 20.4042C136.227 23.9763 141.04 36.3622 141.422 47.3501L141.721 102.387C142.11 113.38 136.136 128.134 127.585 135.556L106.15 156.302C105.908 157.172 87.0629 173.433 86.3082 173.302C77.7577 180.724 65.4908 182.851 56.5448 179.289L54.2244 178.398C53.4697 178.267 14.7956 162.196 15.032 161.335C6.09216 157.763 1.27869 145.377 0.896991 134.39L0.598163 79.3528C0.208796 68.3595 6.18335 53.6052 14.7338 46.184L36.1689 25.4373C36.4115 24.5676 55.2561 8.30628 56.0109 8.43731Z' fill='%234B96FF'/%3E%3C/svg%3E%0A");
              width: 142px;
              height: 181px;
              top: 16px;
              left: 50%;
              transform: translateX(-50%);
              margin-left: -175px;
            }
            @include break(ss-small){
              background-image: url("data:image/svg+xml,%3Csvg width='83' height='104' viewBox='0 0 83 104' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M32.7657 4.75465C37.6794 0.489907 44.7288 -0.732948 49.8698 1.31434L51.2033 1.82632C51.637 1.90162 73.8617 11.1373 73.7259 11.6316C78.8633 13.6844 81.6295 20.8022 81.8488 27.1166L82.0205 58.7445C82.2443 65.062 78.8109 73.5408 73.8972 77.8055L61.5792 89.7279C61.4397 90.2277 50.6103 99.5726 50.1766 99.4973C45.2629 103.762 38.2135 104.985 33.0725 102.938L31.7391 102.426C31.3054 102.35 9.08063 93.1147 9.21648 92.6203C4.07902 90.5676 1.31288 83.4498 1.09353 77.1354L0.921804 45.5075C0.698047 39.19 4.13143 30.7112 9.04513 26.4465L21.3632 14.524C21.5026 14.0242 32.332 4.67935 32.7657 4.75465Z' fill='%234B96FF'/%3E%3C/svg%3E%0A");
              width: 83px;
              height: 104px;
              top: 8px;
              margin-left: -95px;
            }    
          }
          &-3{
            background-image: url("data:image/svg+xml,%3Csvg width='101' height='83' viewBox='0 0 101 83' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.2795 35.0299C-0.312099 30.5875 2.21502 23.1711 5.4224 19.624L6.33759 18.5451C7.11969 17.287 22.5396 1.6852 23.8492 2.43651C27.0566 -1.11053 37.1707 0.213019 41.9697 1.10835L71.7399 9.74522C76.5389 10.6405 85.6483 16.8724 87.2458 21.322L95.3726 34.4893C96.6823 35.2406 100.481 46.2433 99.6988 47.5014C101.29 51.9437 98.7633 59.3602 95.5559 62.9073L94.6407 63.9862C93.8586 65.2443 78.4388 80.8461 77.1291 80.0947C73.9217 83.6418 63.8076 82.3183 59.0086 81.4229L29.2384 72.7861C24.4394 71.8907 15.33 65.6589 13.7325 61.2093L5.60574 48.042C4.29606 47.2907 0.497397 36.288 1.2795 35.0299Z' fill='%2355BBF9'/%3E%3C/svg%3E%0A");
            width: 101px;
            height: 83px;
            top: 361px;
            left: 105px;
            &.animate{
              animation-duration: 0.8s;
              animation-timing-function: ease-in-out;
              @include break(medium){
                animation-name: moveCenter2Style2;
              }
              @include break(ss-small){
                animation-name: moveCenter2MobileStyle2;
              }
            }
            @include break(medium){
              background-image: url("data:image/svg+xml,%3Csvg width='89' height='106' viewBox='0 0 89 106' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.2028 0.794991C55.9217 -0.89569 63.7999 1.78875 67.5677 5.1958L68.7138 6.16796C70.0502 6.99875 86.6233 23.3786 85.8252 24.7698C89.5931 28.1768 88.1871 38.9206 87.2361 44.0183L78.0615 75.6418C77.1105 80.7395 70.4907 90.416 65.7641 92.1129L51.7771 100.746C50.979 102.137 39.2914 106.172 37.955 105.341C33.236 107.032 25.3579 104.347 21.59 100.94L20.4439 99.9682C19.1075 99.1374 2.53447 82.7577 3.33256 81.3665C-0.435301 77.9594 0.970604 67.2156 1.92167 62.1179L11.0962 30.4944C12.0473 25.3966 18.667 15.7202 23.3936 14.0233L37.3806 5.39055C38.1787 3.99934 49.8664 -0.0358012 51.2028 0.794991Z' fill='%2355BBF9'/%3E%3C/svg%3E%0A");
              width: 89px;
              height: 106px;
              top: 55px;
              left: 50%;
              transform: translateX(-50%);
              margin-left: -315px;
            }
            @include break(ss-small){
              background-image: url("data:image/svg+xml,%3Csvg width='51' height='61' viewBox='0 0 51 61' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M29.4979 0.330335C32.2097 -0.641246 36.7371 0.901417 38.9023 2.85934L39.5609 3.41801C40.3289 3.89544 49.853 13.3084 49.3943 14.1079C51.5596 16.0658 50.7516 22.2399 50.2051 25.1694L44.9328 43.3424C44.3862 46.2719 40.5821 51.8327 37.8658 52.8078L29.8279 57.7688C29.3693 58.5683 22.6528 60.8871 21.8848 60.4097C19.173 61.3813 14.6457 59.8386 12.4804 57.8807L11.8218 57.322C11.0538 56.8446 1.52979 47.4317 1.98842 46.6322C-0.176848 44.6743 0.631082 38.5001 1.17763 35.5706L6.44995 17.3976C6.9965 14.4681 10.8007 8.90737 13.5169 7.93221L21.5548 2.97125C22.0134 2.17177 28.7299 -0.147095 29.4979 0.330335Z' fill='%2355BBF9'/%3E%3C/svg%3E%0A");
              width: 51px;
              height: 61px;
              top: 30px;
              margin-left: -180px;
            }    
          }
        }
      }
    }
  }
  &__slider{
    position: relative;
    &.swiper{
      padding-top: 34px;
      padding-left: 22px;
      @include break(medium){
        padding-left: 0;
        padding-top: 19px;
      }
      @include break(ss-small){
        padding-top: 24px;
      }
    }
    &::before{
      content: '';
      display: block;
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='88' height='70' viewBox='0 0 88 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.49666 69.8961C1.50992 69.8961 0 68.3067 0 66.3995V25.1548C0 11.8039 8.98005 0.996094 20.821 0.996094H32.6619C34.6487 0.996094 36.1586 2.58548 36.1586 4.49275V16.0158C36.1586 18.0026 34.5692 19.5125 32.6619 19.5125H30.3573C24.0792 19.5125 19.7084 22.2144 19.7084 26.9826V30.7177C19.7084 32.7044 21.2978 34.2143 23.2051 34.2143H32.5825C34.5692 34.2143 36.0791 35.8037 36.0791 37.711V66.2405C36.0791 68.2273 34.4897 69.7372 32.5825 69.7372H3.49666V69.8961ZM54.8339 69.8961C52.8472 69.8961 51.3373 68.3067 51.3373 66.3995V25.1548C51.3373 11.8039 60.3173 0.996094 72.1583 0.996094H83.9992C85.986 0.996094 87.4959 2.58548 87.4959 4.49275V16.0158C87.4959 18.0026 85.9065 19.5125 83.9992 19.5125H81.6946C75.4165 19.5125 71.0457 22.2144 71.0457 26.9826V30.7177C71.0457 32.7044 72.6351 34.2143 74.5424 34.2143H83.9198C85.9065 34.2143 87.4164 35.8037 87.4164 37.711V66.2405C87.4164 68.2273 85.827 69.7372 83.9198 69.7372H54.8339V69.8961Z' fill='%23E6F5FB'/%3E%3C/svg%3E%0A");
      width: 88px;
      height: 70px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      @include break(medium){
        background-image: url("data:image/svg+xml,%3Csvg width='166' height='130' viewBox='0 0 166 130' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.59747 130C2.84891 130 0 127.001 0 123.403V45.5825C0 20.3922 16.9435 0 39.2849 0H61.6263C65.3749 0 68.2238 2.99885 68.2238 6.59746V28.3391C68.2238 32.0877 65.2249 34.9366 61.6263 34.9366H57.278C45.4325 34.9366 37.1857 40.0346 37.1857 49.0311V56.0784C37.1857 59.827 40.1845 62.6759 43.7832 62.6759H61.4764C65.2249 62.6759 68.0738 65.6747 68.0738 69.2734V123.103C68.0738 126.851 65.075 129.7 61.4764 129.7H6.59747V130ZM103.46 130C99.7117 130 96.8627 127.001 96.8627 123.403V45.5825C96.8627 20.3922 113.806 0 136.148 0H158.489C162.238 0 165.087 2.99885 165.087 6.59746V28.3391C165.087 32.0877 162.088 34.9366 158.489 34.9366H154.141C142.295 34.9366 134.048 40.0346 134.048 49.0311V56.0784C134.048 59.827 137.047 62.6759 140.646 62.6759H158.339C162.088 62.6759 164.937 65.6747 164.937 69.2734V123.103C164.937 126.851 161.938 129.7 158.339 129.7H103.46V130Z' fill='%23E6F5FB'/%3E%3C/svg%3E%0A");
        width: 166px;
        height: 130px;
        left: 50%;
        transform: translateX(-50%);
      }
      @include break(ss-small){
        background-image: url("data:image/svg+xml,%3Csvg width='126' height='99' viewBox='0 0 126 99' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.02422 99C2.16955 99 0 96.7163 0 93.9758V34.7128C0 15.5294 12.9031 0 29.917 0H46.9308C49.7855 0 51.955 2.28374 51.955 5.02422V21.5813C51.955 24.436 49.6713 26.6055 46.9308 26.6055H43.6194C34.5986 26.6055 28.3183 30.4879 28.3183 37.3391V42.7059C28.3183 45.5606 30.6021 47.7301 33.3426 47.7301H46.8166C49.6713 47.7301 51.8408 50.0138 51.8408 52.7543V93.7474C51.8408 96.6021 49.5571 98.7716 46.8166 98.7716H5.02422V99ZM78.7889 99C75.9343 99 73.7647 96.7163 73.7647 93.9758V34.7128C73.7647 15.5294 86.6678 0 103.682 0H120.696C123.55 0 125.72 2.28374 125.72 5.02422V21.5813C125.72 24.436 123.436 26.6055 120.696 26.6055H117.384C108.363 26.6055 102.083 30.4879 102.083 37.3391V42.7059C102.083 45.5606 104.367 47.7301 107.107 47.7301H120.581C123.436 47.7301 125.606 50.0138 125.606 52.7543V93.7474C125.606 96.6021 123.322 98.7716 120.581 98.7716H78.7889V99Z' fill='%23E6F5FB'/%3E%3C/svg%3E%0A");
        width: 126px;
        height: 99px;
      }
    }
    /*&::after{
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #D5D5D5;
      position: absolute;
      left: 22px;
      bottom: 70px;
      @include break(medium){
        display: none;
      }
    }*/
    .swiper-pagination{
      display: none;
      position: absolute;
      height: 16px;
      margin-top: 50px;
      @include break(medium){
        display: flex;
        position: relative;
        justify-content: center;
        bottom: auto !important;
      }
      @include break(x-small){
        height: 10px;
        margin-top: 30px;
      }
      &-bullet{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid $dark_blue !important;
        background-color: $white;
        margin: 0 8px !important;
        opacity: 1 !important;
        display: block;
        transition: .3s linear;
        &:hover,
        &-active{
          background-color: $dark_blue;
        }
        @include break(x-small){
          height: 10px;
          width: 10px;
          margin: 0 5px !important;
        }
      }
    }
    .swiper-button-next,
    .swiper-button-prev{
      width: 32px;
      height: 16px;
      top: auto;
      bottom: 27px;
      transform: translate(0,0,0);
      left: auto;
      @include break(medium){
        display: none;
      }
      &::after{
        display: none;
      }
      svg{
        display: block;
      }
    }
    .swiper-button-next{
      right: 0;
    }
    .swiper-button-prev{
      right: 60px;
    }
  }
  &__item{
    position: relative;
    padding-bottom: 70px;
    @include break(medium){
      padding-bottom: 0;
    }
    &-text{
      font-weight: 300;
      /*font-size: 27px;
      line-height: 36px;
      min-height: 216px;  */
      font-size: 20px;
      line-height: 26px;
      min-height: 234px;
      margin-bottom: 25px;   
      overflow: hidden;
      @include break(desktop){
        /*font-size: 25px;*/
        font-size: 20px;
      } 
      @include break(medium){
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
        min-height: auto;
        text-align: center;
        /*font-size: 30px;
        line-height: 40px;*/
        font-size: 22px;
        line-height: 28px;
      }
      @include break(small){
        max-width: 515px;
        /*font-size: 25px;
        line-height: 34px;*/
        font-size: 22px;
        line-height: 28px;
      }
      @include break(x-small){
        font-size: 19px;
        line-height: 26px;
        margin-bottom: 28px;
      }
    }
    &-deal{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 25px;
      @include break(medium){
        margin-bottom: 34px;
        justify-content: space-around;
      }
      @include break(x-small){
        margin-bottom: 27px;
      }
    }
    &-products{
      max-width: 48%;
      @include break(medium){
        text-align: center;
      }
      &-title{
        font-size: 16px;
        line-height: 23px;
        font-family: $main_font;
        margin-bottom: 0;
        @include break(medium){
          margin-bottom: 18px;
          font-size: 20px;
          line-height: 25px;
        }
        @include break(x-small){
          font-size: 15px;
          line-height: 17px;
          margin-bottom: 10px;
        }
      }
      &-text{
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 0;
        @include break(x-small){
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
    &-industry{
      max-width: 48%;
      @include break(medium){
        text-align: center;
      }
      &-title{
        font-size: 16px;
        line-height: 23px;
        font-family: $main_font;
        margin-bottom: 0;
        @include break(medium){
          margin-bottom: 18px;
          font-size: 20px;
          line-height: 25px;
        }
        @include break(x-small){
          font-size: 15px;
          line-height: 17px;
          margin-bottom: 10px;
        }
      }
      &-text{
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 0;
        @include break(x-small){
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
    &-bottom{
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 100%;
      padding-right: 100px;
      height: 82px;
      border-top: 1px solid #D4D4D4;
      @include break(medium){
        padding-right: 0;
        justify-content: center;
        padding-top: 30px;
        position: relative;
        border-top: 1px solid #D4D4D4;
      }
      @include break(x-small){
        padding-top: 20px;
        height: 70px;
      }
      img{
        max-width: 100px;
        height: auto;
        max-height: 90px;
        width: auto;
        @include break(medium){
          max-height: 40px;
          max-height: none;
          margin-right: 35px;
        }
        @include break(x-small){
          max-height: 50px;
          margin-right: 20px;
        }
      }
    }
    &-name{
      padding-left: 35px;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 0;
      @include break(medium){
        font-size: 20px;
        line-height: 22px;
        margin-left: 35px;
        padding-left: 0;
        position: relative;
        
      }
      @include break(x-small){
        font-size: 15px;
        line-height: 17px;
        margin-left: 20px;
      }
      &::before{
        content: '';
        display: none;
        position: absolute;
        width: 1px;
        height: 22px;
        left: -35px;
        top: 50%;
        background-color: #D5D5D5;
        transform: translateY(-50%);
        @include break(medium){
          display: block;
        }
        @include break(x-small){
          display: block;
          height: 14px;
          left: -20px;
        }
      }
    }
  }
}
@keyframes moveDown1 {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 145%, 0);
  }
}
@keyframes moveDown2 {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes moveCenter1 {
  0%, 100% {
    margin-left: -180px;
  }

  50% {
    margin-left: 0;
  }
}
@keyframes moveCenter1Style2 {
  0%, 100% {
    margin-left: -175px;
  }

  50% {
    margin-left: 0;
  }
}
@keyframes moveCenter2 {
  0%, 100% {
    margin-left: -375px;
  }

  50% {
    margin-left: 0;
  }
}
@keyframes moveCenter2Style2 {
  0%, 100% {
    margin-left: -315px;
  }

  50% {
    margin-left: 0;
  }
}
@keyframes moveCenter1Mobile {
  0%, 100% {
    margin-left: -97px;
  }

  50% {
    margin-left: 0;
  }
}
@keyframes moveCenter1MobileStyle2 {
  0%, 100% {
    margin-left: -95px;
  }

  50% {
    margin-left: 0;
  }
}
@keyframes moveCenter2Mobile {
  0%, 100% {
    margin-left: -180px;
  }

  50% {
    margin-left: 0;
  }
}
@keyframes moveCenter2MobileStyle2 {
  0%, 100% {
    margin-left: -180px;
  }

  50% {
    margin-left: 0;
  }
}