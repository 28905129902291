.video-wrapper {
	position : relative;

	padding : 219px 0 140px;

	@media( max-width: 768px) {
		padding : 157px 0 111px;
	}

	@media( max-width: 480px) {
		padding : 137px 0 50px;
	}

	h2 {
		@media( max-width: 768px) {
			font-size: 65px;
			margin-bottom : 53px;
		}

		@media( max-width: 480px) {
			font-size: 30px;
			margin-bottom : 34px;
		}

	}

	&.have-questions {
		padding : 280px 0 130px;
	}

	&__container {
		text-align : center;
	}

	&__iframe-creator-wrapper {
		text-align : center;
		position : relative;
		display : inline-block;
		margin : 0 auto;
		max-width : 100%;

		@media( max-width: 768px) {
			padding : 0 20px;
		}

		@media( max-width: 600px) {
			padding : 0;
		}

	}

	&__iframe-creator {
		margin        : 0 auto;
		border        : none;
		padding       : 23px;
		box-shadow    : 0px 10px 30px rgba(28, 20, 58, 0.3);
		border-radius : 24px;
		cursor        : pointer;
		position      : relative;
		z-index : 1;
		max-width : 100%;

		@media( max-width: 768px) {
			padding : 19px;
		}

		@media( max-width: 768px) {
			padding : 10px;
		}

		img {
			max-width: 100%;
			height : auto;
		}
	}

	&__play-btn {
		position  : absolute;
		top       : 50%;
		left      : 50%;
		transform : translateY(-50%) translateX(-50%);
		cursor    : pointer;
		z-index   : 20;
		opacity   : 0.8;

		@media( max-width: 768px) {
			width : 102px;
			height : 102px;
		}

		@media( max-width: 480px) {
			width : 79px;
			height : 79px;
		}

		> * {
			pointer-events : none;
		}

		&:hover {
			opacity : 1;
		}
	}

	&__iframe {
		height    : 400px;
		width     : 735px;
		max-width : 100%;
	}

	&__floating-element {
		position : absolute;
		background-repeat : no-repeat;
		background-position : center center;

		&.floating-element-1 {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzUiIGhlaWdodD0iMTE1IiB2aWV3Qm94PSIwIDAgNzUgMTE1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik01NSAxMTQuMDIzQzg1LjM3NTcgMTE0LjAyMyAxMTAgODguNTAzNyAxMTAgNTcuMDIzNEMxMTAgMjUuNTQzMiA4NS4zNzU3IDAuMDIzNDM3NSA1NSAwLjAyMzQzNzVDMjQuNjI0MyAwLjAyMzQzNzUgMCAyNS41NDMyIDAgNTcuMDIzNEMwIDg4LjUwMzcgMjQuNjI0MyAxMTQuMDIzIDU1IDExNC4wMjNaIiBmaWxsPSIjREJGM0ZEIi8+PC9zdmc+);
			width : 77px;
			height : 117px;
			right: 0;
			top: 250px;

			@media( max-width: 768px) {
				width: 114px;
				height : 114px;
				top : 320px;
				right: 20px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEwIiBoZWlnaHQ9IjExNCIgdmlld0JveD0iMCAwIDExMCAxMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTU1IDExNEM4NS4zNzU3IDExNCAxMTAgODguNDgwMiAxMTAgNTdDMTEwIDI1LjUxOTggODUuMzc1NyAwIDU1IDBDMjQuNjI0MyAwIDAgMjUuNTE5OCAwIDU3QzAgODguNDgwMiAyNC42MjQzIDExNCA1NSAxMTRaIiBmaWxsPSIjREJGM0ZEIi8+PC9zdmc+);

			}

			@media( max-width: 480px) {
				display : none;
			}

		}

		&.floating-element-2 {
			top: 478px;
			left: 113px;
			width : 82px;
			height : 82px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzQiIGhlaWdodD0iNzQiIHZpZXdCb3g9IjAgMCA3NCA3NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCBvcGFjaXR5PSIwLjMiIHg9Ii00IiB5PSIzNi4yMTA5IiB3aWR0aD0iNTgiIGhlaWdodD0iNTgiIHJ4PSIxMCIgdHJhbnNmb3JtPSJyb3RhdGUoLTQzLjg1NjQgLTQgMzYuMjEwOSkiIGZpbGw9IiM1REREQTMiLz48L3N2Zz4=);

			@media( max-width: 1200px) {
				left: 44px;
			}

			@media( max-width: 1024px) {
				left: 10px;
			}

			@media( max-width: 768px) {
				top : 380px;
				left : 0;
				width : 47px;
				height : 68px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDMiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA0MyA2MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCBvcGFjaXR5PSIwLjMiIHg9Ii0yMSIgeT0iMjkuNDA2MiIgd2lkdGg9IjQ3Ljk4MDYiIGhlaWdodD0iNDcuOTgwNiIgcng9IjEwIiB0cmFuc2Zvcm09InJvdGF0ZSgtNDMuODU2NCAtMjEgMjkuNDA2MikiIGZpbGw9IiM1REREQTMiLz48L3N2Zz4=);


			}

			@media( max-width: 480px) {
				display : none;
			}
		}

		&.floating-element-3 {
			z-index : -1;
			width : 221px;
			height : 194px;
			bottom : -75px;
			right: -57px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIyIiBoZWlnaHQ9IjE5NSIgdmlld0JveD0iMCAwIDIyMiAxOTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTc2LjQ0MDQgMC4wMjM0Mzc1SDE0NC43NzJDMTU5LjkxNCAwLjAyMzQzNzUgMTczLjgyNiA3Ljc2NTg1IDE4MS4zNTggMjAuMzAxMkwyMTUuNTYzIDc3LjA3ODlDMjIzLjA5NSA4OS42MTQyIDIyMy4wOTUgMTA1LjA5OSAyMTUuNTYzIDExNy42MzRMMTgxLjM1OCAxNzQuNDEyQzE3My44MjYgMTg2Ljk0OCAxNTkuODM3IDE5NC42OSAxNDQuNzcyIDE5NC42OUg3Ni40NDA0QzYxLjI5ODQgMTk0LjY5IDQ3LjM4NjEgMTg2Ljk0OCAzOS44NTM2IDE3NC40MTJMNS42NDk0MyAxMTcuNjM0Qy0xLjg4MzE0IDEwNS4wOTkgLTEuODgzMTQgODkuNjE0MiA1LjY0OTQzIDc3LjA3ODlMMzkuODUzNiAyMC4zMDEyQzQ3LjM4NjEgNy43NjU4NSA2MS4zNzUzIDAuMDIzNDM3NSA3Ni40NDA0IDAuMDIzNDM3NVoiIGZpbGw9IiNFREQxRkYiLz48L3N2Zz4=);

			@media( max-width: 768px) {
				bottom : -66px;
				right : -57px;
				width : 153px;
				height : 164px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUzIiBoZWlnaHQ9IjE2MSIgdmlld0JveD0iMCAwIDE1MyAxNjEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYzLjQ5NSAwSDEyMC4wMjNDMTMyLjU0OSAwIDE0NC4wNTggNi40MDM0MSAxNTAuMjkgMTYuNzcwOEwxNzguNTg1IDYzLjcyOTFDMTg0LjgxNyA3NC4wOTY2IDE4NC44MTcgODYuOTAzNCAxNzguNTg1IDk3LjI3MDhMMTUwLjI5IDE0NC4yMjlDMTQ0LjA1OCAxNTQuNTk3IDEzMi40ODUgMTYxIDEyMC4wMjMgMTYxSDYzLjQ5NUM1MC45Njg2IDE2MSAzOS40NTk1IDE1NC41OTcgMzMuMjI4MSAxNDQuMjI5TDQuOTMyMzQgOTcuMjcwOEMtMS4yOTkwNiA4Ni45MDM0IC0xLjI5OTA2IDc0LjA5NjYgNC45MzIzNCA2My43MjkxTDMzLjIyODEgMTYuNzcwOEMzOS40NTk1IDYuNDAzNDEgNTEuMDMyMiAwIDYzLjQ5NSAwWiIgZmlsbD0iI0VERDFGRiIvPjwvc3ZnPg==);


			}

			@media( max-width: 480px) {
				display : none;
			}
		}
	}

}