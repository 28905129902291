.our-locations-map{
  margin-top: 200px;
  margin-bottom: 255px;
  @include break(desktop){
    margin-top: 100px;
    margin-bottom: 80px;
  }
  @include break(ss-small){
    margin-top: 0px;
    margin-bottom: 0px;
  }
  &__container{
    @include break(ss-small){
      padding-top: 50px;
      padding-bottom: 58px;
      background: linear-gradient(180deg, #DEF6FE 0%, rgba(241, 250, 253, 0) 120.44%);
    }
  
  }
  &__wrap{
    background: linear-gradient(180deg, #DEF6FE 0%, rgba(241, 250, 253, 0) 120.44%);
    border-radius: 40px;
    padding-top: 76px;
    padding-bottom: 163px;
    padding-left: 50px;
    padding-right: 50px;
    @include break(medium850){
      border-radius: 20px;
      padding: 40px;
    }
    @include break(ss-small){
      padding: 0;
      border-radius: 0;
      background: transparent;
    }
  }
  &__wrapper{
    max-width: 864px;
    margin-left: auto;
    margin-right: auto;
    @include break(medium850){
      max-width: 566pxx;
    }
  }
  &__title{
    font-size: 60px;
    font-weight: 400;
    line-height: 68px;
    text-align: center;
    @include break(medium850){
      font-size: 35px;
      line-height: 41px;
    }
    @include break(x-small){
      font-size: 30px;
      line-height: 34px;
      margin-bottom: 2px;
    }
  }
  &__text{
    font-weight: 300;
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    margin-bottom: 0;
    @include break(medium850){
      font-size: 18px;
      line-height: 26px;
    }
    @include break(x-small){
      font-size: 15px;
      line-height: 21px;
    }
  }
  &__map{
    margin-top: 60px;
    position: relative;
    @include break(medium850){
      margin-top: 52px;
    }
    @include break(ss-small){
      display: none;
    }
    svg{
      display: block;
      width: 100%;
      height: auto;
      path{
        transition:opacity .2s linear;
      }
    }
    .hide-dot{
      opacity: 0;
    }
  }
  &__mobile-wrapper{
    display: none;
    margin-top: 45px;
    @include break(ss-small){
      display: block;
    }
  }
}
//map dots
.map-hover-element{
  
}
.map-hover-element__mark{
  cursor: pointer;
}
.js-map-location-icon{
  position: absolute;
  width: 50px;
  height: 63px;
  margin-top: -60px;
  margin-left: -20px;
  transition:opacity .3s linear;
  visibility: hidden;
  opacity: 0;
  @include break(medium850){
    width: 33px;
    height: 41px;
    margin-top: -38px;
    margin-left: -12px; 
  }
  &.show{
    visibility: visible;
    opacity: 1;
  }
}
.map-location-info{
  border: 1px solid #B9D8E7;
  box-shadow: 0px 4.37647px 70.0235px rgba(2, 29, 89, 0.21);
  border-radius: 24px;
  background-color: $white;
  padding: 18px 18px 24px 18px;
  width: 285px;
  @include break(medium850){
    display: grid;
    grid-template-columns: 136px 1fr;
    grid-column-gap: 25px;
    column-gap: 25px;
    box-shadow: 0px 4.37647px 70.0235px rgba(2, 29, 89, 0.11);
    width: 435px;
    padding: 18px;
  }
  @include break(ss-small){
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 136px 1fr;
    &:not(:last-child){
      margin-bottom: 13px;
    }
  }
  @include break(x-small){
    max-width: 335px;
    grid-template-columns: 109px 1fr;
    grid-column-gap: 15px;
    column-gap: 15px;
  }
  &.js-map-location-info{
    position: absolute;
    visibility: hidden;
    opacity: 0;
    z-index: 1000;
    transition:opacity .2s linear;
    &.show{
      visibility: visible;
      opacity: 1;
    }
  }
  &__img-wrapper{
    position: relative;
    width: 100%;
    height: 149px;
    border-radius: 13px;
    overflow: hidden;
    @include break(medium850){
      height: 140px;
    }
    @include break(x-small){
      height: 127px;
    }
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10;
      position: absolute;
      background: linear-gradient(57.04deg, rgba(21, 189, 255, 0.47) 9.98%, rgba(21, 189, 255, 0) 83.32%);
      @include break(medium850){
        background: linear-gradient(57.58deg, rgba(21, 189, 255, 0.47) 9.71%, rgba(21, 189, 255, 0) 83.31%);
      }
    }
  }
  &__img{
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    object-fit: cover;
    transform: translate(-50%,-50%);
  }
  &__content{
    margin-top: 20px;
    @include break(medium850){
      margin-top: 0;
    }
  }
  &__heading{
    position: relative;
    padding-bottom: 13px;
    margin-bottom: 20px;
    @include break(medium850){
      margin-bottom: 11px;
      padding-bottom: 8px;
    }
    &::before{
      content: '';
      display: block;
      background-color: #C6CCCE;
      width: 54px;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  &__name{
    font-size: 20px;
    line-height: 22px;
    font-family: $main_font;
    margin-bottom: 0;
    @include break(medium850){
      line-height: 25px;
    }
    @include break(x-small){
      font-size: 15px;
      line-height: 17px;
    }
  }
  &__company{
    font-size: 14px;
    line-height: 21px;
    margin-top: 1px;
    font-family: $main_font;
    margin-bottom: 0;
    @include break(medium850){
      font-size: 12px;
      line-height: 17px;
    }
  }
  &__address{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 9px;
    @include break(medium850){
      font-size: 16px;
      line-height: 23px;
    }
    @include break(x-small){
      font-size: 12px;
      line-height: 15px;
    }
  }
  &__phone{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 0;
    @include break(medium850){
      font-size: 16px;
      line-height: 23px;
    }
    @include break(x-small){
      font-size: 12px;
      line-height: 15px;
    }
  }
}