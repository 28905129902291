.presskit-two{
  margin-top: 150px;
  margin-bottom: 130px;
  @include break(tablet){
    margin-top: 90px;
    margin-bottom: 110px;
  }
  @include break(ss-small){
    margin-top: 60px;
    margin-bottom: 65px;
  }
  &__container{
    max-width: 1190px;
  }
  &__title{
    font-weight: 400;
    font-size: 35px;
    line-height: 50px;
    padding-bottom: 11px;
    position: relative;
    margin-bottom: 45px;
    color: $dark_blue;
    @include break(tablet){
      font-size: 30px;
      line-height: 43px;
      padding-bottom: 6px;
      margin-bottom: 50px;
    }
    @include break(ss-small){
      font-size: 20px;
      line-height: 30px;
      padding-bottom: 13px;
      margin-bottom: 40px;
    }
    &::before{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(2, 29, 89, 0.29);
    }
  }
  &__wrapper{
    max-width: 990px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 55px;
    column-gap: 55px;
    grid-row-gap: 45px;
    row-gap: 45px;
    @include break(medium850){
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 18px;
      column-gap: 18px;
      grid-row-gap: 35px;
      row-gap: 35px;
    }
    @include break(s-small){
      grid-template-columns: 1fr;
    }
  }
  &__item{
    padding: 12px 12px 20px 12px;
    background-color: $white;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-image-wrapper{
      overflow: hidden;
      border-radius: 10px;
      margin-bottom: 20px;
      height: 158px;
      &.border{
        border: 1px solid rgba(0, 0, 0, 0.08);
      }
      @include break(medium850){ 
        height: 175px;
      }
      @include break(s-small){
        height: 166px;
      }
    }
    &-image{
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
    }
    &-text{
      max-width: 245px;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      line-height: 23px;
      font-weight: 300;
      /*display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;*/
      margin-bottom: 0;
      @include break(medium){
        font-size: 18px;
        line-height: 26px;
        max-width: 230px;
      }
      @include break(s-small){
        max-width: 265px;
        font-size: 16px;
        line-height: 23px;
      }
    }
    &-link{
      max-width: 100%;
      width: 100%;
      display: block;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      text-decoration: underline;
      color: $azure;
      font-family: $second_font;
      transition: .3s linear;
      margin-top: 15px;
      @include break(medium){
        margin-top: 10px;
      }
      @include break(s-small){
        font-size: 16px;
        line-height: 23px;
        margin-top: 25px;
      }
      &:hover{
        text-decoration: none;
        color: $dark_blue;
      }
    }
  }
}