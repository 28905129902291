.ready-to-evolve{
  /*margin-top: 225px;*/
  margin-top: 130px;
  margin-bottom: 235px;
  @include break(large1370){
    margin-top: 105px;
    margin-bottom: 200px;
  }
  @include break(ss-small){
    margin-top: 90px;
    margin-bottom: 140px;
  }
  &__container{
    max-width: 1440px;
    padding-left: 100px;
    padding-right: 100px;
    display: grid;
    grid-template-columns: 1fr 605px;
    grid-column-gap: 15px;
    column-gap: 15px;
    @include break(large1370){
      grid-template-columns: 1fr 530px;
      padding-left  : 40px;
		  padding-right : 40px;
      max-width : 1200px;
    }
    @include break(desktop) {
      max-width : 1000px;
      grid-template-columns: 1fr 430px;
    }
    @include break(tablet) {
      max-width : 900px;
    }
  
    @include break(medium) {
      /*max-width : 760px;*/
      max-width: 100%;
      display: block;
    }
    @include break(ss-small){
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &__content{
    padding-left: 55px;
    padding-top: 145px;
    @include break(large1370){
      padding-left: 0;
    }
    @include break(medium){
      padding-top: 0;
    }
  }
  &__title{
    font-size: 103px;
    line-height: 100px;
    margin-bottom: 15px;
    @include break(desktop){
      font-size: 80px;
      line-height: 80px;
    }
    @include break(medium){
      text-align: center;
      font-size: 65px;
      line-height: 65px;
      margin-bottom: 15px;
      br{
        display: none;
      }
    }
    @include break(ss-small){
      font-size: 42px;
      line-height: 50px;
      max-width: 295px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &__text{
    max-width: 460px;
    font-weight: 300;
    font-size: 22px;
    line-height: 31px;
    margin-bottom: 0;
    @include break(medium){
      max-width: 425px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    @include break(ss-small){
      max-width: 255px;
      font-size: 15px;
      line-height: 21px;
    }
  }
  &__links-container{
    @include break(medium){
      padding-left: 20px;
      padding-right: 45px;
    }
    @include break(ss-small){
      padding-left: 12px;
      padding-right: 13px;
    }
  }
  &__links-wrapper{
    border: 1px solid #C6E9F7;
    background-color:#F1FAFD;
    padding-top: 83px;
    padding-bottom: 73px;
    padding-left: 120px;
    padding-right: 105px;
    border-radius: 25px;
    position: relative;
    @include break(large1370){
      padding-left: 90px;
      padding-right: 75px;
    }
    @include break(desktop){
      padding: 80px 65px;
    }
    @include break(medium){
      margin-top: 70px;
    }
    @include break(ss-small){
      margin-top: 45px;
      padding:40px 26px;
    }
    &::before{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      right: -40px;
      top: 40px;
      border-radius: 25px;
      border: 1px solid #15BDFF;
      z-index: -1;
      @include break(medium){
        right: -45px;
        top: 27px;
      }
      @include break(ss-small){
        right: -13px;
        top: 17px;
        height: 360px;
      }
    }
  }
  &__links{
    &-item{
      display: block;
      width: 100%;
      height: 84px;
      padding-left: 93px;
      padding-right: 27px;
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: $white;
      box-shadow: 0px 11px 14px rgba(178, 215, 229, 0.4);
      border-radius: 16px;
      position: relative;
      display: flex;
      align-items: center;
      transition: .3s ease-in-out;
      @include break(medium){
        height: 82px;
      }
      @include break(ss-small){
        padding-left: 65px;
        height: 58px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 5px;
      }
      &:not(:last-child){
        margin-bottom: 26px;
        @include break(medium){
          margin-bottom: 35px;
        }
        @include break(ss-small){
          margin-bottom: 15px;
        }
      }
      &:hover{
        .ready-to-evolve__links-icon{
          border-color: $white;
        }
        path{
          fill: $white;  
        }
        .ready-to-evolve__links-text{
          color: $white;
        }
      }
      &-0{
        .ready-to-evolve__links-icon{
          background-color: #E8F9F1;
        }
        &:hover{
          background-color: #5DDDA3;
          .ready-to-evolve__links-icon{
            background-color: #5DDDA3;
          }
        }
      }
      &-1{
        .ready-to-evolve__links-icon{
          background-color: #F6E6FF;
        } 
        &:hover{
          background-color: #B748FF;
          .ready-to-evolve__links-icon{
            background-color: #B748FF;
          }
        }
      }
      &-2{
        .ready-to-evolve__links-icon{
          background-color: #D9FBFF;
        }
        &:hover{
          background-color: #15BDFF;
          .ready-to-evolve__links-icon{
            background-color: #15BDFF;
          }
        }
      }
    }
    &-wrapper{
      margin-bottom: 0;
    }
    &-icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 30px;
      width: 36px;
      height: 36px;
      border: 1px solid #000000;
      border-radius: 6px;
      transition: .3s ease-in-out;
      overflow: hidden;
      @include break(ss-small){
        width: 25px;
        height: 25px;
        left: 21px;
      }
      svg{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
        transition: .3s ease-in-out;
        @include break(ss-small){
          width: 16px;
          height: auto;
        }
        path{
          transition: .3s ease-in-out;
        }
      }
    }
    &-text{
      font-size: 16px;
      line-height: 21px;
      transition: .3s ease-in-out;
      color: #1C143A;
      @include break(medium){
        font-size: 18px;
        line-height: 23px;
      }
      @include break(ss-small){
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  &__link-wrapper{
    text-align: center;
    margin-top: 51px;
    @include break(medium){
      margin-top: 55px;
    }
    @include break(ss-small){
      margin-top: 25px;
    }
  }
  &__link{
    display: inline-block;
  }
}