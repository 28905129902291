.what-you-get {
	padding-top : 100px;
	padding-bottom : 75px;
	text-align : center;
	position : relative;

	@media( max-width: 768px) {
		padding-top : 72px;
		padding-bottom : 74px;
	}

	@media( max-width: 480px) {
		padding-top : 50px;
		padding-bottom : 55px;
	}

	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 35px;
		line-height: 1.43;
		text-align: center;
		color: $black;
		margin-bottom : 93px;

		@media( max-width: 768px) {
			font-size: 30px;
			margin-bottom : 67px;
		}

		@media( max-width: 480px) {
			margin-bottom : 50px;
		}
	}

	&__wrap {
		display : flex;
		flex-wrap: wrap;
		justify-content : space-between;
		margin-bottom : 108px;

		@media( max-width: 1030px) {
			margin-bottom : 64px;
			justify-content : center;
		}

		@media( max-width: 480px) {
			margin-bottom : 66px;
		}
	}

	&__item {
		width : calc(33.33% - 26px);
		background: #FFFFFF;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
		border-radius: 10px;
		padding : 30px 41px 30px;

		@media( max-width: 1030px) {
			width : 100%;
			max-width: 474px;
			padding : 0 30px 0;
			margin-bottom : 70px;
			box-shadow: none;
			&:last-of-type {
				margin-bottom : 0;
			}
		}

		@media( max-width: 480px) {
			margin-bottom : 56px;
			padding : 0 10px 0;
			max-width : 266px;
		}
	}

	&__item-count {
		margin-bottom: 20px;
		text-align: center;
		font-family: $kanit;
		font-size: 200px;
		line-height: 1;
		font-weight: 700;
		letter-spacing: 0.015em;
		color: #DFFAFE;
		opacity: 0.9;
		-webkit-text-stroke: 2px #55BBF9;
		@media( max-width: 1370px) {
			font-size: 155px;
		}
		@media( max-width: 1030px){
			margin-bottom: 0;
			font-size: 200px;
		}
		@media( max-width: 480px) {
			font-size: 155px;
		}
	}

	&__item-title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 700;
		font-size: 23px;
		line-height: 1.32;
		text-align: center;
		color: $dark_blue;
		margin-bottom : 47px;
		height : 27px;

		@media( max-width: 1370px) {
			height : 60px;
		}
		@media( max-width: 1030px){
			height: auto;
		}
		@media( max-width: 768px) {
			margin-bottom : 30px;
			font-size: 23px;
		}

		@media( max-width: 480px) {
			font-size: 18px;
			margin-bottom : 10px;
		}
	}

	&__item-content {
		font-family: $second_font;
		font-style: normal;
		font-weight: 300;
		font-size: 22px;
		line-height: 1.43;
		text-align: center;
		color: $dark_blue;
		margin-bottom: 0;
		@media( max-width: 768px) {
			font-size: 23px;
		}

		@media( max-width: 480px) {
			font-size: 15px;
		}
	}

	&__button {
		display : inline-block;
	}

	&__floating-element {
		position : absolute;
		background-repeat : no-repeat;
		background-position : center center;

		&.floating-element-1 {
			display : none;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTE0IiBoZWlnaHQ9IjExNCIgdmlld0JveD0iMCAwIDExNCAxMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iMjguMzk0NSIgeT0iLTQuMDA3ODEiIHdpZHRoPSI5NSIgaGVpZ2h0PSI5NSIgcng9IjE2IiB0cmFuc2Zvcm09InJvdGF0ZSgxOS45MzcxIDI4LjM5NDUgLTQuMDA3ODEpIiBmaWxsPSIjRjBGQkZGIi8+PC9zdmc+);
			width : 122px;
			height : 122px;
			top : 537px;
			right: -42px;

			@media( max-width: 768px) {
				display : block;
			}

			@media( max-width: 480px) {
				top : 444px;
			}

		}

		&.floating-element-2 {
			display : none;
			width : 207px;
			height : 207px;
			bottom : 575px;
			left: -54px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk1IiBoZWlnaHQ9IjE5NSIgdmlld0JveD0iMCAwIDE5NSAxOTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeT0iMC40NzgzODgiIHdpZHRoPSIxNDMuNDI0IiBoZWlnaHQ9IjE0OC4yMDEiIHJ4PSIxNC44ODQiIHRyYW5zZm9ybT0ibWF0cml4KC0wLjcwNzEwNyAwLjcwNzEwNyAwLjcwNzEwNyAwLjcwNzEwNyA5NS41NTYzIC01Ljg1OTg4KSIgc3Ryb2tlPSIjMUMxNDNBIiBzdHJva2Utb3BhY2l0eT0iMC41IiBzdHJva2Utd2lkdGg9IjAuNjc2NTQzIiBzdHJva2UtZGFzaGFycmF5PSI0Ljg1IDQuODUiLz48L3N2Zz4=);

			@media( max-width: 768px) {
				display : block;
			}

			@media( max-width: 480px) {
				width : 69px;
				height : 68px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjkiIGhlaWdodD0iNjgiIHZpZXdCb3g9IjAgMCA2OSA2OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjY4IiBoZWlnaHQ9IjY3IiByeD0iMTQuNSIgc3Ryb2tlPSIjMDIxRDU5IiBzdHJva2UtZGFzaGFycmF5PSIyIDIiLz48L3N2Zz4=);
				left: auto;
				top : 503px;
				right: -40px;
			}

		}
	}
}