.have-questions {
	margin : 80px 0 170px;
	text-align : center;

	@media( max-width: 768px) {
		margin : 111px 0 205px;
	}

	@media( max-width: 480px) {
		margin : 50px 0 110px;
	}

	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 60px;
		line-height: 1.10;
		color: $dark_blue3;
		margin-bottom : 29px;
		max-width : 645px;
		margin-left : auto;
		margin-right : auto;

		@media( max-width: 768px) {
			font-size: 65px;
			max-width : 568px;
		}

		@media( max-width: 480px) {
			font-size: 30px;
			margin-bottom : 10px;
			max-width : 263px;
		}
	}

	&__content {
		font-family: $second_font;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 1.43;
		color: $dark_blue;
		margin : 0 auto 50px;
		max-width : 791px;

		@media( max-width: 768px) {
			font-size: 23px;
			margin-bottom : 37px;
			max-width : 534px;
		}

		@media( max-width: 480px) {
			font-size: 15px;
			margin-bottom : 44px;
			max-width : 100%;
		}
	}

	&__button {
		display : inline-block;
	}
}