.side-icon-text{
  margin-top: 155px;
  margin-bottom: 155px;
  @include break(desktop){
    margin-top: 100px;
    margin-bottom: 130px;
  }
  @include break(ss-small){
    margin-top: 105px;
    margin-bottom: 120px;
  } 
  &__container{
    position: relative;
    .floating-element{
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      &-1{
        background-image: url("data:image/svg+xml,%3Csvg width='133' height='133' viewBox='0 0 133 133' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='66.5391' y='-3.93934' width='99.6707' height='99.6707' rx='13.25' transform='rotate(45 66.5391 -3.93934)' fill='%2315BDFF' fill-opacity='0.1' stroke='%2315BDFF' stroke-width='1.5' stroke-dasharray='3 3 3 3'/%3E%3C/svg%3E%0A");
        z-index: 10;
        width: 133px;
        height: 133px;
        left: -84px;
        bottom: 181px;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='85' height='85' viewBox='0 0 85 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='42.7305' y='-3.29289' width='64.3801' height='64.3801' rx='9.5' transform='rotate(45 42.7305 -3.29289)' fill='%2315BDFF' fill-opacity='0.1' stroke='%2315BDFF' stroke-dasharray='1.94 1.94 1.94 1.94'/%3E%3C/svg%3E%0A");
          width: 85px;
          height: 85px;
          bottom: 50px;
          left: -35px;
        }  
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='85' height='85' viewBox='0 0 85 85' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='42.2305' y='-3.29289' width='64.3801' height='64.3801' rx='9.5' transform='rotate(45 42.2305 -3.29289)' fill='%2315BDFF' fill-opacity='0.1' stroke='%2315BDFF' stroke-dasharray='1.94 1.94 1.94 1.94'/%3E%3C/svg%3E%0A");
          width: 85px;
          height: 85px;
          right: -39px;
          bottom: 17px;
        }
      }
      &-2{
        background-image: url("data:image/svg+xml,%3Csvg width='160' height='160' viewBox='0 0 160 160' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect opacity='0.3' x='67.9023' y='-12' width='147' height='113' rx='31' transform='rotate(45 67.9023 -12)' fill='%23C6EFF4'/%3E%3C/svg%3E%0A");
        z-index: -1;
        width: 160px;
        height: 160px;
        top: -52px;
        left: -41px;
        @include break(desktop){
          display: none;
        }
      }
      &-3{
        background-image: url("data:image/svg+xml,%3Csvg width='87' height='80' viewBox='0 0 87 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M84.3393 48.0837L84.3393 48.0838L70.9019 71.4171L70.9018 71.4173C68.021 76.4242 62.6982 79.5 56.9373 79.5H30.0626C24.3022 79.5 18.9792 76.4164 16.0981 71.4171C16.0981 71.4171 16.0981 71.4171 16.0981 71.417L2.66084 48.084C-0.22035 43.0764 -0.220154 36.9154 2.66067 31.9163L2.66074 31.9162L16.0981 8.58298C16.0981 8.58294 16.0981 8.5829 16.0981 8.58286C18.9792 3.58357 24.3022 0.5 30.0626 0.5H56.9373C62.6978 0.5 68.0209 3.58358 70.9019 8.58286C70.9019 8.5829 70.9019 8.58294 70.9019 8.58298L84.3392 31.916C87.2204 36.9236 87.2202 43.0846 84.3393 48.0837Z' fill='%23EBD8FA' fill-opacity='0.2' stroke='%23B748FF'/%3E%3C/svg%3E%0A");
        z-index: 10;
        width: 87px;
        height: 80px;
        right: 103px;
        top: -42px;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='51' height='47' viewBox='0 0 51 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.1813 28.0208L49.1814 28.0207C50.8304 25.161 50.8306 21.6365 49.1813 18.7717V28.0208ZM49.1813 28.0208L41.3169 41.6686L41.3168 41.6688C39.6678 44.533 36.6211 46.2925 33.3235 46.2925H17.5946C14.2975 46.2925 11.2505 44.5285 9.60127 41.6685L1.73697 28.021C1.73694 28.0209 1.73691 28.0209 1.73688 28.0208C0.0876054 25.156 0.0878339 21.6315 1.73681 18.7718L1.73688 18.7717L9.60127 5.12398C11.2505 2.26393 14.2975 0.5 17.5946 0.5H33.3235C36.6206 0.5 39.6677 2.26388 41.3169 5.12385C41.3169 5.1239 41.3169 5.12394 41.3169 5.12398L49.1812 18.7715L49.1813 28.0208Z' fill='%23EBD8FA' fill-opacity='0.2' stroke='%23B748FF'/%3E%3C/svg%3E%0A");
          width: 51px;
          height: 47px;
          right: 2px;
          top: -22px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='51' height='47' viewBox='0 0 51 47' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.1507 28.2279L49.1507 28.2278C50.7987 25.3681 50.7989 21.8435 49.1507 18.9786V28.2279ZM49.1507 28.2279L41.291 41.8758L41.2909 41.8759C39.643 44.7401 36.5982 46.4995 33.303 46.4995H17.5838C14.2889 46.4995 11.2439 44.7357 9.59568 41.8756L1.73624 28.2281C1.7362 28.2281 1.73617 28.228 1.73614 28.2279C0.0878514 25.3631 0.0880797 21.8384 1.73607 18.9787L1.73614 18.9786L9.59568 5.33089C11.2439 2.47082 14.2889 0.707031 17.5838 0.707031H33.303C36.5978 0.707031 39.6428 2.47077 41.291 5.33077C41.2911 5.33081 41.2911 5.33085 41.2911 5.33089L49.1506 18.9784L49.1507 28.2279Z' fill='%23EBD8FA' fill-opacity='0.2' stroke='%23B748FF'/%3E%3C/svg%3E%0A");
          width: 51px;
          height: 47px;
          top: -16px;
          right: 45px;
        }
      }
      &-4{
        background-image: url("data:image/svg+xml,%3Csvg width='59' height='54' viewBox='0 0 59 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.3873 0H38.6127C42.6409 0 46.3626 2.14624 48.3767 5.625L57.4894 21.375C59.5035 24.8592 59.5035 29.1462 57.4894 32.625L48.3767 48.375C46.3626 51.8592 42.6409 54 38.6127 54H20.3873C16.3591 54 12.6374 51.8538 10.6233 48.375L1.51057 32.625C-0.503525 29.1408 -0.503525 24.8538 1.51057 21.375L10.6233 5.625C12.6374 2.14624 16.3591 0 20.3873 0Z' fill='%23F4E3FF'/%3E%3C/svg%3E%0A");
        z-index: 9;
        width: 59px;
        height: 54px;
        right: 87px;
        top: -73px;
        @include break(desktop){
          background-image: url("data:image/svg+xml,%3Csvg width='36' height='32' viewBox='0 0 36 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.684 0H23.3507C25.7082 0 27.8864 1.25535 29.0652 3.29009L34.3986 12.5024C35.5774 14.5403 35.5774 17.0478 34.3986 19.0825L29.0652 28.2948C27.8864 30.3328 25.7082 31.5849 23.3507 31.5849H12.684C10.3264 31.5849 8.1482 30.3296 6.96941 28.2948L1.63604 19.0825C0.457257 17.0446 0.457257 14.5371 1.63604 12.5024L6.96941 3.29009C8.1482 1.25535 10.3264 0 12.684 0Z' fill='%23F4E3FF'/%3E%3C/svg%3E%0A");
          width: 36px;
          height: 32px;
          top: -37px;
          right: -7px;
        }
        @include break(ss-small){
          background-image: url("data:image/svg+xml,%3Csvg width='36' height='32' viewBox='0 0 36 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.661 0H23.3211C25.6772 0 27.8541 1.25535 29.0321 3.29009L34.3622 12.5024C35.5403 14.5403 35.5403 17.0478 34.3622 19.0825L29.0321 28.2948C27.8541 30.3328 25.6772 31.5849 23.3211 31.5849H12.661C10.3048 31.5849 8.12801 30.3296 6.94995 28.2948L1.61987 19.0825C0.441814 17.0446 0.441814 14.5371 1.61987 12.5024L6.94995 3.29009C8.12801 1.25535 10.3048 0 12.661 0Z' fill='%23F4E3FF'/%3E%3C/svg%3E%0A");
          width: 36px;
          height: 32px;
          right: 35px;
          top: -32px;
        }
      }
    }
  }
  &__wrapper{
    display: grid;
    grid-template-columns: 1fr 364px;
    grid-column-gap: 90px;
    column-gap: 90px;
    padding: 112px 80px;
    align-items: center;
    border-radius: 40px;
    max-width: 1205px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    &::before{
      content: '';
      display: block;
      width: 91%;
      height: 88%;
      position: absolute;
      right: -42px;
      bottom: -42px;
      border-radius: 40px;
      border: 1px solid $azure;
      z-index: -1;
      @include break(large1370){
        width: 98%;
        height: 85%;
        right: -22px;
        bottom: -28px;
      }
      @include break(medium){
        border-radius: 20px;
      }
      @include break(ss-small){
        bottom: -26px;
        right: -11px;
        width: 95%;
        height: 96%;
      }
    }
    @include break(desktop){
      grid-template-columns: 1fr 300px;
      grid-column-gap: 30px;
      column-gap: 30px;
    }
    @include break(tablet){
      padding: 45px;
      grid-template-columns: 1fr 250px;
      grid-column-gap: 20px;
      column-gap: 20px;
    }
    @include break(medium){
      grid-template-columns: 1fr;
      border-radius: 20px;
      grid-row-gap: 30px;
      row-gap: 30px;
      justify-items: center;
    }
    @include break(ss-small){
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  &__content{
    @include break(medium){
      order: 2;
      max-width: 650px;
      @include break(medium850){
        max-width: 455px;
      }
    }
  }
  &__title{
    font-size: 75px;
    line-height: 85px;
    font-weight: 400;
    margin-bottom: 20px;
    color: $white;
    @include break(medium){
      text-align: center;
    }
    @include break(medium850){
      font-size: 30px;
      line-height: 43px;
    }
  }
  &__text{
    *{
      font-weight: 300;
      font-size: 20px;
      line-height: 29px;
      color: $white;
      margin-bottom: 0;
      @include break(medium){
        text-align: center;
      }
      @include break(medium850){
        font-size: 13px;
        line-height: 21px;
      }
      &:not(:last-child){
        margin-bottom: 29px;
        @include break(medium850){
          margin-bottom: 21px;
        }
      }
    }
  }
  &__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    @include break(medium){
      max-width: 250px;
      order: 1;
    }
    @include break(medium850){
      max-width: 175px;
    }
    img, svg{
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}