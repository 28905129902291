.our-partners-tabs {
	padding-top : 95px;
	padding-bottom : 75px;
	position : relative;

	@media( max-width: 768px) {
		padding-top : 108px;
		padding-bottom : 50px;
	}

	@media( max-width: 480px) {
		padding-top : 64px;
		padding-bottom : 28px;
	};

	&__title {
		font-family: $main_font;
		font-style: normal;
		font-weight: 400;
		font-size: 35px;
		line-height: 1.43;
		text-align: center;
		color: $dark_blue3;
		margin-bottom : 17px;

		@media( max-width: 768px) {
			font-size: 65px;
			margin-bottom : 12px;
		}

		@media( max-width: 480px) {
			font-size: 30px;
			margin-bottom : 10px;
		}
	}

	&__subtitle {
		font-family: $second_font;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 1.43;
		text-align: center;
		color: $dark_blue3;
		margin-bottom : 70px;

		@media( max-width: 768px) {
			font-size: 23px;
			margin-bottom : 27px;
		}

		@media( max-width: 480px) {
			font-size: 15px;
			max-width : 197px;
			margin : 0 auto 32px;
		}
	}

	&__link-wrap {
		width : 100%;
		display : flex;
		justify-content : center;
		flex-wrap: wrap;
		align-items : center;
		margin-bottom : 70px;

		@media( max-width: 1030px) {
			display : none;
		}
	}

	&__links-item {
		font-family: $second_font;
		font-style: normal;
		font-weight: 300;
		font-size: 16px;
		line-height: 1.0;
		color: $dark_blue;
		padding : 10px 26px;
		margin-right : 20px;
		border: 1px solid $dark_blue;
		border-radius: 81px;
		transition: 0.3s;

		@media( max-width: 992px) {
			margin-right : 15px;
			padding: 10px 18px;
		}

		&:hover {
			color: #fff;
			background-color : $dark_blue;
		}

		&:last-of-type {
			margin-right : 0;
		}

		&.active {
			color: #fff;
			background-color : $dark_blue;
		}
	}

	&__tab-content {
		grid-template-columns: repeat(3, 253px);
		justify-items: stretch;
		gap: 40px 32px;
		display : none;
		justify-content : center;
		position: relative;
		z-index: 100;
		@media( max-width: 992px) {
			grid-template-columns: repeat(3, calc(33.33% - 33px));
		}

		@media( max-width: 768px) {
			gap: 33px 26px;
		}

		@media( max-width: 480px) {
			grid-template-columns: repeat(2, calc(50% - 11px));
			gap: 22px 20px;
		};

		&.active {
			display: grid;
		}
	}

	&__content-item {
		height : 160px;
		display : flex;
		justify-content : center;
		align-items : center;
		background: #FFFFFF;
		box-shadow: 0px 4px 31px -16px rgba(82, 82, 82, 0.25);
		border-radius: 38px;

		@media( max-width: 992px) {
			height : 150px;
		}

		@media( max-width: 768px) {
			height : 131px;
		}

		@media( max-width: 550px) {
			height : 100px;
		}

		@media( max-width: 480px) {
			height : 90px;
		}
		a{
			display : flex;
			justify-content : center;
			align-items : center;
			&:hover{
				img{
					transform: scale(1.2);
				}
			}
		}
		img {
			max-width : 75%;
			height : auto;
			transition: .3s linear;
		}
	}

	&__link-select {
		display : none;
		height : 61px;
		width : 100%;
		margin-bottom : 49px;
		position : relative;
		z-index: 200;
		@media( max-width: 1030px) {
			display : block;
		}

		@media( max-width: 480px) {
			height : 41px;
			margin-bottom : 44px;
		}
	}

	&__link-select-wrap {
		width : 100%;
		max-height: 100%;
		display : flex;
		flex-direction : column;
		position : absolute;
		overflow: hidden;
		top : 0;
		left : 0;
		background: #F2F2F2;
		border: 1px solid $azure;
		box-shadow: 0px 4px 4px rgba(49, 197, 255, 0.25);
		border-radius: 8.19848px;
		padding : 19px 15px;
		transition: 0.5s;
		z-index : 5;

		@media( max-width: 480px) {
			padding: 12px 17px;
		}

		&.open {
			max-height: 100vh;

			&:before {
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTUuODc4OSA5LjIxODc1TDguNDM5ODggMS43Nzk3M0wxLjAwMDg2IDkuMjE4NzUiIHN0cm9rZT0iIzAyMUQ1OSIgc3Ryb2tlLXdpZHRoPSIxLjQ4NzgiLz48L3N2Zz4=);

			}
		}

		&:before {
			content: "";
			position : absolute;
			right: 20px;
			top: 26px;
			display : block;
			width : 10px;
			height : 9px;
			background-repeat : no-repeat;
			background-position : center center;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMUw2IDZMMTEgMSIgc3Ryb2tlPSIjMDIxRDU5Ii8+PC9zdmc+);

			@media( max-width: 480px) {
				top : 18px;
			}

		}
	}

	&__select-item {
		font-family: $second_font;
		font-style: normal;
		font-weight: 400;
		font-size: 19px;
		line-height: 1.43;
		color: $dark_blue;
		margin-bottom : 26px;
		display : block;
		order : 1;

		@media( max-width: 480px) {
			font-size: 13px;
			margin-bottom : 20px;
		}


		&.active {
			font-weight: 700;
			border-bottom: 1px solid #DADADA;
			padding-bottom : 19px;
			margin-bottom : 29px;
			order : 0;

			@media( max-width: 480px) {
				padding-bottom : 17px;
				margin-bottom : 25px;
			}
		}

	}

	&__floating-element {
		position : absolute;
		background-repeat : no-repeat;
		background-position : center center;

		&.floating-element-1 {
			width : 61px;
			height : 56px;
			top : 337px;
			right: 90px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjEiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA2MSA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAuODY1MSAwLjAyMzQzNzVIMzkuNTExN0M0My42NDA0IDAuMDIzNDM3NSA0Ny40NDc1IDIuMjIxODcgNDkuNDk4NSA1Ljc4NzY0TDU4LjgyODUgMjEuOTQwOEM2MC44OTI5IDI1LjUwNjYgNjAuODkyOSAyOS45MDM1IDU4LjgyODUgMzMuNDgyN0w0OS40OTg1IDQ5LjYzNTlDNDcuNDM0MSA1My4yMDE2IDQzLjYyNyA1NS40MDAxIDM5LjUxMTcgNTUuNDAwMUgyMC44NjUxQzE2LjczNjMgNTUuNDAwMSAxMi45MjkzIDUzLjIwMTYgMTAuODc4MyA0OS42MzU5TDEuNTQ4MjkgMzMuNDgyN0MtMC41MTYwOTggMjkuOTE2OSAtMC41MTYwOTggMjUuNTIgMS41NDgyOSAyMS45NDA4TDEwLjg3ODMgNS43ODc2NEMxMi45NDI3IDIuMjIxODcgMTYuNzQ5NyAwLjAyMzQzNzUgMjAuODY1MSAwLjAyMzQzNzVaIiBmaWxsPSIjRjVFOEZEIi8+PC9zdmc+);

			@media( max-width: 768px) {
				display : none;
			}

		}
		&.floating-element-2 {
			width : 174px;
			height : 266px;
			right : 0;
			top : 402px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY5IiBoZWlnaHQ9IjI0NiIgdmlld0JveD0iMCAwIDE2OSAyNDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iMTE2LjYyMyIgeT0iLTMuOTE1OSIgd2lkdGg9IjE4OC41IiBoZWlnaHQ9IjE3MC41IiByeD0iMTMuMjUiIHRyYW5zZm9ybT0icm90YXRlKDQ1IDExNi42MjMgLTMuOTE1OSkiIHN0cm9rZT0iIzFDMTQzQSIgc3Ryb2tlLW9wYWNpdHk9IjAuMyIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1kYXNoYXJyYXk9IjE2IDE2Ii8+PC9zdmc+);

			@media( max-width: 768px) {
				display : none;
			}
		}
	}
}