.platform-advantage {
	padding-top : 45px;
	padding-bottom : 100px;

	@media( max-width: 768px) {
		padding-top : 70px;
		padding-bottom : 72px;
	}

	@media( max-width: 480px) {
		padding-top : 50px;
		padding-bottom : 50px;
	}

	&__wrap {
		max-width : 1067px;
		padding : 0 40px;
		min-height : 283px;
		display : flex;
		justify-content : center;
		align-items : center;
		flex-wrap: wrap;
		background: $dark_blue;
		border-radius: 40px;
		margin : 0 auto;
		position : relative;

		@media( max-width: 768px) {
			padding : 88px 40px 111px;
			max-width : 100%;
		}

		@media( max-width: 480px) {
			padding : 67px 40px;

			&:before {
				content: "";
				position : absolute;
				width : 100%;
				height : 100%;
				border: 1px solid #15BDFF;
				border-radius: 20px;
				display : block;
				z-index : -1;
				top : 22px;
				left : 12px;

			}
		}
	}

	&__item {
		flex : 1;
		padding : 0 30px;
		position : relative;
		margin-bottom : 0;
		text-align : center;

		span {
			text-align : center;
			font-family: $main_font;
			font-style: normal;
			font-weight: 400;
			font-size: 30px;
			line-height: 1.43;
			color: $white;
		}

		&:before {
			content: "";
			position : absolute;
			display : block;
			width : 3px;
			height : 83px;
			background: #0094FF;
			border-radius: 53px;
			right: 0;
			top : 50%;
			transform: translateY(-50%);
		}

		&:last-of-type:before {
			display : none;
		}

		@media( max-width: 992px) {
			span {
				font-size : 25px;
			}
		}

		@media( max-width: 768px) {
			width : 100%;
			max-width : 100%;
			margin-bottom : 123px;
			flex: none;

			span {
				font-size: 30px;
				display : inline-block;
				max-width : 239px;
			}

			&:before {
				width : 117px;
				height : 3px;
				left: 50%;
				transform: translateX(-50%);
				top : auto;
				bottom: -60px;
			}

			&:last-of-type {
				margin-bottom : 0;
			}
		}

		@media( max-width: 480px) {

			margin-bottom : 93px;

			span {
				font-size: 25px;
				max-width : 220px;
			}

			&:before {
				bottom : -45px;
				width : 117px;
				height : 3px;
			}
		}
	}

	&__floating-element {
		position : absolute;
		background-repeat : no-repeat;
		background-position : center center;

		&.floating-element-1 {
			width : 60px;
			height : 56px;
			top : 0;
			right: -32px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjEiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA2MSA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMjAuODY1MSAwSDM5LjUxMTdDNDMuNjQwNCAwIDQ3LjQ0NzUgMi4xOTg0NCA0OS40OTg1IDUuNzY0MjFMNTguODI4NSAyMS45MTc0QzYwLjg5MjkgMjUuNDgzMiA2MC44OTI5IDI5Ljg4MDEgNTguODI4NSAzMy40NTkyTDQ5LjQ5ODUgNDkuNjEyNEM0Ny40MzQxIDUzLjE3ODIgNDMuNjI3IDU1LjM3NjYgMzkuNTExNyA1NS4zNzY2SDIwLjg2NTFDMTYuNzM2MyA1NS4zNzY2IDEyLjkyOTMgNTMuMTc4MiAxMC44NzgzIDQ5LjYxMjRMMS41NDgyOSAzMy40NTkyQy0wLjUxNjA5OCAyOS44OTM1IC0wLjUxNjA5OCAyNS40OTY2IDEuNTQ4MjkgMjEuOTE3NEwxMC44NzgzIDUuNzY0MjFDMTIuOTQyNyAyLjE5ODQ0IDE2Ljc0OTcgMCAyMC44NjUxIDBaIiBmaWxsPSIjRjVFOEZEIi8+PC9zdmc+);

			@media( max-width: 768px) {
				top : -66px;
				right : -13px;
				width : 130px;
				height : 126px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwNCIgdmlld0JveD0iMCAwIDEwMCAxMDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE4LjQ3NDYgMTIuODkwNkw0OC4zODk5IDEuNjE1MDNDNTUuMDEzOSAtMC44ODE2NSA2Mi40NTQzIDAuMzUxNTgxIDY3LjkwNjEgNC44NDU1N0w5Mi42NjU0IDI1LjE4MDJDOTguMTM4NyAyOS42NjYxIDEwMC44MDQgMzYuNzM2OCA5OS42NjEzIDQzLjc0MUw5NC40ODM5IDc1LjM1OTNDOTMuMzMzMyA4Mi4zNDE4IDg4LjU1OCA4OC4xNzk0IDgxLjk1NTUgOTAuNjY3OUw1Mi4wNDAyIDEwMS45NDRDNDUuNDE2MyAxMDQuNDQgMzcuOTc2IDEwMy4yMDcgMzIuNTI0MiA5OC43MTNMNy43NjQ3MiA3OC4zNzg0QzIuMjkxNDEgNzMuODkyNSAtMC4zNzM2ODMgNjYuODIxNyAwLjc2ODgzOSA1OS44MTc2TDUuOTQ2MzEgMjguMTk5M0M3LjA5Njk2IDIxLjIxNjcgMTEuODcyMiAxNS4zNzkyIDE4LjQ3NDYgMTIuODkwNloiIGZpbGw9IiNGNUU4RkQiLz48L3N2Zz4=);

			}

			@media( max-width: 480px) {
				top : -61px;
				right : 0;
				z-index : -1;
			}

		}

		&.floating-element-2 {
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQ2IiBoZWlnaHQ9IjI0NiIgdmlld0JveD0iMCAwIDI0NiAyNDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iMTE2LjYyMSIgeT0iLTMuOTM5MzQiIHdpZHRoPSIxODguNSIgaGVpZ2h0PSIxNzAuNSIgcng9IjEzLjI1IiB0cmFuc2Zvcm09InJvdGF0ZSg0NSAxMTYuNjIxIC0zLjkzOTM0KSIgc3Ryb2tlPSIjMUMxNDNBIiBzdHJva2Utb3BhY2l0eT0iMC4zIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWRhc2hhcnJheT0iMTYgMTYiLz48L3N2Zz4=);
			width : 268px;
			height : 268px;
			top : 65px;
			right : -222px;

			@media( max-width: 768px) {
				width : 156px;
				height : 152px;
				top : 0;
				right : -110px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTU2IiBoZWlnaHQ9IjE1MiIgdmlld0JveD0iMCAwIDE1NiAxNTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNTUiIGhlaWdodD0iMTUxIiByeD0iMjguNSIgc3Ryb2tlPSIjQjc0OEZGIi8+PC9zdmc+);

			}

			@media( max-width: 480px) {
				width : 51px;
				height : 47px;
				top : -28px;
				right : 55px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTEiIGhlaWdodD0iNDciIHZpZXdCb3g9IjAgMCA1MSA0NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNDkuMTUwNyAyOC4yMjc5TDQ5LjE1MDcgMjguMjI3OEM1MC43OTg3IDI1LjM2ODEgNTAuNzk4OSAyMS44NDM1IDQ5LjE1MDcgMTguOTc4NlYyOC4yMjc5Wk00OS4xNTA3IDI4LjIyNzlMNDEuMjkxIDQxLjg3NThMNDEuMjkwOSA0MS44NzU5QzM5LjY0MyA0NC43NDAxIDM2LjU5ODIgNDYuNDk5NSAzMy4zMDMgNDYuNDk5NUgxNy41ODM4QzE0LjI4ODkgNDYuNDk5NSAxMS4yNDM5IDQ0LjczNTcgOS41OTU2OCA0MS44NzU2TDEuNzM2MjQgMjguMjI4MUMxLjczNjIgMjguMjI4MSAxLjczNjE3IDI4LjIyOCAxLjczNjE0IDI4LjIyNzlDMC4wODc4NTE0IDI1LjM2MzEgMC4wODgwNzk3IDIxLjgzODQgMS43MzYwNyAxOC45Nzg3TDEuNzM2MTQgMTguOTc4Nkw5LjU5NTY4IDUuMzMwODlDMTEuMjQzOSAyLjQ3MDgyIDE0LjI4ODkgMC43MDcwMzEgMTcuNTgzOCAwLjcwNzAzMUgzMy4zMDNDMzYuNTk3OCAwLjcwNzAzMSAzOS42NDI4IDIuNDcwNzcgNDEuMjkxIDUuMzMwNzdDNDEuMjkxMSA1LjMzMDgxIDQxLjI5MTEgNS4zMzA4NSA0MS4yOTExIDUuMzMwODlMNDkuMTUwNiAxOC45Nzg0TDQ5LjE1MDcgMjguMjI3OVoiIGZpbGw9IiNFQkQ4RkEiIGZpbGwtb3BhY2l0eT0iMC4yIiBzdHJva2U9IiNCNzQ4RkYiLz48L3N2Zz4=);


			}


		}

		&.floating-element-3 {
			width : 122px;
			height : 120px;
			bottom : -21px;
			left : -60px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA0IiBoZWlnaHQ9IjEwNCIgdmlld0JveD0iMCAwIDEwNCAxMDQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iNTIuMjc3MyIgeT0iLTMuOTM5MzQiIHdpZHRoPSI3OS41IiBoZWlnaHQ9Ijc5LjUiIHJ4PSIxMy4yNSIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNTIuMjc3MyAtMy45MzkzNCkiIHN0cm9rZT0iIzFDMTQzQSIgc3Ryb2tlLW9wYWNpdHk9IjAuNCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1kYXNoYXJyYXk9IjMgMyAzIDMiLz48L3N2Zz4=);

			@media( max-width: 768px) {
				height : 142px;
				width : 140px;
				bottom: -72px;
				left : 33px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMyIiBoZWlnaHQ9IjEzMiIgdmlld0JveD0iMCAwIDEzMiAxMzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iODUuMDY2NCIgeT0iLTUiIHdpZHRoPSIxMDQiIGhlaWdodD0iMTA0IiByeD0iMTYiIHRyYW5zZm9ybT0icm90YXRlKDYwIDg1LjA2NjQgLTUpIiBmaWxsPSIjRjBGQkZGIi8+PC9zdmc+);

			}

			@media( max-width: 480px) {
				width : 92px;
				height : 92px;
				bottom : -100px;
				left : -70px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODUiIGhlaWdodD0iODUiIHZpZXdCb3g9IjAgMCA4NSA4NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB4PSI0Mi4yMzA1IiB5PSItMy4yOTI4OSIgd2lkdGg9IjY0LjM4MDEiIGhlaWdodD0iNjQuMzgwMSIgcng9IjkuNSIgdHJhbnNmb3JtPSJyb3RhdGUoNDUgNDIuMjMwNSAtMy4yOTI4OSkiIGZpbGw9IiMxNUJERkYiIGZpbGwtb3BhY2l0eT0iMC4xIiBzdHJva2U9IiMxNUJERkYiIHN0cm9rZS1kYXNoYXJyYXk9IjEuOTQgMS45NCAxLjk0IDEuOTQiLz48L3N2Zz4=);

			}

		}

		&.floating-element-4 {
			width : 192px;
			height : 192px;
			bottom: -250px;
			left: -250px;
			background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkxIiBoZWlnaHQ9IjE5MiIgdmlld0JveD0iMCAwIDE5MSAxOTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjE5MSIgaGVpZ2h0PSIxOTIiIHJ4PSIyOSIgZmlsbD0iI0VERkFGRiIvPjwvc3ZnPg==);

			@media( max-width: 768px) {
				width : 197px;
				height : 197px;
				background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTg0IiBoZWlnaHQ9IjE4NCIgdmlld0JveD0iMCAwIDE4NCAxODQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgeD0iOTEuODE2NCIgeT0iLTUuNTIxNjEiIHdpZHRoPSIxMzcuNjU3IiBoZWlnaHQ9IjEzNy42NTciIHJ4PSIxNC44ODQiIHRyYW5zZm9ybT0icm90YXRlKDQ1IDkxLjgxNjQgLTUuNTIxNjEpIiBzdHJva2U9IiMxQzE0M0EiIHN0cm9rZS1vcGFjaXR5PSIwLjUiIHN0cm9rZS13aWR0aD0iMC42NzY1NDMiIHN0cm9rZS1kYXNoYXJyYXk9IjQuODUgNC44NSIvPjwvc3ZnPg==);
				bottom : -161px;
				left: -90px;
				z-index : -1;

			}

			@media( max-width: 480px) {
				display : none;
			}
		}
	}


}