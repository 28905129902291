.archive-resources-featured{
  margin-top: 85px;
  margin-bottom: 130px;
  position: relative;
  @include break(tablet){
    margin-top: 40px;
  }
  @include break(ss-small){
    margin-top: 30px;
    margin-bottom: 95px;
  }
  &__container{

  }
  &__wrapper{
    max-width: 1225px;
    margin-left: auto;
    margin-right: auto;
  }
}