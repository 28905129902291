.template-single-resources{
  @include break(tablet){
    background: linear-gradient(180.79deg, rgba(2, 29, 89, 0.12) -51.2%, rgba(241, 250, 253, 0) 119.49%);
  }
  &.white{
    background: $white;
  }
  .resources-container{
    overflow: hidden;
    margin-top: 75px;
    margin-bottom: 160px;
    @include break(tablet){
      margin-top: 50px;
      margin-bottom: 80px;
    }
    @include break(ss-small){
      margin-top: 35px;
      margin-bottom: 70px;
    }
  }
  .resources-content{
    max-width: 1025px;
    margin-left: auto;
    margin-right: auto;
    
    strong{
      font-weight: 600;
      color: $black;
    }
    p{
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 0;
      color: $black;
      font-weight: 300;
      &:not(:last-child){
        margin-bottom: 23px;
      }
      @include break(x-small) {
        font-size: 15px;
        line-height: 21px;
        &:not(:last-child){
          margin-bottom: 21px;
        }
      }
    }
    ul,ol{
      margin: 30px 0 30px 20px;
      @include break(x-small){
        margin: 20px 0 20px 20px;
      }
    }
    ul{
      list-style: disc;
      li{
        list-style: disc;
      }
    }
    ol{
      list-style: decimal;
      li{
        list-style: decimal;
      }
    }
    li{
      font-size: 16px;
      line-height: 23px;
      color: $black;
      font-weight: 300;
      @include break(x-small) {
        font-size: 15px;
        line-height: 21px;
      }
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
    h1,h2,h3,h4.h5,h6{
      font-weight: 400;
      font-family: $main_font;
      color: $dark_blue;
    }
    h2{
      font-size: 35px;
      line-height: 43px;
      &:not(:last-child){
        margin-bottom: 30px;
      }
      @include break(x-small) {
        font-size: 30px;
        line-height: 43px;
      }
    }
    h3,h4{
      font-size: 30px;
      line-height: 43px;
      &:not(:last-child){
        margin-bottom: 30px;
      }
      @include break(x-small) {
        font-size: 20px;
        line-height: 29px;
      }
    }
    h5, h6{
      font-size: 23px;
      line-height: 31px;
      &:not(:last-child){
        margin-bottom: 23px;
      }
      @include break(x-small) {
        font-size: 20px;
        line-height: 29px;
      }
    }
    blockquote{
      padding-left: 35px;
      position: relative;
      margin: 60px 0;
      @include break(tablet){
        margin: 40px 0;
      }
      @include break(x-small){
        padding-left: 25px;
      }
      &::before{
        content: '';
        display: block;
        width: 7px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: #0094FF;
        border-radius: 53px;
      }
      p{
        margin-bottom: 0;
        color: #0094FF;
        font-size: 35px;
        line-height: 50px;
        max-width: 650px;
        font-family: $main_font;
        @include break(x-small){
          font-size: 20px;
          line-height: 29px;
        }
      }
    }
    &.blue{
      *{
        color: #021D59;
      }
    }
  }
}
